import * as React from "react";
import { Input, InputMultiList, InputWithIcon, SelectWithIcon, TextArea } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { uploadFile } from "services/Uploader";
import { Funciones } from "services/funciones";
import { DropFile } from "shared/FileUpload";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import resize from "shared/Resize";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const NewSale = (router) => {
    const { navigate, params } = router;
    const { setTitle, data_user, setDataUser } = useMedia();
    const [consultoCliente, setConsultoCliente] = React.useState(false);
    const [tieneQueValidar, setTieneQueValidar] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [cliDepartamento, setCliDepartamento] = React.useState("");
    const [cliTelefonoDos, setCliTelefonoDos] = React.useState("");
    const [valorEfectivo, setValorEfectivo] = React.useState("");
    const [valorTransfer, setValorTransfer] = React.useState("");
    const [searchType, setSearchType] = React.useState("email");
    const [cliDireccion, setCliDireccion] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [editClient, setEditClient] = React.useState(false);
    const [productName, setProductName] = React.useState("");
    const [cliApellido, setCliApellido] = React.useState("");
    const [cliTelefono, setCliTelefono] = React.useState("");
    const [cliDocument, setCliDocument] = React.useState("");
    const [tagList, setTagList] = React.useState("loading");
    const [modalView, setModalView] = React.useState(null);
    const [articulos, setArticulos] = React.useState([]);
    const [cliCorreo, setCliCorreo] = React.useState("");
    const [cliNombre, setCliNombre] = React.useState("");
    const [cliCiudad, setCliCiudad] = React.useState("");
    const [cuentas, setCuentas] = React.useState([]);
    const [cliente, setCliente] = React.useState({});
    const [method, setMethod] = React.useState("");
    const [tags, setTags] = React.useState([]);
    const [nota, setNota] = React.useState("");

    const [vouchers, setVouchers] = React.useState([
        {
            bank: "",
            bank_name: "",
            voucher: "",
            pedido: "",
            signature: "",
            file: []
        }
    ]);

    const cliDepartamentoRef = React.useRef(null);
    const transferenciaRef = React.useRef(null);
    const cliDireccionRef = React.useRef(null);
    const cliTelefonoRef = React.useRef(null);
    const cliApellidoRef = React.useRef(null);
    const cliDocumentRef = React.useRef(null);
    const cliCiudadRef = React.useRef(null);
    const cliNombreRef = React.useRef(null);
    const cliCorreoRef = React.useRef(null);
    const efectivoRef = React.useRef(null);
    const prodNameRef = React.useRef(null);
    const articlesRef = React.useRef([]);
    const vouchersRef = React.useRef([]);
    const methodRef = React.useRef(null);
    const totalRef = React.useRef(null);
    const notaRef = React.useRef(null);
    const tagsRef = React.useRef(null);


    const checkingVoucher = async (_json) => {
        setShowLoading(true);

        let _data_checking = await api.fetchJson({
            url: "checking-voucher",
            data: {
                task: "checking",
                info: {
                    pedido: "no aplica",
                    signature: "no aplica",
                    files: _json.files
                }
            }
        });

        if (_data_checking.response == 1) {
            log.d(_data_checking.msg);
            return true;
        } else if (_data_checking.response == -2) {
            Alert(_data_checking.msg, "warning", () => navigate("/sign-in"));
            setShowLoading(false);
            return false;
        } else {
            Alert(_data_checking.msg, "warning");
            setShowLoading(false);
            return false;
        }
    }

    const saveOrder = async (_json, _create_voucher = false) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "shopify-new-sale",
            data: {
                productos: articulos,
                cliente: {
                    id: cliente.id ?? 0,
                    email: cliCorreo,
                    tipo: searchType,
                    first_name: cliNombre,
                    last_name: cliApellido,
                    phone: cliTelefono,
                    address1: cliDireccion,
                    address2: cliTelefonoDos,
                    city: cliCiudad,
                    province: cliDepartamento,
                    company: cliDocument,
                },
                metodo: method.trim(),
                valor_efectivo: funciones.current.formatPrice(valorEfectivo).int,
                valor_transferencia: funciones.current.formatPrice(valorTransfer).int,
                nota: nota,
                tags: tags.join(","),
                estado: method.trim() === "Efectivo" ? "paid" : "pending",
                tienda: data_user.local.id,
                valor_total: articulos.map(item => item.price * parseInt(item.quantity)).reduce((a, b) => a + b, 0)
            }
        });

        if (_data.response == 1 && _create_voucher) {
            await saveVoucher(_data.data.order, _json, _data.msg);
        } else if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate("/tirilla-impresion/" + params.local, { replace: true, state: { data: [_data.data.order] } }));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const saveVoucher = async (_pedido, _json, _msg) => {
        let _data = await api.fetchJson({
            url: "set-voucher",
            data: {
                task: "create",
                info: {
                    pedido: _pedido.order_number,
                    pedido_id: _pedido.id,
                    pedido_detalle: {
                        name: _pedido.name,
                        line_items: _pedido.line_items,
                        subtotal_price: _pedido.subtotal_price,
                        total_price: _pedido.total_price,
                        total_discounts: _pedido.total_discounts,
                        total_shipping_price: _pedido.total_shipping_price_set.shop_money.amount,
                        fulfillment_status: _pedido.fulfillment_status,
                        financial_status: _pedido.financial_status,
                        note: _pedido.note,
                        note_attributes: _pedido.note_attributes,
                        tags: _pedido.tags,
                        customer: _pedido.customer,
                    },
                    signature: _json.sign,
                    files: _json.files
                }
            }
        });

        if (_data.response == 1) {
            Alert(_msg + " y enviado a validación", "ok", () => navigate("/local-ventas/" + params.local, { replace: true }));
            // Alert(_msg + " y enviado a validación", "ok", () => navigate("/tirilla-impresion/" + params.local, { replace: true, state: { data: [_pedido] } }));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const validate = async () => {
        let allVouchers = [];
        let allFiles = [];
        let _error = false;
        let _next = true;
        alertaRemove(notaRef.current);
        alertaRemove(prodNameRef.current);
        alertaRemove(cliCorreoRef.current);
        alertaRemove(cliNombreRef.current);
        alertaRemove(cliApellidoRef.current);
        alertaRemove(cliTelefonoRef.current);
        alertaRemove(cliDireccionRef.current);
        alertaRemove(cliCiudadRef.current);
        alertaRemove(cliDepartamentoRef.current);
        alertaRemove(methodRef.current);
        alertaRemove(efectivoRef.current);
        alertaRemove(transferenciaRef.current);
        alertaRemove(totalRef.current);

        if (parseInt(data_user?.local?.caja?.estado) !== 1) {
            Alert("¡Es necesario realizar apertura de caja para poder continuar!", "warning");
            return;
        }

        if (articulos.length < 1) {
            alerta(prodNameRef.current, "¡Debes agregar al menos un artículo!");
            prodNameRef.current.focus();
            return;
        }

        for (let index = 0; index < articulos.length; index++) {
            let item = articulos[index];

            if (item.title.length < 1) {
                alerta(articlesRef.current["title_" + index], "¡Debes ingresar el nombre del artículo!");
                articlesRef.current["title_" + index].focus();
                _error = true;
                return;
            }

            if (item.price.length < 1) {
                alerta(articlesRef.current["price_" + index], "¡Debes ingresar el precio del artículo!");
                articlesRef.current["price_" + index].focus();
                _error = true;
                return;
            }
            if (isNaN(item.price)) {
                alerta(articlesRef.current["price_" + index], "¡El precio debe ser un número!");
                articlesRef.current["price_" + index].focus();
                _error = true;
                return;
            }

            if (item.quantity.length < 1) {
                alerta(articlesRef.current["quantity_" + index], "¡Debes ingresar la cantidad del artículo!");
                articlesRef.current["quantity_" + index].focus();
                _error = true;
                return;
            }

            if (parseInt(item.quantity) < 1) {
                alerta(articlesRef.current["quantity_" + index], "¡La cantidad debe ser mayor a 0!");
                articlesRef.current["quantity_" + index].focus();
                _error = true;
                return;
            }
        }

        if (_error) {
            return;
        }

        if (cliCorreo == null || cliCorreo.length < 1) {
            alerta(cliCorreoRef.current, "¡Debes ingresar el correo del cliente!");
            cliCorreoRef.current.focus();
            return;
        }

        if (cliNombre == null || cliNombre.length < 1) {
            if (cliNombreRef.current) {
                alerta(cliNombreRef.current, "¡Debes ingresar el nombre del cliente!");
                cliNombreRef.current.focus();
            } else {
                Alert("Debes ingresar el nombre del cliente!", "warning");
                cliCorreoRef.current.focus();
            }
            return;
        }

        if (cliApellido == null || cliApellido.length < 1) {
            if (cliApellidoRef.current) {
                alerta(cliApellidoRef.current, "¡Debes ingresar el apellido del cliente!");
                cliApellidoRef.current.focus();
            } else {
                Alert("Debes ingresar el apellido del cliente!", "warning");
                cliCorreoRef.current.focus();
            }
            return;
        }

        if (cliDocument == null || cliDocument.length < 1) {
            if (cliDocumentRef.current) {
                alerta(cliDocumentRef.current, "¡Debes ingresar el documento del cliente!");
                cliDocumentRef.current.focus();
            } else {
                Alert("Debes ingresar el documento del cliente!", "warning");
                cliCorreoRef.current.focus();
            }
            return;
        }

        if (cliTelefono == null || cliTelefono.length < 1) {
            if (cliTelefonoRef.current) {
                alerta(cliTelefonoRef.current, "¡Debes ingresar el teléfono del cliente!");
                cliTelefonoRef.current.focus();
            } else {
                Alert("Debes ingresar el teléfono del cliente!", "warning");
                cliCorreoRef.current.focus();
            }
            return;
        }

        if (method == null || method.length < 1) {
            alerta(methodRef.current, "¡Debes seleccionar un método de pago!");
            methodRef.current.focus();
            return;
        }

        if (method === "Combinado" && (valorEfectivo.length < 1 || isNaN(valorEfectivo))) {
            alerta(efectivoRef.current, "¡Debes ingresar el valor en efectivo!");
            efectivoRef.current.focus();
            return;
        }

        if (method === "Combinado" && (valorTransfer.length < 1 || isNaN(valorTransfer))) {
            alerta(transferenciaRef.current, "¡Debes ingresar el valor de la transferencia!");
            transferenciaRef.current.focus();
            return;
        }

        let _total = articulos.map(item => item.price * parseInt(item.quantity)).reduce((a, b) => a + b, 0).toFixed(2).replace(".", ",");
        let _suma = parseInt(funciones.current.formatPrice(valorTransfer).int) + parseInt(funciones.current.formatPrice(valorEfectivo).int);

        if (method === "Combinado" && _suma !== parseInt(_total)) {
            alerta(totalRef.current, "¡La suma de los valores debe ser igual al total!");
            window.scrollTo({
                top: document.body.scrollHeight - 840,
                behavior: "smooth"
            });
            return;
        }

        if (tieneQueValidar) {

            for (let index = 0; index < vouchers.length; index++) {
                let all = vouchers[index];

                if (all.voucher == null || all.voucher.length < 1) {
                    alerta(vouchersRef.current["voucher" + index], "¡Debes ingresar el número del comprobante!");
                    vouchersRef.current["voucher" + index].focus();
                    _next = false;
                    return false;
                }

                if (all.bank == null || all.bank.length < 1) {
                    alerta(vouchersRef.current["bank" + index], "¡Debes seleccionar una cuenta bancaria!");
                    vouchersRef.current["bank" + index].focus();
                    _next = false;
                    return false;
                }

                if (all.file == null || all.file.length < 1) {
                    alerta(vouchersRef.current["file" + index], "¡Debes agregar una foto del comprobante!");
                    vouchersRef.current["file" + index].focus();
                    _next = false;
                    return false;
                }

                let file = all.file[0];

                if (file.type.toString().split('/')[0] === "image") {
                    log.i(file.type.toString().split('/')[1]);
                    allFiles.push({
                        file: file,
                        type: file.type.toString().split('/')[1],
                        img_name: "voucher_" + all.voucher + file.nameinserver.toString().split('voucher')[1],
                        bank: all.bank,
                        voucher: all.voucher,
                        bank_name: all.bank_name,
                    });
                } else {
                    alerta(vouchersRef.current["file" + index], "¡Sólo se admiten imágenes!");
                    vouchersRef.current["file" + index].focus();
                    _next = false;
                    return false;
                }
            }

            if (_next) {
                setShowLoading(true);

                let forChecking = allFiles.map(item => ({
                    voucher: item.voucher,
                    bank: item.bank,
                    bank_name: item.bank_name
                }));

                let _response_checking = await checkingVoucher({ files: forChecking });

                if (_response_checking) {
                    allFiles.forEach(async _obj => {
                        const data = new FormData();
                        const file = _obj.file;

                        resize(file, async image => {
                            if (image !== null) {
                                data.append('file', image);
                                data.append('nombre', _obj.img_name.trim());
                                data.append('destino', "vouchers");
                            }

                            await uploadFile(data).then(response => {

                                allVouchers.push({
                                    url: response.url,
                                    name: response.nameinserver,
                                    voucher: _obj.voucher,
                                    bank: _obj.bank,
                                    bank_name: _obj.bank_name
                                });

                                log.i("archivo subido", file.name);

                                if (allVouchers.length == vouchers.length) {
                                    saveOrder({ files: allVouchers, sign: file.sign }, true);
                                }
                            }).catch(error => {
                                alerta(vouchersRef.current["file" + 0], error);
                                setShowLoading(false);
                            });
                        });
                    });
                }
            }
        } else {
            saveOrder({}, false);
        }
    }

    const validaCustomer = () => {
        // eslint-disable-next-line no-useless-escape
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        alertaRemove(cliCorreoRef.current);
        alertaRemove(cliNombreRef.current);
        alertaRemove(cliApellidoRef.current);
        alertaRemove(cliTelefonoRef.current);
        alertaRemove(cliDireccionRef.current);
        alertaRemove(cliCiudadRef.current);
        alertaRemove(cliDepartamentoRef.current);


        if (cliCorreo == null || cliCorreo.length < 1) {
            alerta(cliCorreoRef.current, searchType === "email" ? "¡Debes ingresar el correo del cliente!" : "¡Debes ingresar el teléfono del cliente!");
            cliCorreoRef.current.focus();
            window.scrollTo({ top: 100, behavior: "smooth" })
            return false;
        }

        if (searchType === "email" && !regex.test(String(cliCorreo).toLowerCase())) {
            alerta(cliCorreoRef.current, "¡El formato del correo es erróneo!");
            window.scrollTo({ top: 100, behavior: "smooth" })
            return;
        }

        if (cliNombre == null || cliNombre.length < 1) {
            alerta(cliNombreRef.current, "¡Debes ingresar el nombre del cliente!");
            cliNombreRef.current.focus();
            return false;
        }

        if (cliApellido == null || cliApellido.length < 1) {
            alerta(cliApellidoRef.current, "¡Debes ingresar el apellido del cliente!");
            cliApellidoRef.current.focus();
            return false;
        }

        if (cliTelefono == null || cliTelefono.length < 1) {
            alerta(cliTelefonoRef.current, "¡Debes ingresar el teléfono del cliente!");
            cliTelefonoRef.current.focus();
            return false;
        }

        if (cliDireccion == null || cliDireccion.length < 1) {
            alerta(cliDireccionRef.current, "¡Debes ingresar la dirección del cliente!");
            cliDireccionRef.current.focus();
            return false;
        }

        if (cliCiudad == null || cliCiudad.length < 1) {
            alerta(cliCiudadRef.current, "¡Debes ingresar la ciudad del cliente!");
            cliCiudadRef.current.focus();
            return false;
        }

        if (cliDepartamento == null || cliDepartamento.length < 1) {
            alerta(cliDepartamentoRef.current, "¡Debes ingresar el departamento del cliente!");
            cliDepartamentoRef.current.focus();
            return false;
        }

        return true;
    }

    const getProducts = async (_criterio) => {
        if (_criterio.length < 1) {
            alerta(prodNameRef.current, "¡Debes ingresar el nombre del artículo!");
            prodNameRef.current.focus();
            return;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "shopify-get-products",
            data: {
                criterio: _criterio
            }
        });

        if (_data.response == 1) {
            let _edges = _data.data.products.edges;
            document.body.style.overflow = "hidden";

            if (_edges.length < 1) {
                setModalView(
                    <div className="cont_edges">
                        <div className="edges_list">
                            <Iconos.CloseCircle className="close" onClick={() => {
                                document.body.style.overflow = "auto";
                                setModalView(null);
                            }} />
                            <div className="scroll_content">
                                <h4>No hay artículos “{_criterio}“</h4>
                            </div>
                        </div>
                    </div>
                );
            } else {
                setModalView(
                    <div className="cont_edges">
                        <div className="edges_list">
                            <Iconos.CloseCircle className="close" onClick={() => {
                                document.body.style.overflow = "auto";
                                setModalView(null);
                            }} />
                            <div className="scroll_content">
                                {_edges.map(({ node }, index) => <span key={index} onClick={() => {
                                    document.body.style.overflow = "auto";
                                    setModalView(null);
                                    setProductName("");
                                    setArticulos(a => [...a, { title: node.title, price: node.variants.edges[0].node.price, quantity: 1, tipo: "del inventario" }]);
                                    log.d("articulo", node);
                                }}>{node.title}</span>)}
                            </div>
                        </div>
                    </div>
                );
            }
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getCustomer = async (e) => {
        // eslint-disable-next-line no-useless-escape
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let _correo = e.target.value;
        let search_type = e.target.name;


        if (search_type === "email" && !regex.test(String(_correo).toLowerCase())) {
            alerta(cliCorreoRef.current, "¡El formato del correo es erróneo!");
            return;
        }

        setShowLoading(true);
        setConsultoCliente(false);
        setCliente({});
        setCliNombre("");
        setCliApellido("");
        setCliTelefono("");
        setCliDireccion("");
        setCliCiudad("");
        setCliDepartamento("");
        setCliDocument("");

        let _data = await api.fetchJson({
            url: "shopify-get-customer",
            data: {
                email: _correo,
                tipo: search_type === "email" ? "email" : "phone"
            }
        });

        if (_data.response == 1) {
            if (_data.data.id) {
                setCliente(_data.data);
                setCliNombre(_data.data.default_address?.first_name ?? "");
                setCliApellido(_data.data.default_address?.last_name ?? "");
                setCliTelefono(_data.data.default_address?.phone ?? "");
                setCliTelefonoDos(_data.data.default_address?.address2 ?? "");
                setCliDireccion(_data.data.default_address?.address1 ?? "No alplica");
                setCliCiudad(_data.data.default_address?.city ?? "Bogotá");
                setCliDepartamento(_data.data.default_address?.province ?? "Cundinamarca");
                setCliDocument(_data.data.default_address?.company ?? "");
            }
            setConsultoCliente(true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const saveCustomer = async () => {

        if (validaCustomer()) {
            setShowLoading(true);

            let _data = await api.fetchJson({
                url: "shopify-update-customer",
                data: {
                    id: cliente.id,
                    address_id: cliente.default_address?.id,
                    email: cliCorreo,
                    tipo: searchType,
                    first_name: cliNombre,
                    last_name: cliApellido,
                    phone: cliTelefono,
                    address1: cliDireccion,
                    address2: cliTelefonoDos,
                    city: cliCiudad,
                    province: cliDepartamento,
                    company: cliDocument,
                }
            });

            if (_data.response == 1) {
                Alert(_data.msg, "ok", () => setEditClient(false));
                setCliente({
                    ...cliente,
                    first_name: cliNombre,
                    last_name: cliApellido,
                    default_address: {
                        ...cliente.default_address,
                        first_name: cliNombre,
                        last_name: cliApellido,
                        phone: cliTelefono,
                        address1: cliDireccion,
                        address2: cliTelefonoDos,
                        company: cliDocument,
                        city: cliCiudad,
                        province: cliDepartamento,
                    }
                })
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }
    }

    const getTags = async () => {
        setTagList("loading");

        let _data = await api.fetchJson({
            url: "get-tags",
            data: { task: "todos" }
        });

        if (_data.response == 1) {
            setTagList(_data.data.map(item => item.name));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
            setTagList([]);
        } else {
            Alert(_data.msg, "warning");
            setTagList([]);
        }
    }

    const handlePayMethod = (_value) => {
        alertaRemove(methodRef.current);
        setMethod(_value);

        if (!["Efectivo", "Transferencia", "Combinado"].includes(_value)) {
            alerta(methodRef.current, "¡El método de pago es invalido!");
            methodRef.current.focus();
        }

        if (_value === "Efectivo") {
            setTieneQueValidar(false);
        }

        if (_value === "Transferencia" || _value === "Combinado") {
            setTieneQueValidar(true);
        }
    }

    React.useEffect(() => {
        setTitle("Nueva venta");

        funciones.current.validaSesion("locales_admin").then(response => {
            if (response && data_user.local.id === params.local) {
                setPermission(true);
                getTags();
                getBanksList();

                setTimeout(() => {
                    if (cliCorreoRef.current) {
                        cliCorreoRef.current.addEventListener("keyup", (e) => {
                            if (e.key === "Enter") {
                                getCustomer(e);
                            }
                        });
                    }

                    if (prodNameRef.current) {
                        prodNameRef.current.addEventListener("keyup", (e) => {
                            if (e.key === "Enter") {
                                getProducts(e.target.value);
                            }
                        });
                    }
                }, 600);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Nueva venta</h2>
                {parseInt(data_user?.local?.caja?.estado) !== 1 && <h1 className="error" style={{ fontSize: "2rem", textAlign: "center" }}>¡Es necesario realizar apertura de caja!</h1>}
            </div>

            <div className="content_primary _in_center">

                <div className="in_column" style={{ marginBottom: "1rem" }}>
                    <h4>Artículos</h4>

                    <InputWithIcon
                        ref={prodNameRef}
                        type="search"
                        title="Buscar artículo"
                        value={productName}
                        Icono={Iconos.Magnify}
                        onChange={v => { alertaRemove(prodNameRef.current); setProductName(v) }} />

                    {articulos.map((item, index) => <fieldset key={index} style={{ width: "100%", margin: "-0.5rem 0 1.5rem" }}>
                        <legend>Artículo {item.tipo}</legend>
                        <Iconos.CloseCircle className="close" onClick={() => setArticulos(a => a.filter((_, i) => i !== index))} />

                        <InputWithIcon
                            ref={e => articlesRef.current["title_" + index] = e}
                            title="Nombre del artículo"
                            value={item.title}
                            onChange={v => {
                                alertaRemove(articlesRef.current["title_" + index]); setArticulos(a => {
                                    let _new = [...a];
                                    _new[index].title = v;
                                    return _new;
                                })
                            }} />

                        <InputWithIcon
                            ref={e => articlesRef.current["price_" + index] = e}
                            Icono={Iconos.Currency}
                            title="Precio"
                            value={item.price}
                            onChange={v => {
                                alertaRemove(articlesRef.current["price_" + index]); setArticulos(a => {
                                    let _new = [...a];
                                    _new[index].price = v;
                                    return _new;
                                })
                            }} />


                        <InputWithIcon
                            ref={e => articlesRef.current["quantity_" + index] = e}
                            type="number"
                            title="Cantidad"
                            value={item.quantity}
                            onChange={v => {
                                alertaRemove(articlesRef.current["quantity_" + index]); setArticulos(a => {
                                    let _new = [...a];
                                    _new[index].quantity = v;
                                    return _new;
                                })
                            }} />
                    </fieldset>)}

                    <button type="button" className="btn btn_with_icon _left" style={{ margin: "-0.5rem 0 1.5rem" }}
                        onClick={() => {
                            alertaRemove(prodNameRef.current);
                            setArticulos(a => [...a, { title: "", price: "", quantity: 1, tipo: "personalizado" }])
                        }}><Iconos.Plus /> Agregar artículo personalizado</button>
                </div>

                <div className="in_column" style={{ zIndex: 10, marginBottom: "2rem" }}>
                    <h4>Cliente</h4>
                    <div className="swap_icons" onClick={() => {
                        setSearchType(t => t === "email" ? "phone" : "email");
                        alertaRemove(cliCorreoRef.current);
                    }}>
                        <Iconos.ArrowDown className="arrow" />
                        {searchType === "email" ? <Iconos.Phone className="primay" /> : <Iconos.Email className="primay" />}
                    </div>


                    <InputWithIcon
                        ref={cliCorreoRef}
                        type="text"
                        name={searchType === "email" ? "email" : "phone"}
                        Icono={searchType === "email" ? Iconos.Email : Iconos.Phone}
                        title={searchType === "email" ? "Correo electrónico" : "Teléfono"}
                        value={cliCorreo}
                        onChange={v => {
                            alertaRemove(cliCorreoRef.current);
                            setCliCorreo(v);
                            if (searchType === "phone") {
                                setCliTelefono(v);
                            }
                        }} />

                    {consultoCliente && <>
                        {(cliente.id && !editClient) ?
                            <>
                                <h4>{cliente.default_address?.first_name} {cliente.default_address?.last_name} <br />
                                    <Iconos.Edit className="edit" onClick={() => setEditClient(true)} />
                                </h4>
                                <h5><span>Documento: </span>{cliente.default_address?.company}</h5>
                                {/* <h5><span>Dirección: </span>{cliente.default_address?.address1}, {cliente.default_address?.city}, {cliente.default_address?.province}</h5> */}
                                <h5><span>Teléfono: </span>{cliente.default_address?.phone}</h5>
                                <h4><span>{cliente.orders_count} {cliente.orders_count > 1 ? "pedidos" : "pedido"}</span></h4>
                            </>
                            :
                            <>
                                <InputWithIcon
                                    ref={cliNombreRef}
                                    type="text"
                                    title="Nombre"
                                    value={cliNombre}
                                    onChange={v => { alertaRemove(cliNombreRef.current); setCliNombre(v) }} />

                                <InputWithIcon
                                    ref={cliApellidoRef}
                                    type="text"
                                    title="Apellido"
                                    value={cliApellido}
                                    onChange={v => { alertaRemove(cliApellidoRef.current); setCliApellido(v) }} />

                                <InputWithIcon
                                    ref={cliDocumentRef}
                                    type="text"
                                    Icono={Iconos.CardSelect}
                                    title="Documento"
                                    value={cliDocument}
                                    onChange={v => { alertaRemove(cliDocumentRef.current); setCliDocument(v) }} />

                                <InputWithIcon
                                    ref={cliTelefonoRef}
                                    type="text"
                                    Icono={Iconos.Phone}
                                    title="Teléfono"
                                    value={searchType === "email" ? cliTelefono : cliCorreo}
                                    onChange={v => {
                                        alertaRemove(cliTelefonoRef.current);
                                        setCliTelefono(v);
                                        if (searchType === "phone") {
                                            setCliCorreo(v);
                                        }
                                    }} />

                            </>}
                    </>}

                    {editClient && <>
                        <div className="back_float _blur"></div>
                        <div className="content_btns _with_margin">
                            <button type="button" className="btn btn_primary" onClick={() => setEditClient(false)}>Cancelar</button>
                            <button type="button" className="btn btn_primary" onClick={saveCustomer}>Guardar</button>
                        </div>
                    </>}
                </div>

            </div>

            <div className="content_primary _in_center">
                <div className="in_column">
                    {vouchers.map((item, index) => <fieldset key={index} style={{ width: "100%", margin: "-0.5rem 0 1.5rem", pointerEvents: tieneQueValidar ? "all" : "none", opacity: tieneQueValidar ? 1 : 0.5 }}>
                        <legend>Comprobante {index + 1}</legend>
                        {vouchers.length > 1 && <Iconos.CloseCircle className="close" onClick={() => setVouchers(a => a.filter((_, i) => i !== index))} />}

                        <InputWithIcon
                            ref={e => vouchersRef.current["voucher" + index] = e}
                            type="text"
                            title="Número de comprobante"
                            value={item.voucher}
                            onChange={v => {
                                alertaRemove(vouchersRef.current["voucher" + index]);
                                setVouchers(all => {
                                    let _new = [...all];
                                    _new[index].voucher = v;
                                    return _new;
                                });
                            }} />

                        <SelectWithIcon
                            ref={e => vouchersRef.current["bank" + index] = e}
                            title="Cuenta Bancaria"
                            Icono={Iconos.Bank}
                            value={item.bank}
                            onChange={v => {
                                alertaRemove(vouchersRef.current["bank" + index]);
                                setVouchers(all => {
                                    let _new = [...all];
                                    let _bank = cuentas.find(count => parseInt(count.id) === parseInt(v));
                                    _new[index].bank_name = _bank.nombre;
                                    _new[index].bank = v;
                                    return _new;
                                });
                            }} >
                            <option value="">Selecciona Cuenta</option>
                            {cuentas.map((item, i) => {
                                return <option key={i} value={format(item.id)}>{item.nombre}</option>;
                            })}

                        </SelectWithIcon>

                        <div className="form_group">
                            <span className="form_group-label">Foto del comprobante</span>
                            <DropFile
                                ref={e => vouchersRef.current["file" + index] = e}
                                voucher={item.voucher}
                                attach={item.file}
                                onChange={v => {
                                    alertaRemove(vouchersRef.current["file" + index]);
                                    setVouchers(all => {
                                        let _new = [...all];
                                        _new[index].file = v.length > 0 ? [v[0]] : [];
                                        return _new;
                                    });
                                }} />
                        </div>
                    </fieldset>)}

                    <button type="button" className="btn btn_with_icon _left" style={{ margin: "-0.5rem 0 1.5rem", pointerEvents: tieneQueValidar ? "all" : "none", opacity: tieneQueValidar ? 1 : 0.5 }}
                        onClick={() => {
                            alertaRemove(prodNameRef.current);
                            setVouchers(a => [...a, {
                                bank: "",
                                bank_name: "",
                                voucher: "",
                                pedido: "",
                                signature: "",
                                file: []
                            }])
                        }}><Iconos.VoucherNew /> Agregar comprobante</button>
                </div>

                <div className="in_column">
                    <fieldset style={{ width: "100%", margin: "-0.5rem 0 1.5rem" }}>
                        <legend>Pedido</legend>

                        <div className="input" style={{ margin: "0.5rem 0 0 2rem", position: "absolute" }}>
                            <input type="hidden" value="" ref={totalRef} />
                        </div>
                        <h4 className="total_items" style={{ paddingLeft: 0, margin: "0.8rem 0 1rem" }}>
                            Total: <b>${funciones.current.formatPrice(articulos.map(item => item.price * parseInt(item.quantity)).reduce((a, b) => a + b, 0).toFixed(2).replace(".", ",")).format}</b> por <b>{articulos.map(item => item.quantity).reduce((a, b) => parseInt(a) + parseInt(b), 0)}</b> artículos
                        </h4>

                        <SelectWithIcon
                            ref={methodRef}
                            title="Método de pago"
                            Icono={Iconos.CashBill}
                            value={method}
                            onChange={handlePayMethod} >
                            <option value="">Selecciona método de pago</option>
                            <option>Efectivo</option>
                            <option>Transferencia</option>
                            <option>Combinado</option>
                        </SelectWithIcon>

                        {method === "Combinado" && <>
                            <Input
                                ref={efectivoRef}
                                name="efectivo"
                                title="Valor en efectivo"
                                value={funciones.current.formatPrice(valorEfectivo).format}
                                onChange={v => {
                                    alertaRemove(efectivoRef.current);
                                    alertaRemove(totalRef.current);
                                    setValorEfectivo(v);
                                }} />

                            <Input
                                ref={transferenciaRef}
                                name="transferencia"
                                title="Valor de transferencia"
                                value={funciones.current.formatPrice(valorTransfer).format}
                                onChange={v => {
                                    alertaRemove(transferenciaRef.current);
                                    alertaRemove(totalRef.current);
                                    setValorTransfer(v);
                                }} />
                        </>}

                        <InputMultiList
                            ref={tagsRef}
                            title="Etiquetas"
                            suggestionList={tagList}
                            currentList={tags}
                            removeTag={i => setTags(t => t.filter((_, index) => index !== i))}
                            onChange={v => setTags(t => [...t, v])} />

                        <TextArea
                            ref={notaRef}
                            title="Nota"
                            value={nota}
                            onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />

                    </fieldset>
                </div>
            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
            </div>

            {modalView}
            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(NewSale);