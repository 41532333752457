import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputSearch } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Menu from "./components/MenuAdmin";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Locales = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, data_user, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [currentQuery, setCurrentQuery] = React.useState(7);
    const [showLazy, setShowLazy] = React.useState(true);
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);


    const Row = ({ data, interline }) => {

        return (
            <Menu
                inRow={true}
                data={data}
                interline={interline}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery)}>

                {media_movil ?
                    <td>
                        {data.nombre}
                        <pre><b>Correo: </b>{data.correo}</pre>
                        <pre><b>Teléfono: </b>{data.telefono}</pre>
                        <pre><b>Administrador: </b>{data.admin.nombre} <sup>{data.admin.correo}</sup></pre>
                    </td>
                    :
                    <>
                        <td>{data.nombre}</td>
                        <td>{data.correo}</td>
                        <td>{data.telefono}</td>
                        <td>{data.admin.nombre} <sup>{data.admin.correo}</sup></td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;
        let _info;

        dataList.map((item, i) => {
            _info = JSON.parse(item.info);
            _info.id = item.id;

            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={_info} />);
        });

        if (_data.length < 1) {
            _footer = <>
                {currentQuery === "search" ?
                    <h4 className="msg_lazy_load _alone _left">No hay locales con "{criterio}"</h4>
                    :
                    <h4 className="msg_lazy_load _alone _left">No hay locales</h4>
                }
            </>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="msg_lazy_load">¡No hay más locales!</h4>
                        }
                    </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th><Iconos.SwapVertical onClick={() => sortBy("nombre")} />Lista de locales</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th><Iconos.SwapVertical onClick={() => sortBy("nombre")} />Nombre</th>
                            <th>Correo</th>
                            <th>Teléfono</th>
                            <th>Administrador</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const sortBy = (_key) => {
        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: currentQuery,
                criterio: criterio,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery(_val === "" ? "todos" : "search");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-locales",
            data: {
                task: _val === "" ? "todos" : "search",
                criterio: _val,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_task = "todos") => {
        setShowLoading(true);
        setCurrentQuery(_task);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-locales",
            data: {
                task: _task,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Todos los locales");

        funciones.current.validaSesion("locales_ver").then(response => {
            if (response && [1, 2].includes(parseInt(data_user.nivel))) {
                getList();
                setPermission(true);
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2>Lista de locales</h2>

                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar por nombre"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            {funciones.current.validaPermisos("locales_crear") &&
                <NavLink
                    to="/local-crear-nuevo"
                    className="btn btn_add_to_list _right">
                    Crear nuevo local<Iconos.StoreAdd />
                </NavLink>}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Locales);