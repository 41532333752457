import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputSearch } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CashOnDelivery = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [titulo, setTitulo] = React.useState("Todas las contraentregas");
    const [showLoading, setShowLoading] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [permission, setPermission] = React.useState(false);
    const [currentQuery, setCurrentQuery] = React.useState("todos");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLazy, setShowLazy] = React.useState(true);
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);


    const Filter = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";
            switch (_opcion) {
                case 0:
                    setTitulo("Contraentregas pendientes");
                    getList("pendientes");
                    break;
                case 1:
                    setTitulo("Contraentregas aceptadas");
                    getList("aceptadas");
                    break;
                case 2:
                    setTitulo("Contraentregas rechazadas");
                    getList("rechazadas");
                    break;

                default:
                    setTitulo("Todas las contraentregas");
                    getList("todos");
                    break;
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 112) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={pos}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <ul>
                            <li onClick={() => handleSelct(0)}><span>Pendientes</span></li>
                            <li onClick={() => handleSelct(2)}><span>Rechazadas</span></li>
                            <li onClick={() => handleSelct(1)}><span>Aceptadas</span></li>
                            <li onClick={() => handleSelct(3)}><span>Todos</span></li>
                        </ul>}
                </div>
            </>
        )
    }

    const Row = ({ data, interline }) => {
        let fecha_creado = new Date(data.fecha_creado);

        return (
            <Menu
                inRow={true}
                data={data}
                interline={interline}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery)}>

                {media_movil ?
                    <td>
                        <pre><span>Pedido:</span> {data.pedido.name} </pre>
                        <pre><span>Valor:</span> ${funciones.current.formatPrice(data.pedido.total_price.toString().replace(".", ",")).format}</pre>
                        <pre><span>Cliente:</span> {data.pedido.customer.first_name} {data.pedido.customer.last_name} </pre>
                        <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                    </td>
                    :
                    <>
                        <td>{data.pedido.name}</td>
                        <td>${funciones.current.formatPrice(data.pedido.total_price.toString().replace(".", ",")).format}</td>
                        <td>{data.pedido.customer.first_name} {data.pedido.customer.last_name}</td>
                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _footer;
        let _info;

        let _data = dataList.map((item, i) => {
            _info = JSON.parse(item.info);
            return <Row
                key={item.id}
                interline={i % 2}
                data={{
                    id: item.id,
                    estado: item.estado,
                    pedido: {..._info.pedido_detalle, order_number: _info.pedido},
                    fecha_creado: item.fecha_creado,
                    asesor: JSON.parse(item.asesor),
                }} />;
        });

        if (_data.length < 1) {
            _footer = <>
                {currentQuery === "search" ?
                    <h4 className="msg_lazy_load _alone _left">No hay contraentregas con “ {criterio} “</h4>
                    :
                    <h4 className="msg_lazy_load _alone _left">No hay contraentregas {currentQuery === "todos" ? "" : currentQuery}</h4>
                }
            </>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="msg_lazy_load">¡No hay más contraentregas!</h4>
                        }
                    </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}<Filter /></th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Pedido<Filter /></th>
                            <th>Valor</th>
                            <th>Cliente</th>
                            <th><Iconos.SwapVertical onClick={() => sortBy("fecha_creado")} />Fecha</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const sortBy = (_key) => {
        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-cash-on-delivery",
            data: {
                task: currentQuery,
                criterio: criterio,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery(_val === "" ? "todos" : "search");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-cash-on-delivery",
            data: {
                task: _val === "" ? "todos" : "search",
                criterio: _val,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_c_query = "todos") => {
        setShowLoading(true);
        setCurrentQuery(_c_query);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-cash-on-delivery",
            data: {
                task: _c_query,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Contraentregas");

        funciones.current.validaSesion("contraentregas_ver").then(response => {
            if (response) {
                setPermission(true);
                getList();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2>{titulo}</h2>

                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar pedido"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(CashOnDelivery);