import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputListOnly, InputSearch } from "shared/Components";
import { Alert, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import logo from "images/interrapidisimo.png";
import ReactExport from "react-export-excel-ext";
import withRouter from "services/withRouter";
import ciudades from "shared/ciudadesInter";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint react/jsx-no-target-blank: 0 */
/* eslint no-extend-native: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_center = { horizontal: "center", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "002060" } },
    font: { sz: "11", bold: false, color: { rgb: "ffffffff" } },
    alignment: _align_center,
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

String.prototype.acentos = function () {
    var translate_re = /[áéíóúÁÉÍÓÚ]/g;
    var translate = {
        "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
        "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
    };
    return this.replace(translate_re, function (match) { return translate[match]; });
};

const GuiasInter = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [permission, setPermission] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [seleccionados, setSeleccionados] = React.useState([]);
    const [currentQuery, setCurrentQuery] = React.useState("todos");
    const [navBtns, setNavBtns] = React.useState({ prev: "", next: "" });
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [fileName, setFileName] = React.useState("Guías Inter Rapidísimo");
    const [rows, setRows] = React.useState(<h4 className="msg_lazy_load _alone _left">Verificando pedidos...</h4>);


    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_with_icon _left unable"><Iconos.ExcelDownload />Descargar excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {

        const controlCaja = [
            {
                columns: [
                    { value: "NUMERO GUIA", style: _heads, width: 17 },
                    { value: "ID DESTINATARIO", style: _heads, width: 18 },
                    { value: "NOMBRE DESTINATARIO", style: _heads, width: 20 },
                    { value: "APELLIDO1 DESTINATARIO", style: _heads, width: 22 },
                    { value: "APELLIDO2 DESTINATARIO", style: _heads, width: 22 },
                    { value: "TELEFONO DESTINATARIO", style: _heads, width: 22 },
                    { value: "DIRECCION DESTINATARIO", style: _heads, width: 22 },
                    { value: "CIUDAD DESTINO", style: _heads, width: 22 },
                    { value: "CODIGO CIUDAD DESTINO", style: _heads, width: 22 },
                    { value: "DICE CONTENER", style: _heads, width: 19 },
                    { value: "OBSERVACIONES", style: _heads, width: 19 },
                    { value: "BOLSA DE SEGURIDAD", style: _heads, width: 20 },
                    { value: "PESO", style: _heads, width: 7 },
                    { value: "VALOR COMERCIAL", style: _heads, width: 19 },
                    { value: "NO PEDIDO", style: _heads, width: 12 },
                    { value: "DIRECCION AGENCIA DESTINO", style: _heads, width: 24 },
                    { value: "FOLIO", style: _heads, width: 8 },
                    { value: "CODIGO RADICADO", style: _heads, width: 20 },
                ],
                data: seleccionados.map(item => {
                    let last_name = item.customer?.default_address?.last_name ?? "";

                    return [
                        { value: " ", style: _txt },
                        { value: item.name?.replace(/#/g, '') ?? ".", style: { ..._txt, alignment: _align_rigth } },
                        { value: item.customer?.default_address?.first_name + " " + last_name, style: _txt },
                        { value: ".", style: _txt },
                        { value: ".", style: _txt },
                        { value: item.customer?.default_address?.phone ?? ".", style: _txt },
                        { value: item.customer?.default_address?.address1 ?? ".", style: _txt },
                        { value: item.ciudad?.ciudad + "/" + item.ciudad?.departamento, style: _txt },
                        { value: "" + item.ciudad?.codigo, style: _txt },
                        { value: "paquete hit", style: _txt },
                        { value: "paquete hit " + item.name, style: _txt },
                        { value: " ", style: _txt },
                        { value: "1", style: _txt },
                        { value: "" + parseInt(item.total_price), style: { ..._txt, alignment: _align_rigth } },
                        { value: " ", style: _txt },
                        { value: " ", style: _txt },
                        { value: " ", style: _txt },
                        { value: " ", style: _txt },
                    ];
                })
            }
        ];

        return <>
            {(seleccionados.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={controlCaja} name="BASE COMPLETA" />
                </ExcelFile>
            }
        </>;
    }

    const Row = ({ info, interline }) => {
        let _default_address = info.customer ? info.customer.default_address : "-1";
        let _status = info.financial_status === "pending" ? "Pendiente" : info.financial_status === "paid" ? "Pagado" : info.financial_status === "partially_paid" ? "Pago parcial" : info.financial_status;
        let _city = _default_address.city ? _default_address.city.toString().toLowerCase().acentos() : "-1";
        let _province = _default_address.province ? _default_address.province.toString().toLowerCase().acentos() : "-1";
        let _obj_provinces = ciudades.filter(item => item.departamento.toString().toLowerCase().acentos().includes(_province));
        let _obj_city = _obj_provinces.filter(item => item.ciudad.toString().toLowerCase().acentos().includes(_city));

        const [flete, setFlete] = React.useState(<strong></strong>);

        const cityRef = React.useRef();
        const provinceRef = React.useRef();
        const [data, setData] = React.useState({
            ...info,
            fulfill_id: info.fulfillments[0]?.id,
            tracking_number: info.fulfillments[0]?.tracking_number,
            tracking_url: info.fulfillments[0]?.tracking_url,
            ciudad: _obj_city[0],
        });
        const [isChecked, setIsChecked] = React.useState(false);
        const [departamentos, setDepartamentos] = React.useState([]);
        const [showAply, setShowAply] = React.useState(false);


        const [currentCity, setCurrentCity] = React.useState(_default_address.city || "");
        const [ciudadesPorDepartamentos, setCiudadesPorDepartamentos] = React.useState([]);
        const [currentProvince, setCurrentProvince] = React.useState(_default_address.province || "");

        const handleSelect = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (data.tracking_number === undefined && data.ciudad?.codigo) {
                setIsChecked(b => !b);

                setSeleccionados(s => {
                    if (s.some(item => item.id === data.id)) {
                        return s.filter(f => f.id !== data.id);
                    } else {
                        return [...s, data];
                    }
                });
            }
        }

        const handleChangeCode = (e) => {
            e.stopPropagation();
            e.preventDefault();

            _province = currentProvince.toString().toLowerCase().acentos();
            _city = currentCity.toString().toLowerCase().acentos();

            _obj_provinces = ciudades.filter(item => item.departamento.toString().toLowerCase().acentos().includes(_province));
            _obj_city = _obj_provinces.filter(item => item.ciudad.toString().toLowerCase().acentos().includes(_city));

            setData(d => ({ ...d, ciudad: _obj_city[0] }));
            setShowAply(false);
        }

        React.useEffect(() => {
            let _ciudades = {};

            ciudades.forEach(item => {
                if (!_ciudades[item.departamento]) {
                    _ciudades[item.departamento] = item;
                }
            });

            setDepartamentos(Object.values(_ciudades));

            if (data.ciudad?.codigo && data.tracking_number === undefined) {
                // cargaFlete();
            } else if (data.fulfillment_status === "fulfilled") {
                setFlete(<strong className="_ok">✅​</strong>);
            } else {
                // setFlete(<strong className="_error">Ciudad o departamento no encontrado</strong>);
                setFlete("no city");
            }
        }, [data]);

        return <Menu
            data={data}
            interline={interline}>

            {media_movil ?
                <td>
                    <pre className="cont_checkbox">
                        {(data.tracking_number === undefined && data.ciudad?.codigo) ?
                            <>
                                <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                                <span>pedido:&nbsp;</span>
                            </>
                            :
                            <>
                                {data.tracking_number ? <Iconos.CheckBold className="_correcto" /> : <Iconos.Close className="_error" />}
                                <span>pedido:&nbsp;</span>
                            </>
                        } {data.name}
                        <span>Estado:&nbsp;</span> {_status}
                    </pre>
                    <pre style={{ paddingLeft: "2rem" }}>{data.tracking_number ?
                        <><span>Guía:&nbsp;</span> <a href={data.tracking_url} target="_blank" onClick={e => e.stopPropagation()}>{data.tracking_number}</a></>
                        :
                        <><span>Costo envio:&nbsp;</span> {data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</>
                    }
                    </pre>
                </td>
                :
                <>
                    <td className="cont_checkbox" onClick={handleSelect}>
                        {(data.tracking_number === undefined && data.ciudad?.codigo) ?
                            <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                            :
                            <>
                                {data.tracking_number ?
                                    <Iconos.CheckBold className="_correcto" />
                                    :
                                    <>
                                        {data.fulfillment_status === "fulfilled" ?
                                            <Iconos.CheckOk className="_correcto" />
                                            :
                                            <Iconos.Close className="_error" />
                                        }
                                    </>
                                }
                            </>
                        } {data.name}
                    </td>
                    <td>
                        {_status}
                    </td>
                    <td>{data.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</td>
                    <td className="cont_city">
                        {data.tracking_number ?
                            <span>Guía: <a href={data.tracking_url} target="_blank">{data.tracking_number}</a></span>
                            :
                            <>
                                {flete === "no city" ?
                                    <span className="with_selects">
                                        <InputListOnly
                                            ref={provinceRef}
                                            placeholder="Departamento"
                                            value={currentProvince}
                                            onChange={v => {
                                                alertaRemove(provinceRef.current);
                                                alertaRemove(cityRef.current);
                                                setCurrentProvince(v);
                                                setCurrentCity("");
                                                let _ciudades = [];

                                                ciudades.forEach(item => {
                                                    if (item.departamento === v) {
                                                        _ciudades.push(item);
                                                    }
                                                });

                                                setCiudadesPorDepartamentos(_ciudades);
                                                setShowAply(true);
                                            }}
                                            list={departamentos.map(item => {
                                                return item.departamento?.trim();
                                            })} />
                                        <InputListOnly
                                            ref={cityRef}
                                            placeholder="Ciudad"
                                            value={currentCity}
                                            onChange={v => {
                                                alertaRemove(cityRef.current);
                                                setCurrentCity(v);
                                                setShowAply(true);
                                            }}
                                            list={ciudadesPorDepartamentos.map(item => {
                                                return item.ciudad?.trim();
                                            })} />

                                        {showAply && <button type="button" onClick={handleChangeCode}>Aplicar</button>}
                                    </span>
                                    :
                                    <span>{data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</span>
                                }
                            </>
                        }
                    </td>
                </>
            }
        </Menu>;
    }

    const armaFilas = () => {
        let _data = dataList.map((item, i) => {
            return <Row
                key={item.id}
                interline={i % 2}
                info={item} />;
        });

        if (_data.length < 1) {
            setRows(<h4 className="msg_lazy_load _alone _left">No hay pedidos</h4>);
        } else {
            setRows(<>
                <table className="table">
                    <tbody>
                        {media_movil ?
                            <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                                <th>&nbsp;</th>
                            </tr>
                            :
                            <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                                <th>Pedido</th>
                                <th>Estado del pago</th>
                                <th style={{ whiteSpace: "nowrap" }}>Estado preparación</th>
                                <th>Costo envio</th>
                            </tr>
                        }
                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <div className="content_btns _center">
                        {navBtns.next !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("next")}>Anterior</button>}
                        {navBtns.prev !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("prev")}>Siguiente</button>}
                    </div>
                }
            </>);
        }

        // window.scrollTo(0, 120);
    }

    const getListLazy = async (direction) => {
        setShowLoading(true);
        let position = direction === "next" ? dataList.length - 1 : 0;
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: currentQuery,
                criterio: criterio,
                lazy: true,
                direction,
                limit: 20,
                since_id: dataList[position].id
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);
        setCurrentQuery("todos");
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: "todos",
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery("search");
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: "search",
                criterio: _val,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        armaFilas();
    }, [dataList]);

    React.useEffect(() => {
        setTitle("Guías");

        funciones.current.validaSesion("guias_ver").then(response => {
            if (response) {
                let fecha = new Date();
                setFileName(`Guías Inter Rapidísimo ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
                setPermission(true);
                getList();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar_sticky">
                <img className="dark" src={logo} alt="Logo Inter Rapidísimo" />
                <div className="in_row">
                    {seleccionados.length < 1 ?
                        <h3>No hay pedidos seleccionados</h3>
                        :
                        <h3>{seleccionados.length} pedido{seleccionados.length == 1 ? "" : "s"} seleccionado{seleccionados.length == 1 ? "" : "s"}</h3>
                    }

                    {funciones.current.validaPermisos("guias_crear") && <HojaDeCalculo />}
                </div>
            </div>

            <div className="head_bar with_margin">
                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar pedido"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            {rows}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(GuiasInter);