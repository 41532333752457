import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { uploadFile } from "services/Uploader";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import withRouter from "services/withRouter";
import EditExpense from "./EditExpense";
import VisorImg from "shared/VisorImg";
import Loading from "shared/Loading";
import resize from "shared/Resize";
import * as Iconos from "images";
import { useMedia } from "hooks";
import Entrada from "./Entrada";
import log from "services/log";
import api from "services/api";
import Salida from "./Salida";
import Menu from "./RowTr";

/* eslint eqeqeq: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CashClosure = (router) => {
    const { navigate, params } = router;
    const { setTitle, media_movil, data_user, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [valorEfectivo, setValorEfectivo] = React.useState(0);
    const [countEfectivo, setCountEfectivo] = React.useState(0);
    const [valorTransfer, setValorTransfer] = React.useState(0);
    const [countTransfer, setCountTransfer] = React.useState(0);
    const [valorApertura, setValorApertura] = React.useState(0);
    const [valorTotal, setValorTotal] = React.useState(0);
    const [countTotal, setCountTotal] = React.useState(0);
    const [valorOtros, setValorOtros] = React.useState(0);
    const [subTotal, setSubTotal] = React.useState(0);
    const [dataList, setDataList] = React.useState([]);
    const [otros, setOtros] = React.useState([]);


    const createFile = React.useCallback(async (_file) => {
        let response = await fetch(_file.url, {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        let blob = await response.blob();

        let file = new File([blob],
            _file.name,
            {
                type: _file.name.split(".").pop() === "pdf" ? "application/pdf" : "image/" + _file.name.split(".").pop(),
                lastModified: Date.now()
            });
        file = Object.assign(file, {
            preview: _file.url,
            path: _file.name,
            nameinserver: _file.name,
            isUpdate: true
        });

        return file;
    }, []);

    const CardProduct = ({ data }) => {
        const [showCard, setShowCard] = React.useState(false);
        const title = data.total_articulos + " " + (data.total_articulos > 1 ? "artículos" : "articulo");
        const products = data.line_items;

        return <td className="td_info">
            <div className="info_name _product" onClick={() => setShowCard(true)}>
                <p>{title}</p>
                <Iconos.Products className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    <h5>{title}</h5>
                    {products.map((item, i) => <p key={i} className="info_product">{item.quantity} x {funciones.current.formatPrice(item.price.toString().replace(".", ",")).format} - <span>{item.name}</span></p>)}
                </div>
            </>
            }
        </td>;
    }

    const CardCustomer = ({ customer }) => {
        const [infoCustomer, setInfoCustomer] = React.useState({});
        const [showCard, setShowCard] = React.useState(false);
        const [loadInfo, setLoadInfo] = React.useState(true);

        const getCustomer = async () => {
            setLoadInfo(true);

            let _data = await api.fetchJson({
                url: "shopify-get-customer",
                data: {
                    id: customer.id,
                    tipo: "id"
                }
            });

            if (_data.response == 1) {
                setInfoCustomer(_data.data);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setLoadInfo(false);
        }

        React.useEffect(() => {
            if (showCard && !infoCustomer.id) {
                getCustomer();
            }
        }, [showCard]); // eslint-disable-line react-hooks/exhaustive-deps

        return <td className="td_info">
            <div className="info_name" onClick={() => setShowCard(true)}>
                <p>{customer?.first_name} {customer?.last_name}</p>
                <Iconos.CardSelect className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    {loadInfo ?
                        <h5>{customer?.first_name} {customer?.last_name} <Iconos.Loading className="icon_loading" /></h5>
                        :
                        <h5>{infoCustomer?.first_name} {infoCustomer?.last_name}</h5>
                    }
                    {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                    {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}
                    {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                    {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                    {infoCustomer?.default_address?.zip && <p className="info_nota"><b>Observación:</b> {infoCustomer?.default_address?.zip}</p>}
                </div>
            </>
            }
        </td>;
    }

    const Row = ({ data, interline }) => {
        let fecha_creado = new Date(data.created_at);

        return (
            <Menu
                data={data}
                interline={interline}>

                {media_movil ?
                    <td>
                        <strong>{data.name}</strong>
                        <pre><span>Artículos:</span> {data.total_articulos} {data.total_articulos > 1 ? "artículos" : "articulo"}</pre>
                        <pre><span>Valor:</span> ${funciones.current.formatPrice(data.valor_pago.toString().replace(".", ",")).format} {data.method_order === "Combinado" && <sup> de ${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</sup>}</pre>
                        <pre><span>Pago:</span> {data.metodo_pago}</pre>
                        <pre><span>Cliente:</span> {data.customer.first_name} {data.customer.last_name}</pre>
                        <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                    </td>
                    :
                    <>
                        <td>{data.name}</td>
                        <CardProduct data={data} />
                        <td>${funciones.current.formatPrice(data.valor_pago.toString().replace(".", ",")).format} {data.method_order === "Combinado" && <sup> de ${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</sup>}</td>
                        <td>{data.metodo_pago}</td>
                        <td>{data.tags.replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",")}</td>
                        {data.customer ? <CardCustomer customer={data.customer} /> : <td><span>Sin cliente</span></td>}
                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _footer;
        let _info;

        let _data = dataList.map((item, i) => {
            _info = JSON.parse(item.info);
            _info.pedido = JSON.parse(_info.pedido);
            _info.pedido.total_articulos = _info.pedido.line_items.map((articulo) => articulo.quantity).reduce((a, b) => a + b, 0);
            _info.pedido.method_order = _info.pedido.metafields.filter(meta => meta.key === "Metodo de pago")[0]?.value;
            _info.pedido.metodo_pago = _info.metodo_pago;
            _info.pedido.valor_pago = _info.monto;

            return <Row
                key={item.id}
                interline={i % 2}
                data={{ ..._info.pedido, id: item.id }} />;
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">No hay ventas</h4>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <h4 className="msg_lazy_load">¡No hay más ventas!</h4>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Lista de ventas</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Pedido</th>
                            <th style={{ minWidth: "110px" }}>Artículos</th>
                            <th>Valor</th>
                            <th>Método pago</th>
                            <th>Etiquetas</th>
                            <th>Cliente</th>
                            <th>Fecha</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const saveClosure = async () => {
        setShowLoading(true);

        let _otros = otros.map(item => {
            if (item.foto && item.foto.preview) {
                item.foto = {
                    url: item.foto.preview,
                    name: item.foto.nameinserver
                }
            }
            return item;
        });

        let _data = await api.fetchJson({
            url: "set-cash-closure",
            data: {
                task: "close",
                info: {
                    tienda: window.atob(params.local),
                    closure_id: data_user.local.caja.id,
                    otros: _otros,
                    valor_otros: valorOtros,
                    valor_efectivo: valorEfectivo,
                    valor_transferencia: valorTransfer,
                    valor_total: valorTotal,
                    valor_ventas: subTotal,
                    cantidad_ventas: countTotal,
                    cantidad_efectivos: countEfectivo,
                    cantidad_transferencias: countTransfer,
                }
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate("/local-ventas/" + params.local, { replace: true }));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const saveExpense = async (_otros) => {
        let _valor_otros = _otros.reduce((acumulador, elemento) => {
            if (elemento.tipo === "Ingreso") {
                return acumulador + parseInt(elemento.monto);
            } else {
                return acumulador - parseInt(elemento.monto);
            }
        }, 0);

        _otros = _otros.map(item => {
            if (item.foto && item.foto.preview) {
                item.foto = {
                    url: item.foto.preview,
                    name: item.foto.nameinserver
                }
            }
            return item;
        });

        let _data = await api.fetchJson({
            url: "set-cash-add-expense",
            data: {
                task: "close",
                info: {
                    tienda: window.atob(params.local),
                    closure_id: data_user.local.caja.id,
                    valor_otros: _valor_otros,
                    otros: _otros,
                }
            }
        });

        if (_data.response == 1) {
            // Alert(_data.msg, "ok");
            setModalView(null); // Cierra el modal tras la respuesta
            setOtros([]);
            _otros.forEach(async _obj => {
                if (_obj.foto && _obj.foto.url) {
                    let _file = await createFile(_obj.foto);
                    _obj.foto = _file;
                }
                setOtros(o => [...o, _obj]);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const closureCash = (e) => {
        e.preventDefault();

        Confirm("¿Seguro de cerrar la caja?", async (response) => {
            if (response) {
                saveClosure();
            }
        });
    }

    const handleAddExpense = (_obj) => {
        setShowLoading(true);
        const data = new FormData();
        const file = _obj.foto;
        let _otros = otros.filter(item => item.id !== _obj.id);

        if (file && file.type && !file.isUpdate) {
            resize(file, async image => {
                if (image !== null) {
                    data.append('file', image);
                    data.append('nombre', file.name);
                    data.append('destino', "gastos");
                }

                await uploadFile(data).then(response => {
                    log.i("archivo subido", file.name);

                    saveExpense([..._otros, {
                        ..._obj,
                        foto: {
                            url: response.url,
                            name: response.nameinserver
                        }
                    }]);
                }).catch(error => {
                    Alert(error, "warning");
                    setShowLoading(false);
                });
            });
        } else {
            saveExpense([..._otros, _obj]);
        }
    }

    const abrirEntrada = (e) => {
        e.preventDefault();

        setModalView(<Entrada
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleAddExpense}
            close={() => setModalView(null)} />);
    }

    const abrirSalida = (e) => {
        e.preventDefault();

        setModalView(<Salida
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleAddExpense}
            close={() => setModalView(null)} />);
    }

    const abrirEditar = (e, _data, _index) => {
        e.preventDefault();

        setModalView(<EditExpense
            data={_data}
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleAddExpense}
            close={() => setModalView(null)} />);
    }

    const eliminarItem = (_expense) => {
        Confirm(`¿Seguro de borrar este ${_expense.tipo.toLowerCase()}?`, async (response) => {
            if (response) {
                setShowLoading(true);
                let _valor_otros = otros.reduce((acumulador, elemento) => {
                    if (elemento.tipo === "Ingreso") {
                        return acumulador + parseInt(elemento.monto);
                    } else {
                        return acumulador - parseInt(elemento.monto);
                    }
                }, 0);

                let _otros = otros.map(item => {
                    if (item.foto && item.foto.preview) {
                        item.foto = {
                            url: item.foto.preview,
                            name: item.foto.nameinserver
                        }
                    }
                    return item;
                });

                _otros = _otros.filter(item => item.id !== _expense.id);

                let _data = await api.fetchJson({
                    url: "delete-expense",
                    data: {
                        info: {
                            tienda: window.atob(params.local),
                            closure_id: data_user.local.caja.id,
                            valor_otros: _valor_otros,
                            otros: _otros,
                            expense: _expense
                        }
                    }
                });

                if (_data.response == 1) {
                    setModalView(null); // Cierra el modal tras la respuesta
                    setOtros([]);
                    _otros.forEach(async _obj => {
                        if (_obj.foto && _obj.foto.url) {
                            let _file = await createFile(_obj.foto);
                            _obj.foto = _file;
                        }
                        setOtros(o => [...o, _obj]);
                    });
                } else if (_data.response == -2) {
                    Alert(_data.msg, "warning", () => navigate("/sign-in"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });
    }

    const getVentas = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-sales",
            data: {
                task: "todos",
                closure_id: data_user.local.caja.id,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getCuentas = async () => {
        let _data = await api.fetchJson({
            url: "get-sales",
            data: {
                task: "cuentas",
                closure_id: data_user.local.caja.id,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            _data.data = _data.data[0];
            setValorEfectivo(_data.data.efectivo);
            setValorTransfer(_data.data.transferencia);
            setCountEfectivo(_data.data.cantidad_efectivos);
            setCountTransfer(_data.data.cantidad_transferencias);
            setCountTotal(_data.data.cantidad_ventas);
            setSubTotal(_data.data.total);
            setValorTotal(parseInt(_data.data.efectivo) + parseInt(_data.data.valor_apertura));
            setValorApertura(_data.data.valor_apertura);

            JSON.parse(_data.data.otros)?.forEach(async _obj => {
                if (_obj.foto && _obj.foto.url) {
                    let _file = await createFile(_obj.foto);
                    _obj.foto = _file;
                }
                setOtros(o => [...o, _obj]);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const handleZoom = (imagen) => {
        if (imagen.type.toString().split('/')[0] === "image") {
            VisorImg([imagen]);
        }
    }

    React.useEffect(() => {
        let _total = parseInt(valorEfectivo) + parseInt(valorApertura);
        let _otros = otros.reduce((acumulador, elemento) => {
            if (elemento.tipo === "Ingreso") {
                return acumulador + parseInt(elemento.monto);
            } else {
                return acumulador - parseInt(elemento.monto);
            }
        }, 0);

        setOtros(otros.sort((a, b) => a.id - b.id));
        setValorOtros(_otros);
        setValorTotal(_total + _otros);
    }, [otros]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setTitle("Cierre de caja");

        funciones.current.validaSesion("locales_admin").then(response => {
            if (response && data_user.local.id === params.local) {
                setPermission(true);
                getCuentas();
                getVentas();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title _left">Cierre de caja</h2>
            </div>

            <div className="caja">
                <div className="caja_to_rigth">
                    <h3 className="caja_totales"><span>Todas las ventas</span> <sup>{countTotal}</sup> <samp>{funciones.current.formatPrice(subTotal).format}</samp></h3>
                    <h3 className="caja_totales"><span>Transferencias</span> <sup>{countTransfer}</sup> <samp>{funciones.current.formatPrice(valorTransfer).format}</samp></h3>
                    <h3 className="caja_totales"><span>Efectivo</span> <sup>{countEfectivo}</sup> <samp>{funciones.current.formatPrice(valorEfectivo).format}</samp></h3>
                    <h3 className="caja_totales"><span>Apertura</span> <samp>{funciones.current.formatPrice(valorApertura).format}</samp></h3>
                    <h3 className="caja_totales"><span>Otros</span> <samp>{funciones.current.formatPrice(valorOtros).format}</samp></h3>
                    <h3 className="caja_totales">Total a entregar <samp>{funciones.current.formatPrice(valorTotal).format}</samp></h3>
                </div>
            </div>

            <table className="table apendice">
                <tbody>
                    {otros.length > 0 ?
                        <tr className="table_title">
                            <th>Otros ingresos y gastos</th>
                            <th></th>
                            <th></th>
                        </tr>
                        :
                        <tr className="table_title radius_bottom">
                            <th>Otros ingresos y gastos</th>
                        </tr>
                    }

                    {otros.map((v, i) => {
                        const clases = (i % 2) == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line";
                        return (<tr key={i} className={clases}>
                            <td>{v.nota} {v.foto?.type && <Iconos.Image className="icon_img" onClick={() => handleZoom(v.foto)} />}</td>
                            <td style={{ textAlign: "right" }}>{v.tipo === "Gasto" ? "-" : ""}{funciones.current.formatPrice(v.monto).format}</td>
                            <td>{v.tipo} <Iconos.Delete className="icon_delete" onClick={() => eliminarItem(v)} /> <Iconos.Edit className="icon_edit" onClick={e => abrirEditar(e, v, i)} /></td>
                            <td className="td_menu"></td>
                        </tr>)
                    })}
                </tbody>
            </table>

            <div className="content_btns in_movil">
                <h3 className="btn btn_add_to_list _left" onClick={abrirEntrada}>Agregar Ingreso <Iconos.CashPlus /> </h3>
                <h3 className="btn btn_add_to_list _right" onClick={abrirSalida}>Agregar Gasto <Iconos.CashMinus /> </h3>
            </div>

            <div className="content_btns">
                <button type="button" className="btn btn_with_icon _left" onClick={closureCash}><Iconos.Save /> Guardar cierre</button>
            </div>

            <AllRow />
            {modalView}
            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(CashClosure);