import * as React from 'react';
import { Route, Routes, Outlet } from "react-router-dom";
import { MediaProvider } from "./Contexts";
import Header from '../view/Header';
import Footer from 'shared/Footer';

// Otros
import NoMatch from 'shared/NoMatch';
import IconTest from 'images/IconTest';

// Inicio y login
import Home from '../view/Home';
import LogIn from '../view/LogIn';
import LogInAdmin from '../view/LogIn/components/LogInAdmin';
import ForgotPassword from '../view/ForgotPassword';

// Usuarios
import Users from '../view/Users';
import NewUser from '../view/Users/components/NewUser';
import EditUser from '../view/Users/components/EditUser';
import DetailUser from '../view/Users/components/DetailUser';

// Despachos
import Dispatch from '../view/Dispatch';
import NewDispatch from '../view/Dispatch/components/NewDispatch';
import EditDispatch from '../view/Dispatch/components/EditDispatch';
import DeliveryDispatch from '../view/Dispatch/components/DeliveryDispatch';
import DetailsDispatch from '../view/Dispatch/components/DetailsDispatch';

// Comprobantes
import Vouchers from '../view/Vouchers';
import VoucherDetail from '../view/Vouchers/components/VoucherDetail';
import Till from '../view/Till';

// Guías
import GuiasEnvia from '../view/GuiasEnvia';
import GuiasInter from '../view/GuiasInter';
import PrintGuide from '../view/GuiasEnvia/components/PrintGuide';

// Conciliaciones
import Conciliation from '../view/Conciliation';
import Devolutions from '../view/Devolutions';
import Carrying from '../view/Carrying';
import ConciliarGuias from '../view/ConciliarGuias';
import ConciliationDetails from '../view/Conciliation/components/ConciliationDetails';
import ConciliationEdit from '../view/Conciliation/components/ConciliationEdit';

// Pedidos
import Orders from '../view/Orders';
import OrderNew from '../view/Orders/components/OrderNew';
import OrderEdit from '../view/Orders/components/OrderEdit';
import ColaImpresion from '../view/Orders/ColaImpresion';
import PrintBill from '../view/Orders/components/PrintBill';
import DownloadBill from '../view/Orders/components/DownloadBill';

// Locales
import Locales from '../view/Locales';
import LocalVentas from '../view/Locales/LocalVentas';
import NewLocal from '../view/Locales/components/NewLocal';
import EditLocal from '../view/Locales/components/EditLocal';
import NewSale from '../view/Locales/components/NewSale';
import EditSale from '../view/Locales/components/EditSale';
import PrintingStrip from '../view/Locales/components/PrintingStrip';
import CashOpening from '../view/Locales/components/CashOpening';
import CashClosure from '../view/Locales/components/CashClosure';
import Closures from '../view/Closures';
import DetailClosure from '../view/Closures/components/DetailClosure';

// Lista de contraentregas
import CashOnDelivery from '../view/CashOnDelivery';

/* eslint eqeqeq: 0 */

export default function Router() {

    const handleTheme = (_theme) => {
        let _element = document.getElementsByTagName("body")[0];
        localStorage.setItem("theme", _theme);

        if (_theme == "theme-light") {
            _element.classList.add("theme-light");
            _element.classList.remove("theme-dark");
            // _element.style.backgroundColor = "rgb(230, 230, 230)";
        } else if (_theme == "theme-dark") {
            _element.classList.add("theme-dark");
            _element.classList.remove("theme-light");
            // _element.style.backgroundColor = "#2a2a31";
        }
    }

    const WrappedRoutes = (params) => {
        return (
            <>
                <Header {...params} />
                <main className="wrapper">
                    <Outlet />
                </main>
            </>
        );
    }

    return (
        <MediaProvider>
            <Routes>
                <Route path="/" element={<WrappedRoutes changeTheme={(_theme) => handleTheme(_theme)} />}   >
                    <Route path="/" element={<Home />} />

                    <Route path="/lista-administradores" element={<Users />} />
                    <Route path="/lista-almacenistas" element={<Users />} />
                    <Route path="/lista-contadores" element={<Users />} />
                    <Route path="/lista-asesores" element={<Users />} />
                    <Route path="/lista-transportadoras" element={<Users />} />
                    <Route path="/lista-domiciliarios" element={<Users />} />

                    <Route path="/crear-usuario" element={<NewUser />} />
                    <Route path="/editar-usuario" element={<EditUser />} />
                    <Route path="/detalle-usuario" element={<DetailUser />} />

                    <Route path="/despachos" element={<Dispatch />} />
                    <Route path="/nuevo-despacho" element={<NewDispatch />} />
                    <Route path="/editar-despacho" element={<EditDispatch />} />
                    <Route path="/entregar-despachos" element={<DeliveryDispatch />} />
                    <Route exact path="/detalle-despacho" element={<DetailsDispatch />} />

                    <Route path="/validacion-contraentregas" element={<CashOnDelivery />} />

                    <Route path="/comprobantes" element={<Vouchers />} />
                    <Route exact path="/detalle-comprobante" element={<VoucherDetail />} />

                    <Route exact path="/conciliaciones" element={<Carrying />} />
                    <Route path="/editar-conciliacion/:carrying/:name" element={<ConciliationEdit />} />
                    <Route path="/detalle-conciliacion/:carrying/:name" element={<ConciliationDetails />} />
                    <Route path="/conciliar-guias/:carrying/:name" element={<ConciliarGuias />} />
                    <Route path="/conciliaciones/:carrying/:name" element={<Conciliation />} />
                    <Route path="/pedidos/:carrying/:name" element={<Devolutions />} />

                    <Route path="/caja" element={<Till />} />
                    <Route path="/caja-apertuera/:local" element={<CashOpening />} />
                    <Route path="/caja-cierre/:local" element={<CashClosure />} />
                    <Route path="/cierres-de-caja/:local" element={<Closures />} />
                    <Route path="/detalle-cierre/:local" element={<DetailClosure />} />

                    <Route path="/guias-envia" element={<GuiasEnvia />} />
                    <Route path="/guias-interrapidisimo" element={<GuiasInter />} />
                    <Route path="/guias-imprimir" element={<PrintGuide />} />
                    <Route path="/icons-in-proyect" element={<IconTest />} />

                    <Route path="/pedidos" element={<Orders />} />
                    <Route path="/nuevo-pedido" element={<OrderNew />} />
                    <Route path="/editar-pedido" element={<OrderEdit />} />
                    <Route path="/duplicar-pedido" element={<OrderEdit />} />
                    <Route path="/cola-impresion" element={<ColaImpresion />} />
                    <Route path="/imprimir-facturas" element={<PrintBill />} />
                    <Route path="/factura" element={<DownloadBill />} />

                    <Route path="/locales" element={<Locales />} />
                    <Route path="/local-crear-nuevo" element={<NewLocal />} />
                    <Route path="/local-editar" element={<EditLocal />} />
                    <Route path="/local-ventas/:local" element={<LocalVentas />} />
                    <Route path="/nueva-venta/:local" element={<NewSale />} />
                    <Route path="/editar-venta/:local" element={<EditSale />} />
                    <Route path="/duplicar-venta/:local" element={<EditSale />} />
                    <Route path="/tirilla-impresion/:local" element={<PrintingStrip />} />

                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route
                    path="/sign-in"
                    element={<LogIn changeTheme={(_theme) => handleTheme(_theme)} />} />

                <Route
                    path="/sign-in-admin"
                    element={<LogInAdmin changeTheme={(_theme) => handleTheme(_theme)} />} />

                <Route
                    path="/forgot-password"
                    element={<ForgotPassword changeTheme={(_theme) => handleTheme(_theme)} />} />
            </Routes>
            <footer>
                <Footer />
                <div id="modal"></div>
            </footer>
        </MediaProvider>
    );
}
