import * as React from "react";
import { InputListTags, InputSearch, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Orders = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, currentQuery, setCurrentQuery, setDataUser } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando pedidos...");
    const [navBtns, setNavBtns] = React.useState({ prev: "", next: "" });
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [titulo, setTitulo] = React.useState("Todos los pedidos");
    const [cleanFilters, setCleanFilters] = React.useState(false);
    const [showBtnClean, setShowBtnClean] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [permission, setPermission] = React.useState(false);
    const [carrierList, setCarrierList] = React.useState([]);
    const [tagList, setTagList] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);


    const FiltroPago = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";
            switch (_opcion) {
                case 0:
                    setTitulo("Pedidos con pago pendiente");
                    getList({ task: "con pago pendiente", criterio: "" });
                    break;
                case 1:
                    setTitulo("Pedidos pagados");
                    getList({ task: "pagados", criterio: "" });
                    break;
                default:
                    setTitulo("Todos los pedidos");
                    getList({ task: "todos", criterio: "" });
                    break;
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 112) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic} style={{ zIndex: 100 }}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={{ ...pos, zIndex: showFilter ? 101 : 1 }}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <ul>
                            <li onClick={() => handleSelct(0)}><span>Pendientes<Iconos.PedidosPending /></span></li>
                            <li onClick={() => handleSelct(1)}><span>Pagados<Iconos.PedidosPagados /></span></li>
                            <li onClick={() => handleSelct(2)}><span>Todos<Iconos.Pedidos /></span></li>
                        </ul>}
                </div>
            </>
        )
    }

    const FiltroPreparacion = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";
            switch (_opcion) {
                case 0:
                    setTitulo("Pedidos no preparados");
                    getList({ task: "no preparados", criterio: "" });
                    break;
                case 1:
                    setTitulo("Pedidos preparados");
                    getList({ task: "preparados", criterio: "" });
                    break;
                default:
                    setTitulo("Todos los pedidos");
                    getList({ task: "todos", criterio: "" });
                    break;
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 112) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic} style={{ zIndex: 100 }}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={{ ...pos, zIndex: showFilter ? 101 : 1 }}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <ul>
                            <li onClick={() => handleSelct(0)}><span>No preparados<Iconos.PedidosNoPreparado /></span></li>
                            <li onClick={() => handleSelct(1)}><span>Preparados<Iconos.PedidosPreparado /></span></li>
                            <li onClick={() => handleSelct(2)}><span>Todos<Iconos.Pedidos /></span></li>
                        </ul>}
                </div>
            </>
        )
    }

    const FiltroCarrier = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const [carrier, setCarrier] = React.useState("");
        const carrierRef = React.useRef(null);


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";

            setTitulo("Transportadora " + _opcion);
            setCarrier(_opcion);
            searchCarriers({ task: "transportadora", criterio: _opcion });
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 270) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic} style={{ zIndex: 100 }}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={{ ...pos, zIndex: showFilter ? 101 : 1 }}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por transportadora</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <SelectWithIcon
                            ref={carrierRef}
                            title="Transportadora"
                            Icono={Iconos.TruckFast}
                            value={carrier}
                            onChange={handleSelct} >
                            <option value="">Selecciona transportadora</option>
                            {carrierList.map(item => {
                                let _info = JSON.parse(item.info);
                                return <option key={item.id}>{_info.nombre} {_info.apellido}</option>;
                            })}
                        </SelectWithIcon>}
                </div>
            </>
        )
    }

    const FiltroTags = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const tagsRef = React.useRef(null);


        const handleSearch = (_crit_tags) => {
            document.body.style.overflow = "auto";

            if (_crit_tags === "Sin etiquetas") {
                setTitulo("Pedidos sin etiqueta");
                searchTags({ task: "tags", criterio: "" });
            } else {
                setTitulo("Pedidos con etiqueta “" + _crit_tags.replace(/"/g, "") + "“");
                searchTags({ task: "tags", criterio: _crit_tags });
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 270) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic} style={{ zIndex: 100 }}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={{ ...pos, zIndex: showFilter ? 101 : 1 }}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por etiquetas</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter && <InputListTags
                        ref={tagsRef}
                        onChange={handleSearch}
                        placeholder="Ingresa etiqueta"
                        list={tagList.map(item => item.name)} />
                    }
                </div>
            </>
        )
    }

    const CardProduct = ({ data }) => {
        const [showCard, setShowCard] = React.useState(false);
        const title = data.total_articulos + " " + (data.total_articulos > 1 ? "artículos" : "articulo");
        const products = data.line_items;

        return <td className="td_info">
            <div className="info_name _product" onClick={() => setShowCard(true)}>
                <p>{title}</p>
                <Iconos.Products className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    <h5>{title}</h5>
                    {products.map((item, i) => <p key={i} className="info_product">{item.quantity} x {funciones.current.formatPrice(item.price.toString().replace(".", ",")).format} - <span>{item.name}</span></p>)}
                </div>
            </>
            }
        </td>;
    }

    const CardCustomer = ({ customer }) => {
        const [infoCustomer, setInfoCustomer] = React.useState({});
        const [showCard, setShowCard] = React.useState(false);
        const [loadInfo, setLoadInfo] = React.useState(true);

        const getCustomer = async () => {
            setLoadInfo(true);

            let _data = await api.fetchJson({
                url: "shopify-get-customer",
                data: {
                    id: customer.id,
                    tipo: "id"
                }
            });

            if (_data.response == 1) {
                setInfoCustomer(_data.data);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setLoadInfo(false);
        }

        React.useEffect(() => {
            if (showCard && !infoCustomer.id) {
                getCustomer();
            }
        }, [showCard]); // eslint-disable-line react-hooks/exhaustive-deps

        return <td className="td_info">
            <div className="info_name" onClick={() => setShowCard(true)}>
                <p>{customer?.first_name} {customer?.last_name}</p>
                <Iconos.CardSelect className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    {loadInfo ?
                        <h5>{customer?.first_name} {customer?.last_name} <Iconos.Loading className="icon_loading" /></h5>
                        :
                        <h5>{infoCustomer?.first_name} {infoCustomer?.last_name}</h5>
                    }
                    {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                    {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}
                    {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                    {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                    {infoCustomer?.default_address?.zip && <p className="info_nota"><b>Observación:</b> {infoCustomer?.default_address?.zip}</p>}
                </div>
            </>
            }
        </td>;
    }

    const Row = ({ data, interline }) => {
        const [isOpen, setIsOpen] = React.useState("");
        let fecha_creado = new Date(data.created_at);
        let _tag = data.tags.includes("CONTRAENTREGA") ? "CONTRAENTREGA" : null;
        let _carrier = "";
        carrierList.forEach(item => {
            if (data.tags.toLowerCase().includes(item.nombres.trim().toLowerCase())) {
                _carrier = item.nombres.trim();
            }
        });

        return <Menu
            inRow={true}
            data={data}
            interline={interline}
            navigate={navigate}
            current={selected.id}
            onSel={setSelected}
            setOpen={open => setIsOpen(open)}
            reload={() => getList(currentQuery.orders)}>

            {media_movil ?
                <td>
                    <strong>{data.name}</strong>
                    <pre><span>Artículos:</span> {data.total_articulos} {data.total_articulos > 1 ? "artículos" : "articulo"}</pre>
                    <pre><span>Valor:</span> ${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</pre>
                    <pre><span>Pago:</span> {data.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.financial_status.toLowerCase() === "paid" ? "Pagado" : data.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.financial_status} {_tag && <b className="tag">{_tag}</b>}</pre>
                    <pre><span>Preparación:</span> {isOpen === "reabierto" ? "Reabierto" : data.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</pre>
                    <pre><span>Cliente:</span> {data.customer.first_name} {data.customer.last_name}</pre>
                    <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                </td>
                :
                <>
                    <td>{data.name}</td>
                    <CardProduct data={data} />
                    <td>${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</td>
                    <td>{data.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.financial_status.toLowerCase() === "paid" ? "Pagado" : data.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.financial_status} {_tag && <b className="tag">{_tag}</b>}</td>
                    <td>{isOpen === "reabierto" ? "Reabierto" : data.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</td>
                    <td>{_carrier}</td>
                    <td>{data.tags.replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",")}</td>
                    {data.customer ? <CardCustomer customer={data.customer} /> : <td><span>Sin cliente</span></td>}
                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                </>
            }
        </Menu>;
    }

    const AllRow = () => {
        let _footer;
        let _data = dataList.map((item, i) => {
            item.total_articulos = item.line_items.map((item) => item.quantity).reduce((a, b) => a + b, 0);
            if (item.note_attributes.length > 0) {
                item.note_attributes = item.note_attributes.filter(attr => {
                    switch (attr.name) {
                        // case "Nombre completo":
                        // case "Email":
                        // case "Numero de WhatsApp":
                        // case "Celular":
                        // case "Dirección":
                        // case "Departamento":
                        // case "Ciudad":
                        // case "Barrio":
                        // case "utm_source":
                        case "IP Address":
                        case "_pos":
                        case "_sid":
                        case "_ss":
                        case "utm_medium":
                        case "utm_id":
                        case "utm_content":
                        case "utm_term":
                        case "utm_campaign":
                        case "fbclid":
                        case "campaign_id":
                        case "ad_id":
                        case "useragent":
                        case "full_url":
                            return false;
                        default:
                            return true;
                    }
                });
            }

            return <Row
                key={item.id}
                interline={i % 2}
                data={item} />;
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">{loadResult}</h4>;
        } else if (!navBtns.next.show && !navBtns.prev.show) {
            _footer = <h4 className="msg_lazy_load">{loadResult}</h4>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <div className="content_btns _center">
                        {navBtns.next.show && <button className="btn btn_lazy_load" onClick={() => getListLazy(navBtns.next)}>Anterior</button>}
                        {navBtns.prev.show && <button className="btn btn_lazy_load" onClick={() => getListLazy(navBtns.prev)}>Siguiente</button>}
                    </div>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}<FiltroPreparacion /></th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Pedido</th>
                            <th style={{ minWidth: "110px" }}>Artículos</th>
                            <th>Valor</th>
                            <th>Pago<FiltroPago /></th>
                            <th>Preparación<FiltroPreparacion /></th>
                            <th>Transportadora<FiltroCarrier /></th>
                            <th>Etiquetas<FiltroTags /></th>
                            <th>Cliente</th>
                            <th><Iconos.SwapVertical onClick={() => sortBy("fecha_creado")} />Fecha</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const sortBy = (_key) => {
        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async (_obj) => {
        setShowLoading(true);
        let position = _obj.dir === "next" ? dataList.length - 1 : 0;
        let _data = await api.fetchDirect({
            url: _obj.url,
            data: {
                task: currentQuery.orders.task,
                criterio: currentQuery.orders.criterio,
                lazy: true,
                direction: _obj.dir,
                cursor: _obj.cursor,
                limit: 20,
                since_id: dataList[position].id
            }
        });

        if (_data.response == 1) {

            if (_data.headers?.link) {
                let _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                let _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
                setNavBtns({
                    prev: {
                        show: _prev.length > 4,
                        url: "shopify-get-orders",
                        link: _prev,
                        dir: "prev"
                    },
                    next: {
                        show: _next.length > 4,
                        url: "shopify-get-orders",
                        link: _next,
                        dir: "next"
                    }
                });
            } else {
                setNavBtns({
                    prev: {
                        show: _data.pageInfo.hasPreviousPage,
                        url: _obj.url,
                        dir: "prev",
                        cursor: _data.pageInfo.startCursor
                    },
                    next: {
                        show: _data.pageInfo.hasNextPage,
                        url: _obj.url,
                        dir: "next",
                        cursor: _data.pageInfo.endCursor,
                    }
                });
            }

            setDataList(_data.data);

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (currentQuery.orders.task === "todos") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos!" : "¡No hay más pedidos!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos " + currentQuery.orders.task + "!" : "¡No hay más pedidos " + currentQuery.orders.task + "!");
        }

        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult(_filter.task === "todos" ? "Cargando pedidos..." : _filter.task === "search" ? "Cargando pedidos con: " + _filter.criterio + "..." : "Cargando pedidos " + _filter.task + "...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, orders: _filter }));
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: _filter.task,
                criterio: _filter.criterio,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({
                prev: {
                    show: _prev.length > 4,
                    url: "shopify-get-orders",
                    link: _prev,
                    dir: "prev"
                },
                next: {
                    show: _next.length > 4,
                    url: "shopify-get-orders",
                    link: _next,
                    dir: "next"
                }
            });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter.task === "todos") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos!" : "¡No hay más pedidos!");
        } else if (_filter.task === "search") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos con: " + _filter.criterio + "!" : "¡No hay más pedidos con: " + _filter.criterio + "!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos " + _filter.task + "!" : "¡No hay más pedidos " + _filter.task + "!");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        let _task = _val === "" ? "todos" : "search";
        setLoadResult("Buscando pedidos con: " + _val + "...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, orders: { task: _task, criterio: _val } }));
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: _task,
                criterio: _val,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_task === "todos") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos!" : "¡No hay más pedidos!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay pedidos con: " + _val : "No hay más pedidos con: " + _val);
        }

        setShowLoading(false);
    }

    const searchCarriers = async (_filter) => {
        setLoadResult("Cargando transportadora " + _filter.criterio + "...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, orders: _filter }));
        setDataList([]);
        let _data = await api.fetchJson({
            url: "shopify-get-all-carriers",
            data: {
                task: _filter.task,
                criterio: _filter.criterio,
                limit: 50,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            setNavBtns({
                prev: {
                    show: false, // _data.pageInfo.hasPreviousPage,
                    url: "shopify-get-carriers",
                    dir: "prev",
                    cursor: _data.pageInfo.startCursor
                },
                next: {
                    show: false, // _data.pageInfo.hasNextPage,
                    url: "shopify-get-carriers",
                    dir: "next",
                    cursor: _data.pageInfo.endCursor,
                }
            });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos de la transportadora " + _filter.criterio + "!" : "¡No hay más pedidos de la transportadora " + _filter.criterio + "!");

        setShowLoading(false);
    }

    const searchTags = async (_filter) => {
        setLoadResult(_filter.criterio === "" ? "Cargando pedidos sin etiqueta" : "Cargando pedidos con etiqueta “" + _filter.criterio.replace(/"/g, "") + "“...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, orders: _filter }));
        setDataList([]);
        let _data = await api.fetchJson({
            url: "shopify-get-tags",
            data: {
                task: _filter.task,
                criterio: _filter.criterio,
                limit: 50,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            setNavBtns({
                prev: {
                    show: false, // _data.pageInfo.hasPreviousPage,
                    url: "shopify-get-carriers",
                    dir: "prev",
                    cursor: _data.pageInfo.startCursor
                },
                next: {
                    show: false, // _data.pageInfo.hasNextPage,
                    url: "shopify-get-carriers",
                    dir: "next",
                    cursor: _data.pageInfo.endCursor,
                }
            });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter.criterio === "") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos sin etiqueta!" : "¡No hay más pedidos sin etiqueta!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos con la etiqueta “" + _filter.criterio.replace(/"/g, "") + "“!" : "¡No hay más pedidos con la etiqueta “" + _filter.criterio.replace(/"/g, "") + "“!");
        }

        setShowLoading(false);
    }

    const getCarriers = async () => {
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "carriers"
            }
        });

        if (_data.response == 1) {
            setCarrierList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const getTags = async () => {
        let _data = await api.fetchJson({
            url: "get-tags",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setTagList([{ name: "Sin etiquetas", id: 0 }, ..._data.data]);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        if (cleanFilters) {
            setCleanFilters(false);
            setTitulo("Todos los pedidos");
            getList({ task: "todos", criterio: "" });
        }
    }, [cleanFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (currentQuery.orders.task !== "todos") {
            setShowBtnClean(true);
        } else {
            setShowBtnClean(false);
        }
    }, [currentQuery.orders]);

    React.useEffect(() => {
        setTitle("Pedidos");

        funciones.current.validaSesion("pedidos_ver").then(response => {
            if (response) {
                setPermission(true);
                getCarriers();
                getTags();
                switch (currentQuery.orders.task) {
                    case "tags":
                        if (currentQuery.orders.criterio === "Sin etiquetas") {
                            setTitulo("Pedidos sin etiqueta");
                        } else {
                            setTitulo("Pedidos con etiqueta “" + currentQuery.orders.criterio.replace(/"/g, "") + "“");
                        }
                        searchTags(currentQuery.orders);
                        break;
                    case "transportadora":
                        setTitulo("Transportadora " + currentQuery.orders.criterio);
                        searchCarriers(currentQuery.orders);
                        break;

                    default:

                        getList(currentQuery.orders);
                        break;
                }
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2>{titulo}</h2>
                {showBtnClean && <button type="button" className="btn btn_with_icon _left" onClick={() => setCleanFilters(true)}><Iconos.FilterClose /> Limpiar filtros</button>}

                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar pedido"
                        value={currentQuery.orders.criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            {funciones.current.validaPermisos("pedidos_crear") &&
                <NavLink
                    to="/nuevo-pedido"
                    className="btn btn_add_to_list _right">
                    Crear pedido<Iconos.PedidoAdd />
                </NavLink>}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Orders);