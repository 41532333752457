import * as React from "react";
import { Alert, Confirm } from "shared/Alert";
import { Funciones } from "services/funciones";
import VoucherReport from "./VoucherReport";
import Loading from "shared/Loading";
import AddOrder from "./AddOrder";
import * as Iconos from "images";
import { useMedia } from "hooks";
import Options from "./Options";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, onSel, reload, current, inRow = false } = params;
    const { setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10 };
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);


    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/detalle-comprobante", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm("¿Seguro de borrar este comprobante?", async (response) => {
            if (response) {
                setShowLoading(true);
                let _data = await api.fetchJson({
                    url: "delete-voucher",
                    data: {
                        info: JSON.stringify(data)
                    }
                });

                if (_data.response == 1) {
                    Alert(_data.msg, "ok", () => reload());
                } else if (_data.response == -2) {
                    Alert(_data.msg, "warning", () => navigate("/sign-in"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });

    }

    // eslint-disable-next-line
    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const BtnMarkAs = () => {
        let _btn = <li className="unable"><Iconos.VoucherOk /> <span>Validar</span></li>;
        let _btn_add = <></>;


        const changeStatus = (e) => {
            e.preventDefault();

            setModalView(<Options
                data={data}
                parent={{ top: e.clientY, left: e.clientX, width: 300 }}
                reload={() => reload()}
                close={() => setModalView(null)} />);
        }

        const addOrder = (e) => {
            e.preventDefault();

            setModalView(<AddOrder
                data={data}
                parent={{ top: e.clientY, left: e.clientX, width: 230 }}
                reload={() => reload()}
                close={() => setModalView(null)} />);
        }

        if ([5].includes(parseInt(data_user.nivel))) {
            if (data.pedido.length < 1 && data.asesor.id == window.atob(data_user.auth)) {
                _btn_add = <li onClick={addOrder}><Iconos.VoucherEdit /> <span>Agregar pedido</span></li>
            } else {
                _btn_add = <li className="unable"><Iconos.VoucherEdit /> <span>Agregar pedido</span></li>
            }
        }

        if (funciones.current.validaPermisos("comprobantes_valida")) {
            if (data.estado == 0 || funciones.current.validaPermisos("comprobantes_revalida")) {
                _btn = <li onClick={changeStatus}><Iconos.VoucherOk /> <span>Validar</span></li>
            }
        } else {
            _btn = <></>;
        }

        return <>{_btn_add} {_btn}</>;
    }

    const BtnDownloads = () => {
        let _btn = <li className="unable"><Iconos.ExcelDownload /> <span>Descargar excel</span></li>;

        const handleOpen = (e) => {
            e.preventDefault();

            setModalView(<VoucherReport
                parent={{ top: e.clientY, left: e.clientX, width: 250 }}
                close={() => setModalView(null)} />);
        }

        if (funciones.current.validaPermisos("comprobantes_informes") && [1, 2, 3].includes(parseInt(data_user.nivel))) {
            _btn = <li onClick={handleOpen}><Iconos.ExcelDownload /> <span>Descargar excel</span></li>;
        } else {
            _btn = <></>;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        if (funciones.current.validaPermisos("comprobantes_borra")) {
            if (data.estado != 1 || funciones.current.validaPermisos("comprobantes_borra_in_close")) {
                return <li onClick={goDelete}><Iconos.Delete /> <span>Borrar</span></li>;
            } else {
                return <li className="unable"><Iconos.Delete /> <span>Borrar</span></li>;
            }
        } else {
            return <li className="unable"><span style={{ display: "inline-block", width: "2.5rem" }}></span><span>...</span></li>;
        }
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: "0", left: "0" });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            if (inRow) {
                setPos(funciones.current.getPosInRow(e, menuRef.current));
            } else {
                setPos(funciones.current.getPosInDisplay(e, menuRef.current));
            }
            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (
            <>
                <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                    <ul>
                        {inRow ?
                            <li onClick={goOpen}><Iconos.OpenIn /> <span><strong>Detalle</strong></span></li>
                            :
                            <li className="unable"><Iconos.OpenIn /> <span><strong>...</strong></span></li>
                        }

                        <hr />
                        <BtnMarkAs />
                        <BtnDownloads />
                        <hr />
                        <BtnDelete />
                    </ul>
                    <Loading state={showLoading} />
                </nav>
            </>)
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    React.useEffect(() => {
        if (inRow && data.estado == 1) {
            setClases(c => c + " bkg_aceptado");
        }

        if (inRow && data.estado == 2) {
            setClases(c => c + " bkg_rechazado");
        }
    }, []);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    // onClick={onSelect}
                    onDoubleClick={goOpen}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="container _detalle" onContextMenu={handleContextMenu}>

                        {children}
                        {modalView}
                    </div>
                </>}
        </>
    )
}

export default Menu;