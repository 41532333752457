import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import logo from "images/logo/logo_light.png";
import withRouter from "services/withRouter";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import log from "services/log";
import Bill from "./Bill";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const getDPI = () => {
    // Crea un elemento invisible para medir
    const dpiElement = document.createElement('div');
    dpiElement.style.width = '1in'; // 1 pulgada en CSS
    dpiElement.style.height = '1in'; // 1 pulgada en CSS
    dpiElement.style.position = 'absolute';
    dpiElement.style.left = '-100%'; // Posiciona fuera de la vista
    document.body.appendChild(dpiElement);

    // Obtiene el tamaño en píxeles
    const dpi = dpiElement.offsetWidth;

    // Elimina el elemento después de medir
    document.body.removeChild(dpiElement);

    return dpi;
}

const PrintBill = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [data, setData] = React.useState([]);
    const dpi = getDPI();
    const widthInInches = 8.5;  // Ancho en pulgadas
    const heightInInches = 11;  // Alto en pulgadas
    const width = widthInInches * dpi;
    const height = heightInInches * dpi;


    React.useEffect(() => {
        const handleBeforePrint = () => {
            log.w("Se activó la impresión");
            const printStyles = `
                @page {
                    size: ${height}px ${width + 12}px;
                }
                @media print {
                      body {
                        width: ${height}px;
                        height: ${width + 12}px;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(255, 255, 255) !important;
                        color: rgb(20, 20, 20);
                      }

                      header,
                      footer,
                      .head_bar,
                      .content_btns {
                        display: none !important;
                      }

                      .wrapper {
                        width: 100% !important;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(255, 255, 255) !important;

                        .container {
                          display: block;
                          width: 100% !important;
                          margin: 0 !important;
                          padding: 0 !important;
                          background-color: rgb(255, 255, 255) !important;
                          color: rgb(20, 20, 20) !important;

                          .content_to_print._bill_row {
                            page-break-after: always;

                            ._bill {
                              width: 100% !important;

                              .info_logo_hit {
                                background-image: url(${logo});
                              }

                              .info_tags {
                                margin-left: 1rem;
                                border: 1px solid rgb(20, 20, 20) !important;
                              }

                              .info_notas {
                                margin-left: 1rem;
                                border: 1px solid rgb(20, 20, 20) !important;
                              }

                              .info_customer {
                                border: 2px dashed rgb(20, 20, 20) !important;
                              }

                              .info_articles {
                                table {
                                  thead {
                                    th {
                                      background-color: rgb(20, 20, 20) !important;
                                      color: rgb(255, 255, 255) !important;
                                      border: 1px solid rgb(20, 20, 20) !important;
                                    }
                                  }

                                  tbody {
                                    tr {
                                      border: 1px solid rgb(20, 20, 20) !important;
                                    }
                                  }
                                }
                              }

                              .info_footer {
                                background-color: rgb(255, 255, 255) !important;

                                .info_price {
                                  &._total {
                                    background-color: rgb(20, 20, 20) !important;
                                    color: rgb(255, 255, 255) !important;
                                  }
                                }

                                h6 {
                                  border: 2px dashed rgb(20, 20, 20) !important;
                                }
                              }
                            }
                          }
                            
                          ._qr_code {
                            svg {
                              path {
                                stroke: rgb(20, 20, 20);

                                &:first-child {
                                  stroke: none;
                                  fill: rgb(255, 255, 255);
                                }
                              }
                            }
                          }

                          ._bar_code {
                            svg {
                              g {
                                fill: rgb(20, 20, 20) !important;
                              }
                            }
                          }
                        }
                      }
                    }
            `;

            if (document.getElementById("printGuideStyles")) {
                document.getElementById("printGuideStyles").remove();
            }

            if (!document.getElementById("printOrdersStyles")) {
                const styleSheet = document.createElement("style");
                styleSheet.id = "printOrdersStyles";
                styleSheet.innerHTML = printStyles;
                document.head.appendChild(styleSheet);
                log.d("Se agrego el estilo de impresión");
            } else {
                log.d("El estilo de impresión ya existe");
            }
        };

        window.addEventListener('beforeprint', handleBeforePrint);

        return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
        };
    }, []);

    React.useEffect(() => {
        if (location.state && location.state.data) {
            let _data = location.state.data;
            setTitle("Imprimir " + _data.length + " factura" + (_data.length > 1 ? "s" : ""));
            setShowLoading(true);
            log.d("datos", _data);

            funciones.current.validaSesion("pedidos_edita").then(response => {
                if (response) {
                    setPermission(true);
                    let _new_data = _data.map(item => {

                        item.total_price = item.total_price.toString().replace(".", ",");
                        item.subtotal_price = item.subtotal_price.toString().replace(".", ",");
                        item.shipping_price = item.total_shipping_price_set.shop_money.amount.toString().replace(".", ",");
                        item.partial_price = item.total_line_items_price_set.shop_money.amount.toString().replace(".", ",");
                        item.total_discounts = item.total_discounts.toString().replace(".", ",");
                        item.notas = item.note && <><b>Nota:</b> {item.note}</>;
                        item.note_attributes.forEach(attr => {
                            if (attr.name === "Observación") {
                                item.notas = item.notas ? <>{item.notas}<br /> <b>Observación:</b> {attr.value}</> : <><b>Observación:</b> {attr.value}</>;
                            }
                        });
                        item.receiver_address = {
                            first_name: item.shipping_address ? item.shipping_address.first_name ?? "" : item.customer.default_address?.first_name ?? "",
                            address1: item.shipping_address ? item.shipping_address.address1 ?? "" : item.customer.default_address?.address1 ?? "",
                            phone: item.shipping_address ? item.shipping_address.phone ?? "" : item.customer.default_address?.phone ?? "",
                            city: item.shipping_address ? item.shipping_address.city ?? "" : item.customer.default_address?.city ?? "",
                            zip: item.shipping_address ? item.shipping_address.zip ?? "" : item.customer.default_address?.zip ?? "",
                            province: item.shipping_address ? item.shipping_address.province ?? "" : item.customer.default_address?.province ?? "",
                            country: item.shipping_address ? item.shipping_address.country ?? "" : item.customer.default_address?.country ?? "",
                            last_name: item.shipping_address ? item.shipping_address.last_name ?? "" : item.customer.default_address?.last_name ?? "",
                            address2: item.shipping_address ? item.shipping_address.address2 ?? "" : item.customer.default_address?.address2 ?? "",
                            company: item.shipping_address ? item.shipping_address.company ?? "" : item.customer.default_address?.company ?? "",
                            latitude: item.shipping_address ? item.shipping_address.latitude ?? "" : item.customer.default_address?.latitude ?? "",
                            longitude: item.shipping_address ? item.shipping_address.longitude ?? "" : item.customer.default_address?.longitude ?? "",
                            name: item.shipping_address ? item.shipping_address.name ?? "" : item.customer.default_address?.name ?? "",
                            country_code: item.shipping_address ? item.shipping_address.country_code ?? "" : item.customer.default_address?.country_code ?? "",
                            province_code: item.shipping_address ? item.shipping_address.province_code ?? "" : item.customer.default_address?.province_code ?? "",
                        }

                        item.tags = item.tags.replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",");

                        let _fecha_creado = new Date(item.created_at);
                        item.order_created = format(_fecha_creado.getDate()) + "/" + meses[_fecha_creado.getMonth()] + "/" + _fecha_creado.getFullYear();
                        item.order_created_num = format(_fecha_creado.getDate()) + "/" + format(_fecha_creado.getMonth() + 1) + "/" + _fecha_creado.getFullYear()

                        return item;
                    });
                    setData(_new_data);
                } else {
                    setPermission(false);
                }
                setShowLoading(false);
            });

        } else {
            setTitle("Imprimir Factura");
            setShowLoading(false);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar guide">
                {media_movil && <h4>En la versión movil no esta disponible la impresión</h4>}
            </div>

            {data.map((bill, i) => <div key={i} className="content_to_print _bill_row" style={{ width: height + "px", marginBottom: "3rem" }}>
                <div className="_bill" style={{ width: "50%", marginRight: "0.9rem" }}>
                    <Bill bill={bill} navigate={navigate} />
                </div>
                <div className="_bill" style={{ width: "50%", marginLeft: "0.9rem" }}>
                    <Bill bill={bill} navigate={navigate} />
                </div>
            </div>)}

            <div className="content_btns _floating">
                {!media_movil && <button type="button" className="btn btn_primary" onClick={() => window.print()}>Imprimir</button>}
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(PrintBill);