import * as React from 'react';
import load_img from "images/loading.svg";
import loading_alone from "images/loading_alone.svg";

const Loading = ({ state }) => {
    return (
        <>
            {state ?
                <div className="load">
                    <div className="load__icon-wrap">
                        <img src={load_img} alt="Loading..." />
                    </div>
                </div>
                :
                <></>
            }
        </>
    );
}

export const LoadingProgress = ({ state, progress }) => {
    return (
        <>
            {state ?
                <div className="load">
                    <div className="load__icon-wrap">
                        <img src={loading_alone} alt="Loading..." />
                        <h2 className="load__icon-progress">{progress}%</h2>
                    </div>
                </div>
                :
                <></>
            }
        </>
    );
}

export default Loading;