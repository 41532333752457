import * as React from "react";
import { BarCode, QrCode } from "shared/BarCode";
import { Funciones } from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Bill = ({ navigate, bill }) => {
    const { setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));

    return (
        <>
            <div className="in_row">
                <div className="in_column" style={{ width: "100%", paddingRight: "0.3rem" }}>
                    <h5 className="info_greeting">😀 Gracias por la confianza depositada en nosotros 😀 </h5>
                    <div className="info_logo_hit"></div>
                    <h4 className="info_tags">{bill.tags ?? bill.order_created}</h4>
                    <h4 className="info_price">Total a Pagar: ${funciones.current.formatPrice(bill.total_price).format}</h4>
                    <h5 className="info_date">Fecha de Compra: {bill.order_created}</h5>
                    <h1 className="info_number">Nº Compra: {bill.order_number}</h1>
                </div>
                <div className="in_column" style={{ width: "190px", minWidth: "190px" }}>
                    <QrCode content={`${bill.name} ( ${bill.order_created_num} ( ${bill.total_articulos} ( ${bill.receiver_address.first_name} ${bill.receiver_address.last_name} ( ${bill.total_price} ( ${bill.receiver_address.city} ( ${bill.receiver_address.phone} ( ${bill.receiver_address.company} ( ${bill.tags.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`} />
                    <BarCode code={"Hit " + bill.order_number} />
                </div>
            </div>

            {bill.notas && <h4 className="info_notas">{bill.notas}</h4>}

            <p className="info_customer">
                <span>Nombre: <b>{bill.receiver_address.first_name} {bill.receiver_address.last_name}</b></span>
                <span>Teléfono: <b>{bill.receiver_address.phone}</b></span>
                {bill.receiver_address.address2 && <span>Teléfono 2: <b>{bill.receiver_address.address2}</b></span>}
                <span>Dirección: <b>{bill.receiver_address.address1}</b></span>
                <span>Departamento: <b>{bill.receiver_address.province}</b></span>
                <span>Ciudad: <b>{bill.receiver_address.city}</b></span>
            </p>

            <div className="info_articles">
                <table>
                    <thead>
                        <tr>
                            <th>Productos</th>
                            <th>Cantidad</th>
                            <th className="_right">Precio</th>
                            <th className="_right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bill.line_items && bill.line_items.map((item, i) =>
                            <tr key={i}>
                                <td>{item.name}</td>
                                <td className="_center">{item.quantity}</td>
                                <td className="_right">{funciones.current.formatPrice(item.price.toString().replace(".", ",")).format}</td>
                                <td className="_right">{funciones.current.formatPrice(item.price * item.quantity).format}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="info_footer">
                <h4 className="info_price"><span className="left">Valor parcial</span> <span className="right">${funciones.current.formatPrice(bill.subtotal_price).format} {bill.currency}</span></h4>
                <h4 className="info_price"><span className="left">Valor envío </span> <span className="right">${funciones.current.formatPrice(bill.shipping_price).format} {bill.currency}</span></h4>
                {/* <h4 className="info_price"><span className="left">Total Parcial</span> <span className="right">${funciones.current.formatPrice(bill.partial_price).format} {bill.currency}</span></h4> */}
                <h4 className="info_price"><span className="left">Descuento</span> <span className="right">${funciones.current.formatPrice(bill.total_discounts).format} {bill.currency}</span></h4>
                <h4 className="info_price _total"><span className="left">Total</span> <span className="right">${funciones.current.formatPrice(bill.total_price).format} {bill.currency}</span></h4>

                <h6>Si tienes algun inconveniente comunicarse al WhatsApp: 312 873 4751 Este Producto tiene Garantía Recuerda NO ARRANCAR LOS SELLOS QUE TIENE EL PRODUCTO PARA NO PERDER LA GARANTIA </h6>
            </div>
        </>
    )
}

export default Bill;