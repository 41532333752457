import * as React from "react";
import { InputCheck, InputWithIcon, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { useMedia } from "hooks";
import * as Iconos from "images";
import Menu from "./Menu";
import {
    getPerfil,
    permisos_para_asesores,
    permisos_para_almacenista,
    permisos_para_contadores,
    permisos_para_transportadoras,
    permisos_para_domiciliarios,
    permisos_para_admin
} from "shared/permisos";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DetailUser = (router) => {
    const { navigate, location } = router;
    const { setTitle, setDataUser } = useMedia();
    const [perfil, setPerfil] = React.useState({ plural: "usuarios", singular: "usuario" });
    const [listaPermisos, setListaPermisos] = React.useState([{ "title_line": "Permisos de " + perfil.singular }]);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [permisosUser, setPermisosUser] = React.useState({});
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [direccion, setDireccion] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [apellido, setApellido] = React.useState("");
    const [tipoDoc, setTipoDoc] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [numDoc, setNumDoc] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [data, setData] = React.useState({});

    const direccionRef = React.useRef(null);
    const telefonoRef = React.useRef(null);
    const apellidoRef = React.useRef(null);
    const tipoDocRef = React.useRef(null);
    const nombreRef = React.useRef(null);
    const numDocRef = React.useRef(null);
    const emailRef = React.useRef(null);



    const armaPerfil = (_rol) => {
        let _perfil = getPerfil(_rol);

        setPerfil(_perfil);
        return _perfil;
    }

    React.useEffect(() => {

        if (location.state && location.state.data) {
            let _data = location.state.data;
            let data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };
            let _rol = _data.nivel;
            let _perfil = armaPerfil(_rol);
            let _permission = "permitido";
            let _listaPermisos = [];

            setTitle(_perfil.singular + " " + location.state.data.nombres, location.state);

            switch (parseInt(_rol)) {
                case 1:
                case 2:
                    // 2 Administrador                    
                    _permission = "admin_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_admin,
                    ];
                    break;
                case 3:
                    // 3 Contador o asistente contable
                    _permission = "contadores_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_contadores,
                    ];
                    break;
                case 4:
                    // 4 Almacenista
                    _permission = "almacenistas_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_almacenista,
                    ];
                    break;
                case 5:
                    //  5 vendedor o asesores
                    _permission = "acesor_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_asesores,
                    ];
                    break;
                case 6:
                    //  6 transportadoras
                    _permission = "carrier_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_transportadoras,
                    ];
                    break;
                case 7:
                    //  7 domiciliarios
                    _permission = "domics_ver";
                    _listaPermisos = [
                        { "title_line": "Permisos de " + _perfil.singular },

                        ...permisos_para_domiciliarios,
                    ];
                    break;
            }

            setListaPermisos(_listaPermisos);
            setData(_data);

            funciones.current.validaSesion(_permission).then(response => {
                if (response && [1, 2].includes(parseInt(data_user.nivel))) {
                    setPermission(true);
                    setNombre(_data.nombre || "");
                    setApellido(_data.apellido || "");
                    setTipoDoc(_data.doc_tipo || "");
                    setNumDoc(_data.doc_numero || "");
                    setTelefono(_data.telefono || "");
                    setDireccion(_data.direccion || "");
                    setEmail(_data.correo || "");

                    let _permisosUser = {};

                    _listaPermisos.map((perm, i) => {
                        // eslint-disable-next-line
                        const [key, value] = Object.entries(perm)[0];
                        if (_data.permisos[key] !== undefined) {
                            _permisosUser = { ..._permisosUser, [key]: 1 }
                        }
                    });

                    setPermisosUser(_permisosUser);
                } else {
                    setPermission(false);
                }
                setShowLoading(false);
            });

        } else {
            setShowLoading(false);
            setTitle("Detalle usuario", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} menu={true} loading={showLoading}>
            <Menu
                data={data}
                navigate={navigate}>

                <div className="head_bar">
                    <h2 className="head_bar-title _capitalize">{perfil.singular + "\n"} "{location.state && location.state.data.nombres}"</h2>
                </div>

                <div className="content_primary _on_details">

                    <InputWithIcon
                        ref={nombreRef}
                        type="text"
                        title="Nombre"
                        readOnly={true}
                        value={nombre}
                        onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

                    <InputWithIcon
                        ref={apellidoRef}
                        type="text"
                        title="Apellidos"
                        readOnly={true}
                        value={apellido}
                        onChange={v => { alertaRemove(apellidoRef.current); setApellido(v) }} />

                    <SelectWithIcon
                        ref={tipoDocRef}
                        type="text"
                        title="Tipo de documento"
                        Icono={Iconos.CardSelect}
                        readOnly={true}
                        value={tipoDoc}
                        onChange={v => { alertaRemove(tipoDocRef.current); setTipoDoc(v) }} >
                        <option value="">Selecciona tipo de documento</option>
                        <option>Cédula de Ciudadanía</option>
                        <option>Cédula de extranjería</option>
                        <option>Pasapote</option>
                        <option>NIT</option>
                    </SelectWithIcon>

                    <InputWithIcon
                        ref={numDocRef}
                        type="number"
                        title="Número de documento"
                        Icono={Iconos.CardDetails}
                        readOnly={true}
                        value={numDoc}
                        onChange={v => { alertaRemove(numDocRef.current); setNumDoc(v) }} />

                    <InputWithIcon
                        ref={telefonoRef}
                        type="tel"
                        title="Teléfono"
                        Icono={Iconos.Phone}
                        readOnly={true}
                        value={telefono}
                        onChange={v => { alertaRemove(telefonoRef.current); setTelefono(v) }} />

                    <InputWithIcon
                        ref={direccionRef}
                        type="text"
                        title="Dirección"
                        Icono={Iconos.HomeMarker}
                        readOnly={true}
                        value={direccion}
                        onChange={v => { alertaRemove(direccionRef.current); setDireccion(v) }} />

                    <InputWithIcon
                        ref={emailRef}
                        type="text"
                        title="Correo eléctronico"
                        Icono={Iconos.Email}
                        readOnly={true}
                        value={email}
                        onChange={v => { alertaRemove(emailRef.current); setEmail(v) }} />


                </div>

                <div className="content_collapse _open _detalle">

                    {listaPermisos.map((perm, i) => {
                        const [key, value] = Object.entries(perm)[0];

                        if (key == "title_line") {
                            return <div key={i} >
                                <button type="button" className="btn btn_primary">
                                    <Iconos.Permissions />
                                    {value}
                                </button>
                            </div>;
                        } else if (key == "breack_line") {
                            return <div key={i} className="breack_line"><hr /><i>{value}</i><hr /></div>;
                        } else {
                            return permisosUser[key] !== undefined ?
                                <InputCheck
                                    key={i}
                                    title={value}
                                    readOnly={true}
                                    checked={permisosUser[key] !== undefined}
                                    onClick={() => { }} />
                                : null;
                        }
                    })}

                </div>
            </Menu>
        </RequirePermissions>
    )
}

export default withRouter(DetailUser);