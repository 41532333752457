import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { InputWithIcon } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */

const CashOpening = (router) => {
    const { navigate, params } = router;
    const { setTitle, data_user, setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [monto, setMonto] = React.useState("");

    const montoRef = React.useRef(null);

    const openCash = async (e) => {
        e.preventDefault();

        alertaRemove(montoRef.current);

        if (monto == null || monto.length == 0) {
            alerta(montoRef.current, "¡Debes ingresar el monto para la apertura!");
            montoRef.current.focus();
            return false;
        }

        if (monto < 0) {
            alerta(montoRef.current, "¡El monto ingresado no puede ser menor a cero!");
            montoRef.current.focus();
            return false;
        }

        if (isNaN(monto)) {
            alerta(montoRef.current, "¡El monto ingresado debe ser un número!");
            montoRef.current.focus();
            return false;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-cash-opening",
            data: {
                task: "local",
                info: {
                    monto: funciones.current.formatPrice(monto).int,
                    tienda: window.atob(params.local)
                }
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate("/", { replace: true }));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Apertura de caja");

        funciones.current.validaSesion("locales_admin").then(response => {
            if (response && data_user.local.id === params.local) {
                setPermission(true);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title _left">Apertura de caja</h2>
            </div>

            <div className="content_primary _in_center">
                <div className="in_column">
                    <InputWithIcon
                        ref={montoRef}
                        title="Monto en apertura"
                        name="monto"
                        Icono={Iconos.Currency}
                        value={funciones.current.formatPrice(monto).format}
                        onChange={v => { alertaRemove(montoRef.current); setMonto(v) }} />
                </div>
            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={openCash}>Abrir caja</button>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(CashOpening);