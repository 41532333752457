import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { DatePickerOnly, InputSearch } from "shared/Components";
import { Alert, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Conciliation = (router) => {
    const { navigate, location, params } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [currentQuery, setCurrentQuery] = React.useState({ task: "todos", criterio: "" });
    const titulo = "Conciliaciones con " + params.name.replace(/_/g, ' ');
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const FilterDate = () => {
        const [fecha, setFecha] = React.useState(currentQuery.task === "fecha" ? new Date(currentQuery.criterio + "T05:00:00") : new Date());
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const searchRef = React.useRef();


        const valida = (e) => {
            e.preventDefault();

            document.body.style.overflow = "auto";

            getList({
                task: "fecha",
                criterio: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
            });
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 170) });
                }

                if (currentQuery.task === "fecha") {
                    let _fecha = new Date(currentQuery.criterio + "T05:00:00");
                    setFecha(_fecha);
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={media_movil ? { ...pos, marginRight: "2rem" } : pos}>
                    {showFilter ?
                        <div className="filter_content">
                            <h4 className="filter_title">Filtrar fecha</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />

                            <DatePickerOnly
                                ref={searchRef}
                                placeholder="Fecha"
                                value={fecha}
                                onChange={(date) => { alertaRemove(searchRef.current); setFecha(date); }} />

                            <button type="button" className="btn btn_primary" onClick={valida}>Buscar</button>
                        </div>
                        :
                        <>
                            {currentQuery.task === "fecha" ?
                                <Iconos.FilterCheck className="filter_icon _check" onClick={handleClic} />
                                :
                                <Iconos.Filter className="filter_icon" onClick={handleClic} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }

    const Row = ({ data, interline }) => {
        let fecha_creado = new Date(data?.fecha_creado);

        // log.d("data in row", data);

        const handleSelect = (e) => {
            e.preventDefault();
            e.stopPropagation();
        }

        return (
            <Menu
                data={{ ...data, carrying_name: params.name }}
                interline={interline}
                navigate={navigate}
                reload={() => getList(currentQuery)}>

                {media_movil ?
                    <td onClick={handleSelect}>
                        <pre><span>Número:</span> #{data.id} <span>Guías:</span> {data.guias.length}</pre>
                        <pre><span>Recaudo:</span> {funciones.current.formatPrice(data.recaudo).format}</pre>
                        <pre><span>Sevicios:</span> {funciones.current.formatPrice(data.servicios).format}</pre>
                        <pre><span>Otros:</span> {funciones.current.formatPrice(data.otros.reduce((acumulador, elemento) => {
                            if (elemento.tipo === "Ingreso") {
                                return acumulador + parseInt(elemento.monto);
                            } else {
                                return acumulador - parseInt(elemento.monto);
                            }
                        }, 0)).format}</pre>
                        <pre><h4>Total: {funciones.current.formatPrice(data.total).format}</h4></pre>

                        <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                    </td>
                    :
                    <>
                        <td>#{data.id}</td>
                        <td>{data.guias.length}</td>
                        <td>{funciones.current.formatPrice(data.recaudo).format}</td>
                        <td>-{funciones.current.formatPrice(data.servicios).format}</td>
                        <td>{funciones.current.formatPrice(data.otros.reduce((acumulador, elemento) => {
                            if (elemento.tipo === "Ingreso") {
                                return acumulador + parseInt(elemento.monto);
                            } else {
                                return acumulador - parseInt(elemento.monto);
                            }
                        }, 0)).format}</td>
                        <td>{funciones.current.formatPrice(data.total).format}</td>
                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;
        let _info;

        dataList.map((item, i) => {
            _info = JSON.parse(item.info);

            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ..._info,
                    id: item.id,
                }} />);
        });

        if (_data.length < 1) {
            _footer = <>
                {currentQuery.task === "buscar" ?
                    <h4 className="msg_lazy_load _alone _left">No hay conciliaciones con el número #{currentQuery.criterio}</h4>
                    :
                    <h4 className="msg_lazy_load _alone _left">No tiene conciliaciones</h4>
                }
            </>;
        } else {
            _footer = <>
                {
                    showLoading ?
                        <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="msg_lazy_load">¡No hay más conciliaciones!</h4>
                            }
                        </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}<FilterDate /></th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Número</th>
                            <th>Guías</th>
                            <th>Recaudo</th>
                            <th>Sevicios</th>
                            <th>Otros</th>
                            <th>Total</th>
                            <th>Fecha creado <FilterDate /></th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-conciliation",
            data: {
                task: currentQuery.task,
                criterio: currentQuery.criterio,
                limit: 20,
                offset: dataList.length,
                carrying: params.carrying
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.replace(/#/g, '');

        getList({
            task: "buscar",
            criterio: _val.toLowerCase()
        });
    }

    const getList = async (_c_query) => {
        setShowLoading(true);
        setCurrentQuery(_c_query);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-conciliation",
            data: {
                task: _c_query.task,
                limit: 20,
                offset: 0,
                criterio: _c_query.criterio,
                carrying: params.carrying
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Conciliaciones con " + params.name.replace(/_/g, ' '));

        funciones.current.validaSesion("concilios_ver").then(response => {
            if (response) {
                setPermission(true);
                getList({ task: "todos", criterio: "" });
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, [params.carrying, location.pathname]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar with_margin">
                <h2>{titulo}</h2>
                <div className="search_bar">
                    <InputSearch type="text"
                        placeholder="Buscar por número"
                        onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            <div className="content_btns in_movil">
                <NavLink
                    to={`/conciliar-guias/${params.carrying}/${params.name}`}
                    className="btn btn_add_to_list _left">
                    Ir a conciliar guías <Iconos.TruckExcelUpload />
                </NavLink>

                <button className="btn btn_add_to_list" onClick={() => navigate("/conciliaciones", { state: { rol: 6 } })}>Cambiar transportadora <Iconos.Truck /></button>

                <NavLink
                    to={`/pedidos/${params.carrying}/${params.name}`}
                    className="btn btn_add_to_list _right">
                    Ir a los pedidos <Iconos.TruckOrders />
                </NavLink>
            </div>
            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Conciliation);