import * as React from "react";
import { DatePicker, SelectWithIcon } from "shared/Components";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-export-excel-ext";
import MovePopUp from "shared/MovePopUp";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

// const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_left = { horizontal: "left", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "ffb7e1cd" } },
    font: { sz: "12", bold: true, color: { rgb: "ffff0000" } },
    alignment: _align_left,
    border: _border
};


const _txt = { font: { sz: "10.5" }, border: _border };

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff64ff00" } },
    font: { sz: "10.5", color: { rgb: "ff3c3c3c" } },
    border: _border
};

const _txt_red = {
    fill: { patternType: "solid", fgColor: { rgb: "ffff3200" } },
    font: { sz: "10.5", color: { rgb: "fff0f0f0" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const VoucherReport = ({ parent, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [fileName, setFileName] = React.useState("No name");
    const [generado, setGenerado] = React.useState(false);
    const [fecha, setFecha] = React.useState(new Date());
    const [bankInfo, setBankInfo] = React.useState({});
    const [dataList, setDataList] = React.useState([]);
    const [cuentas, setCuentas] = React.useState([]);
    const [bank, setBank] = React.useState("");
    const [error, setError] = React.useState("");

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const handleBank = (_value) => {
        setError("");
        setGenerado(false);

        if (_value === "") {
            setBank("");
            setBankInfo({});
        } else {
            let item = cuentas.find(count => format(count.id) === _value);

            if (typeof item === "object") {
                setBank(_value);
                setBankInfo({ bank: item.id, bank_name: item.nombre });
            } else {
                setBank("");
                setBankInfo({});
                setError("¡La cuenta bancaria es invalida!");
            }
        }
    }

    const getList = async () => {
        setError("");
        setShowLoading(true);
        setDataList([]);
        let _radios = document.getElementsByName("estado");
        let _estado;

        _radios.forEach(element => {
            if (element.checked) {
                _estado = element.value;
            }
        });

        let _data = await api.fetchJson({
            url: "get-vouchers",
            data: {
                task: "para_excel",
                estado: _estado,
                bank: format(bankInfo.bank),
                fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
            }
        });

        if (_data.response == 1) {
            let _list = [];

            _data.data.map(item => {
                _list.push(item);
            });

            setDataList(_list);
            setGenerado(true);
        } else if (_data.response == -2) {
            setError(_data.msg);
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_with_icon _left unable"><Iconos.ExcelDownload />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const BotonGenerar = () => {

        if (showLoading) {
            return <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else {
            return <button className="btn btn_with_icon _left" onClick={() => getList()}><Iconos.ExcelSearch />Generar informe </button>;
        }
    }

    const HojaDeCalculo = () => {

        const multiDataSet = [
            {
                columns: [
                    { value: "Estado", style: _heads, width: 8 },
                    { value: "Comprobante", style: _heads, width: 11 },
                    { value: "Pedido", style: _heads, width: 10 },
                    { value: "Cuenta", style: _heads, width: 20 },
                    { value: "Asesor", style: _heads, width: 19 },
                    { value: "Fecha creado", style: _heads, width: 14 },
                    { value: "Fecha validación", style: _heads, width: 14 },
                    { value: "Nota", style: _heads, width: 20 },
                ],
                data: dataList.map(item => {
                    let _info = JSON.parse(item.info);
                    let _asesor = JSON.parse(item.asesor);

                    let fecha_validacion = _info.validacion ? new Date(_info.validacion.fecha) : new Date();
                    let fecha_creado = new Date(_info.fecha_creado);

                    return [
                        { value: _info.validacion ? _info.validacion.estado : "Pendiente", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _info.voucher ?? "", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _info.pedido ?? "", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _info.bank_name ? _info.bank + " - " + _info.bank_name : "", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _asesor.nombre ?? "", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: format(fecha_creado.getDate()) + "/" + meses_hsort[fecha_creado.getMonth()] + "/" + fecha_creado.getFullYear() + " " + format(fecha_creado.getHours()) + ":" + format(fecha_creado.getMinutes()), style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _info.validacion ? format(fecha_validacion.getDate()) + "/" + meses_hsort[fecha_validacion.getMonth()] + "/" + fecha_validacion.getFullYear() + " " + format(fecha_validacion.getHours()) + ":" + format(fecha_validacion.getMinutes()) : "Pendiente", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                        { value: _info.validacion ? _info.validacion.nota : "", style: item.estado == 1 ? _txt_green : item.estado == 2 ? _txt_red : _txt },
                    ];
                })
            }
        ];

        return <>
            {(dataList.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={multiDataSet} name={`${meses[fecha.getMonth()].slice(0, 3)} ${format(fecha.getDate())} de ${fecha.getFullYear()}`} />
                </ExcelFile>
            }
        </>;
    }

    const handleChange = (e) => {
        if (e.target.value === "todos") {
            setFileName(`Todos los comprobantes ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Comprobantes ${e.target.value} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }
        setGenerado(false);
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        let _radios = document.getElementsByName("estado");
        let _estado;

        _radios.forEach(element => {
            if (element.checked) {
                _estado = element.value;
            }
        });

        if (_estado === "todos") {
            setFileName(`Todos los comprobantes ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Comprobantes ${_estado} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }

        setGenerado(false);
    }, [fecha]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getBanksList();
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Informe comprobantes">

            <SelectWithIcon
                title="Cuenta Bancaria"
                Icono={Iconos.Bank}
                value={bank}
                onChange={handleBank} >
                <option value="">Todas las cuentas</option>
                {cuentas.map((item, i) => {
                    return <option key={i} value={format(item.id)}>{item.nombre}</option>;
                })}
            </SelectWithIcon>

            <DatePicker
                title="Fecha"
                value={fecha}
                onChange={(date) => { setError(""); setFecha(date); }} />

            <div className="radio_goup">
                <label><input type="radio" name="estado" value="pendientes" onChange={handleChange} /><Iconos.VoucherPending />Pendientes</label>
                <label><input type="radio" name="estado" value="rechazados" onChange={handleChange} /><Iconos.VoucherRemove />Rechazados</label>
                <label><input type="radio" name="estado" value="aceptados" onChange={handleChange} /><Iconos.VoucherOk />Aceptados</label>
                <label><input type="radio" name="estado" value="todos" onChange={handleChange} defaultChecked={true} /><Iconos.Vouchers />Todos</label>
            </div>


            {error.length > 1 && <h4 className="error"><Iconos.InformationCircle />{error}</h4>}

            {generado ?
                <HojaDeCalculo />
                :
                <BotonGenerar />
            }
        </MovePopUp>
    )
}

export default VoucherReport;