import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { DatePickerShort, DropZone } from "shared/Components";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Entrada from "./components/Entrada";
import Salida from "./components/Salida";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import log from "services/log";
import api from "services/api";
import * as XLSX from 'xlsx';

// eslint-disable-next-line 
const _test_rows = [
    [
        "Guía",
        "Valor",
        "Código",
        "Pedido",
        "Fecha entregado",
        "Estado"
    ],
    [
        "HIT 69119",
        "79000",
        "12345",
        "#69119",
        "23/mar/2024 17:14",
        "Pendiente"
    ],
    [
        "HIT 69118",
        "152000",
        "12345",
        "#69118",
        "23/mar/2024 17:12",
        "Pendiente"
    ],
    [
        "HIT 69429",
        "37000",
        "12345",
        "#69429",
        "23/mar/2024 17:14",
        "Pendiente"
    ],
    [
        "HIT 69399",
        "72000",
        "12345",
        "#69399",
        "23/mar/2024 17:12",
        "Pendiente"
    ],
    [
        "HIT 69417",
        "97000",
        "12345",
        "#69417",
        "23/mar/2024 17:12",
        "Pendiente"
    ],
    [
        "HIT 69403",
        "72000",
        "12345",
        "#69403",
        "23/mar/2024 17:13",
        "Pendiente"
    ],
    [
        "HIT 68675",
        "127000",
        "12345",
        "#68675",
        "16/mar/2024 17:00",
        "Pendiente"
    ],
    [
        "HIT 67969",
        "32000",
        "12345",
        "#67969",
        "09/mar/2024 17:52",
        "Pendiente"
    ],
    [
        "HIT 67985",
        "247000",
        "12345",
        "#67985",
        "09/mar/2024 17:52",
        "Pendiente"
    ],
    [
        "HIT 67976",
        "112000",
        "12345",
        "#67976",
        "09/mar/2024 17:52",
        "Pendiente"
    ],
    [
        "HIT 67968",
        "27000",
        "12345",
        "#67968",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 67972",
        "72000",
        "12345",
        "#67972",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 67965",
        "37000",
        "12345",
        "#67965",
        "09/mar/2024 17:52",
        "Pendiente"
    ],
    [
        "HIT 67964",
        "117000",
        "12345",
        "#67964",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 67967",
        "117000",
        "12345",
        "#67967",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 67978",
        "117000",
        "12345",
        "#67978",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 68017",
        "186900",
        "12345",
        "#68017",
        "09/mar/2024 17:53",
        "Pendiente"
    ],
    [
        "HIT 67977",
        "47000",
        "12345",
        "#67977",
        "09/mar/2024 17:52",
        "Pendiente"
    ],
    [
        "HIT 67990",
        "27000",
        "12345",
        "#67990",
        "09/mar/2024 17:56",
        "Pendiente"
    ],
    [
        "HIT 67983",
        "32000",
        "12345",
        "#67983",
        "09/mar/2024 17:56",
        "Pendiente"
    ]
];

// eslint-disable-next-line 
const _test_data = [
    {
        "id": 21640,
        "guia": "HIT 67983",
        "info": {
            "fecha": "09-03-2024",
            "valor": "32000",
            "value": "#67983 ) 09-03-2024 ) 1 )MAGALI Lorena romero joaqui ) 32000 ) Bogota ) ¡573024416545 )  ) 09-03-24, 6pm-9pm, a, c, cobrar 7000mil de envio, live, ya esta pago por daviplata",
            "cedula": "",
            "ciudad": "Bogota",
            "numero": "#67983",
            "cliente": "MAGALI Lorena romero joaqui",
            "cantidad": "1",
            "contacto": "¡573024416545",
            "etiquetas": "09-03-24, 6pm-9pm, a, c, cobrar 7000mil de envio, live, ya esta pago por daviplata",
            "productos": "No aplica",
            "signature": "1710021488",
            "fecha_creado": "2024-03-09 16:58:08.000000"
        },
        "estado": 1,
        "carrier": {
            "id": "10",
            "name": "BTS",
            "fecha_entregado": "2024-03-09 17:56:38.000000"
        },
        "concilio": {
            "guia": "HIT 67983",
            "valor": "32000",
            "codigo": "12345"
        }
    },
    {
        "id": 21641,
        "guia": "HIT 67990",
        "info": {
            "fecha": "09-03-2024",
            "valor": "27000",
            "value": "#67990 ) 09-03-2024 ) 1 )Leydi Marcela Gmez Surez ) 27000 ) Bogota ) ¡573209086571 )  ) 09-03-24, 6pm-9pm, a, c, cobrar 7000mil de envio, live, ya esta pago por daviplata",
            "cedula": "",
            "ciudad": "Bogota",
            "numero": "#67990",
            "cliente": "Leydi Marcela Gmez Surez",
            "cantidad": "1",
            "contacto": "¡573209086571",
            "etiquetas": "09-03-24, 6pm-9pm, a, c, cobrar 7000mil de envio, live, ya esta pago por daviplata",
            "productos": "No aplica",
            "signature": "1710021535",
            "fecha_creado": "2024-03-09 16:58:55.000000"
        },
        "estado": 1,
        "carrier": {
            "id": "10",
            "name": "BTS",
            "fecha_entregado": "2024-03-09 17:56:40.000000"
        },
        "concilio": {
            "guia": "HIT 67990",
            "valor": "27000",
            "codigo": "12345"
        }
    }
];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ConciliarGuias = (router) => {
    const { navigate, location, params } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [currentStatus, setCurrentStatus] = React.useState("Consultando guías...");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [currentQuery, setCurrentQuery] = React.useState("todos");
    const [valorServicio, setValorServicio] = React.useState(0);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [valorRecaudo, setValorRecaudo] = React.useState(0);
    const [excelFile, setExcelFile] = React.useState(null);
    const [modalView, setModalView] = React.useState(null);
    const [valorTotal, setValorTotal] = React.useState(0);
    const [valorOtros, setValorOtros] = React.useState(0);
    const [dataExcel, setDataExcel] = React.useState([]);
    const [fecha, setFecha] = React.useState(new Date());
    const [dataList, setDataList] = React.useState([]);
    const [otros, setOtros] = React.useState([]);


    const Row = ({ data, interline }) => {
        const _valorEnvio = [1, 2, 4].includes(parseInt(data.estado)) ? data.carrier?.conciliacion?.envio : data.concilio?.envio == "" ? 0 : data.concilio?.envio ?? 0;
        const [valorShopify, setValorShopify] = React.useState(data.info?.valor ?? 0);
        const [valorExcel, setValorExcel] = React.useState(data.concilio?.valor ?? 0);
        const [valorEnvio, setValorEnvio] = React.useState(_valorEnvio);

        // log.d("data in row", data);

        const handleSave = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    if (!item.carrier.conciliacion) {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '1'
                                }
                            },
                            isTemp: true
                        };
                    } else {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    ...item.carrier.conciliacion,
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '1'
                                }
                            },
                            isTemp: true
                        };
                    }
                }
                return item;
            });

            setDataList(_newData);
        }

        const handleSaveChange = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    if (!item.carrier.conciliacion) {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '6'
                                }
                            },
                            isTemp: true
                        };
                    } else {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    ...item.carrier.conciliacion,
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '6'
                                }
                            },
                            isTemp: true
                        };
                    }
                }
                return item;
            });

            setDataList(_newData);
        }

        const handlePayedPending = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    if (!item.carrier.conciliacion) {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '5'
                                }
                            },
                            isTemp: true
                        };
                    } else {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    ...item.carrier.conciliacion,
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '5'
                                }
                            },
                            isTemp: true
                        };
                    }
                }
                return item;
            });

            setDataList(_newData);
        }

        const handlePayed = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    if (!item.carrier.conciliacion) {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '4'
                                }
                            },
                            isTemp: true
                        };
                    } else {
                        return {
                            ...item,
                            info: {
                                ...item.info,
                                valor: funciones.current.formatPrice(valorShopify).int
                            },
                            concilio: {
                                ...item.concilio,
                                valor: funciones.current.formatPrice(valorExcel).int,
                                envio: funciones.current.formatPrice(valorEnvio).int,
                            },
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    ...item.carrier.conciliacion,
                                    envio: funciones.current.formatPrice(valorEnvio).int,
                                    estado: '4'
                                }
                            },
                            isTemp: true
                        };
                    }
                }
                return item;
            });

            setDataList(_newData);
        }

        const handleCancel = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    if (!item.carrier.conciliacion) {
                        return {
                            ...item,
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    estado: '2'
                                }
                            },
                            isTemp: true
                        };
                    } else {
                        return {
                            ...item,
                            carrier: {
                                ...item.carrier,
                                conciliacion: {
                                    ...item.carrier.conciliacion,
                                    estado: '2'
                                }
                            },
                            isTemp: true
                        };
                    }
                }
                return item;
            });

            setDataList(_newData);
        }

        return (
            <Menu data={data} interline={interline}>

                {media_movil ?
                    <td className="td_with_forms">
                        <pre><span>Guía:</span> {data.guia} <span>Pedido:</span> {data.info.numero} <i>{data.estado_name}</i></pre>

                        <pre>
                            <span className="width_fixed">Valor Shopify</span>
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorShopify).format} onChange={e => setValorShopify(e.target.value)} />
                                :
                                <>{valorShopify}</>
                            }
                        </pre>
                        <pre>
                            <span className="width_fixed">Valor Excel</span>
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorExcel).format} onChange={e => setValorExcel(e.target.value)} />
                                :
                                <>{valorExcel}</>
                            }
                        </pre>
                        <pre>
                            <span className="width_fixed">Envío</span>
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorEnvio).format} onChange={e => setValorEnvio(e.target.value)} />
                                :
                                <>{valorEnvio}</>
                            }
                        </pre>
                        <pre className="row_center">
                            {data.isTemp ?
                                <Iconos.CheckBold />
                                :
                                <>
                                    {(data.estado == 0 || data.estado == 2) ?
                                        <>
                                            <button type="button" className="btn btn_primary green_aux" onClick={handleSaveChange}>Garantía contraenterrega</button>
                                            <button type="button" className="btn btn_primary blue_aux" onClick={handlePayedPending}>Garantía Pagada</button>
                                            <br />
                                            <button type="button" className="btn btn_primary green" onClick={handleSave}>Contraentrega</button>
                                            <button type="button" className="btn btn_primary blue" onClick={handlePayed}>Pagada</button>
                                            <button type="button" className="btn btn_primary orange" onClick={handleCancel}>Pendiente</button>
                                        </>
                                        :
                                        <>
                                            {[1, 4, 5, 6, 7].includes(parseInt(data.estado)) && <span className="msg">Cerrado en el concilio #{data.carrier?.conciliacion?.id || "..."}</span>}
                                        </>
                                    }
                                </>
                            }
                        </pre>
                    </td>
                    :
                    <>
                        <td className="td_with_forms">{data.estado_name}</td>
                        <td className="td_with_forms">{data.guia}</td>
                        <td className="td_with_forms">{data.info.numero}</td>
                        <td className="td_with_forms">{data.concilio?.codigo}</td>
                        <td className="td_with_forms">
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorShopify).format} onChange={e => setValorShopify(e.target.value)} />
                                :
                                <>{valorShopify}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorExcel).format} onChange={e => setValorExcel(e.target.value)} />
                                :
                                <>{valorExcel}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {(!data.isTemp && (data.estado == 0 || data.estado == 2)) ?
                                <input type="text" value={funciones.current.formatPrice(valorEnvio).format} onChange={e => setValorEnvio(e.target.value)} />
                                :
                                <>{valorEnvio}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {data.isTemp ?
                                <Iconos.CheckBold />
                                :
                                <>
                                    {(data.estado == 0 || data.estado == 2) ?
                                        <>
                                            <button type="button" className="btn btn_primary green_aux" onClick={handleSaveChange}>Garantía contraenterrega</button>
                                            <button type="button" className="btn btn_primary blue_aux" onClick={handlePayedPending}>Garantía Pagada</button>
                                            <br />
                                            <button type="button" className="btn btn_primary green" onClick={handleSave}>Contraentrega</button>
                                            <button type="button" className="btn btn_primary blue" onClick={handlePayed}>Pagada</button>
                                            <button type="button" className="btn btn_primary orange" onClick={handleCancel}>Pendiente</button>
                                        </>
                                        :
                                        <>
                                            {[1, 4, 5, 6, 7].includes(parseInt(data.estado)) && <span className="msg">Cerrado en el concilio #{data.carrier?.conciliacion?.id || "..."}</span>}
                                        </>
                                    }
                                </>
                            }
                        </td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;

        dataList.forEach((item, i) => {
            let _estado = item.carrier.conciliacion?.estado === undefined ? 0 : item.carrier.conciliacion?.estado;
            let _estado_name;
            switch (_estado) {
                case '7':
                    _estado_name = "Conciliado recibido"; // Recibido
                    break;
                case '6':
                    _estado_name = "Garantía contraenterrega"; // Cambio efectivo
                    break;
                case '5':
                    _estado_name = "Garantía Pagada"; // Garantía Pagada
                    break;
                case '4':
                    _estado_name = "Conciliado pagado"; // Pagado
                    break;
                case '3':
                    _estado_name = "Devuelto"; // Rechazado
                    break;
                case '2':
                    _estado_name = "Pendiente"; // Pendiente
                    break;
                case '1':
                    _estado_name = "Conciliado"; // Aceptado
                    break;
                default:
                    _estado_name = "Sin conciliar";
                    break;
            }
            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ...item,
                    estado: _estado,
                    estado_name: _estado_name
                }} />);
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">{currentStatus}</h4>;
        } else {
            _footer = <>
                {
                    showLoading ?
                        <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                        :
                        <h4 className="msg_lazy_load">{currentStatus}</h4>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Conciliar guías con {params.name.replace(/_/g, ' ')}</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Estado</th>
                            <th>Guía</th>
                            <th>Pedido</th>
                            <th>Código</th>
                            <th>Valor Shopify</th>
                            <th>Valor Excel</th>
                            <th>Envío</th>
                            <th></th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
            <table className="table apendice">
                <tbody>
                    {otros.length > 0 ?
                        <tr className="table_title">
                            <th>Otros ingresos y gastos</th>
                            <th></th>
                            <th></th>
                        </tr>
                        :
                        <tr className="table_title radius_bottom">
                            <th>Otros ingresos y gastos</th>
                        </tr>
                    }

                    {otros.map((v, i) => {
                        const clases = (i % 2) == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line";
                        return (<tr key={i} className={clases}>
                            <td>{v.nota}</td>
                            <td>{funciones.current.formatPrice(v.monto).format}</td>
                            <td>{v.tipo}</td>
                            <td className="td_menu"></td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </>
    }

    const onChange = (file) => {
        const ext = file?.name?.split(".").pop();

        if (ext === "xls" || ext === "xlsx") {
            setExcelFile(file);
        } else if (ext === undefined) {
            setExcelFile(null);
        } else {
            Alert(`¡El tipo "${ext}" no es permitido! \n\nSolo se admiten archivos de Excel \n"xls" o "xlsx"`, "warning");
        }
    }

    const handleUpload = async (file) => {
        if (!file) {
            Alert("¡No has agregado un archivo!", "warning");
            return;
        }
        setShowLoading(true);

        const reader = new FileReader();

        setTimeout(() => {
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });

                log.d("A " + rows[0][0].trim(), rows[0][0].trim() === "Guía");
                log.d("B " + rows[0][1].trim(), rows[0][1].trim() === "Valor");
                log.d("C " + rows[0][2].trim(), rows[0][2].trim() === "Código");
                log.d("D " + rows[0][3].trim(), rows[0][3].trim() === "Envío");

                if (rows[0][0].trim() === "Guía" && rows[0][1].trim() === "Valor" && rows[0][2].trim() === "Código" && rows[0][3].trim() === "Envío") {
                    setDataExcel(rows);
                    getList("todos", rows);
                } else {
                    Alert("El formato del excel parece incorrecto, el orden de las columnas debe ser: \nA = Guía      \nB = Valor     \nC = Código \nD = Envío   ", "warning");
                    setShowLoading(false);
                }
            };
            reader.readAsArrayBuffer(file);
        }, 1200);
    }

    const getList = async (_c_query = "todos", rows) => {
        let _rows = [];
        rows.forEach((guia, i) => {
            if (i > 0) {
                _rows.push({
                    guia: guia[0],
                    valor: guia[1],
                    codigo: guia[2],
                    envio: guia[3]
                });
            }
        });
        setCurrentStatus("Consultando guías...");
        setCurrentQuery(_c_query);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-compare-guides",
            data: {
                task: _c_query,
                info: JSON.stringify({
                    carrying: params.carrying,
                    guias: _rows // Se devuelve en la respuesta como data.concilio 
                })
            }
        });

        if (_data.response == 1) {
            // log.d("_data.data", _data.data);
            setDataList(_data.data);
            setCurrentStatus(() => {
                let to_return;
                if (_data.data.length < 1) {
                    to_return = `No hay guías${currentQuery === "todos" ? "" : " " + currentQuery}`;
                } else {
                    to_return = "¡No hay más guías!";
                }
                return to_return;
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
            setCurrentStatus("Ocurrio un error: " + _data.msg);
        }

        setShowLoading(false);
    }

    const saveConciliation = async (e) => {
        e.preventDefault();
        let _guias = [];
        let _date = new Date();
        let _todas = dataList.length;

        dataList.forEach((guia) => {
            let _estado = parseInt(guia.carrier?.conciliacion?.estado ?? "2");

            if (guia.isTemp) {
                _guias.push(guia);
            } else {
                if ([1, 3, 4, 5, 6, 7].includes(_estado)) {
                    // log.d(guia.guia, _estado);
                    _todas--;
                }
            }
        });

        if (_guias.length < 1) {
            Alert("Es necesario que valides \n(Guardar o Rechazar) \npara poder continuar", "warning");
            return;
        }

        if (_guias.length !== _todas) {
            Alert("Es necesario validar cada una de las " + _todas + " guías para poder continuar", "warning");
            return;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-conciliation",
            data: {
                task: "conciliation_create",
                info: JSON.stringify({
                    fecha: fecha.getFullYear() + "-" + format((fecha.getMonth() + 1)) + "-" + format(fecha.getDate()) + " " + format(_date.getHours()) + ":" + format(_date.getMinutes()) + ":00",
                    carrying: params.carrying,
                    recaudo: valorRecaudo,
                    servicios: valorServicio,
                    otros: otros,
                    total: valorTotal,
                    guias: _guias
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate(`/conciliaciones/${params.carrying}/${params.name}`));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleClean = () => {
        Confirm("¿Seguro de abandonar la conciliación?", async (response) => {
            if (response) {
                setValorRecaudo(0);
                setValorServicio(0);
                setExcelFile(null);
                setDataExcel([]);
                setDataList([]);
                setOtros([]);
            }
        });
    }

    const handleRefresh = (v) => {
        setModalView(null); // Cierra el modal tras la respuesta

        setOtros([...otros, v]);
    }

    const abrirEntrada = (e) => {
        e.preventDefault();

        setModalView(<Entrada
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleRefresh}
            close={() => setModalView(null)} />);
    }

    const abrirSalida = (e) => {
        e.preventDefault();

        setModalView(<Salida
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleRefresh}
            close={() => setModalView(null)} />);
    }

    React.useEffect(() => {
        let _total = (valorRecaudo - valorServicio);
        let _otros = otros.reduce((acumulador, elemento) => {
            if (elemento.tipo === "Ingreso") {
                return acumulador + parseInt(elemento.monto);
            } else {
                return acumulador - parseInt(elemento.monto);
            }
        }, 0);
        setValorOtros(_otros);
        setValorTotal(_total + _otros);
    }, [otros, valorRecaudo, valorServicio]);

    React.useEffect(() => {
        let _valor_recaudo = 0;
        let _valor_servicios = 0;

        dataList.forEach(i => {
            if (i.isTemp && [1, 6].includes(parseInt(i.carrier?.conciliacion?.estado ?? "0"))) {
                _valor_recaudo += parseInt(i.info?.valor);
                _valor_servicios += parseInt(i.carrier?.conciliacion?.envio || "0");
            }
            if (i.isTemp && [4, 5].includes(parseInt(i.carrier?.conciliacion?.estado ?? "0"))) {
                _valor_servicios += parseInt(i.carrier?.conciliacion?.envio || "0");
            }
        });

        setValorRecaudo(_valor_recaudo);
        setValorServicio(_valor_servicios);
    }, [dataList]);

    React.useEffect(() => {
        setTitle("Conciliar guías con " + params.name.replace(/_/g, ' '));

        funciones.current.validaSesion("concilios_crear").then(response => {
            if (response) {
                setPermission(true);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, [params.carrying, location.pathname]);

    return (<RequirePermissions permission={permission} loading={showLoading}>

        <div className="head_bar">
            <h2 className={media_movil ? "" : "_center"}>Conciliar guías con {params.name.replace(/_/g, ' ')}</h2>
        </div>

        {dataExcel.length < 1 ?
            <div className="content_column">
                <p>Para continuar primero debes subir el archivo de conciliaciones en formato Excel entregado por la transportadora.</p>
                <DropZone onChange={onChange} />
                <button className="btn btn_primary" onClick={() => handleUpload(excelFile)}>Cargar archivo</button>
            </div>
            :
            <>
                <AllRow />
                <div className="content_btns in_movil">
                    <h3 className="btn btn_add_to_list _left" onClick={abrirEntrada}>Agregar Ingreso <Iconos.CashPlus /> </h3>
                    <h3 className="btn btn_add_to_list _right" onClick={abrirSalida}>Agregar Gasto <Iconos.CashMinus /> </h3>
                </div>

                <div className="content_sticky_bottom">
                    <DatePickerShort
                        placeholder="Fecha"
                        value={fecha}
                        onChange={(date) => setFecha(date)} />
                    <h2><span>Valor a recaudar</span> <span>{funciones.current.formatPrice(valorRecaudo).format}</span></h2>
                    <h2><span>Servicios</span> <span>{valorServicio > 0 ? "-" : ""}{funciones.current.formatPrice(valorServicio).format}</span></h2>
                    <h2><span>Otros</span> <span>{funciones.current.formatPrice(valorOtros).format}</span></h2>
                    <h2><span>Total</span> <span>{funciones.current.formatPrice(valorTotal).format}</span></h2>

                    <button className="btn btn_primary" onClick={saveConciliation}>Guardar conciliación</button>
                </div>
            </>
        }
        <div className="content_btns in_movil">
            <NavLink
                to={`/conciliaciones/${params.carrying}/${params.name}`}
                className="btn btn_add_to_list _left">
                Ir a conciliaciones <Iconos.TruckCheck />
            </NavLink>

            {dataExcel.length > 0 ?
                <button className="btn btn_add_to_list" onClick={handleClean}>Corregir archivo excel <Iconos.TruckExcelUpload /></button>
                :
                <button className="btn btn_add_to_list" onClick={() => navigate("/conciliaciones", { state: { rol: 6 } })}>Cambiar transportadora <Iconos.Truck /></button>
            }

            <NavLink
                to={`/pedidos/${params.carrying}/${params.name}`}
                className="btn btn_add_to_list _right">
                Ir a los pedidos <Iconos.TruckOrders />
            </NavLink>
        </div>
        <Loading state={showLoading} />
        {modalView}
    </RequirePermissions>)
}

export default withRouter(ConciliarGuias);