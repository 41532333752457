import * as React from "react";
import { Alert, alertArea, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import { useNavigate } from "react-router-dom";
import { TextArea } from "shared/Components";
import MovePopUp from "shared/MovePopUp";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Options = ({ data, parent, reload, close }) => {
    const navigate = useNavigate();
    const { media_movil, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(false);
    const [infoCustomer, setInfoCustomer] = React.useState({});
    const [loadInfo, setLoadInfo] = React.useState(true);
    const [products, setProducts] = React.useState([]);
    const [pedido, setPedido] = React.useState({});
    const [fecha, setFecha] = React.useState("");
    const [_tag, setTag] = React.useState(null);
    const [nota, setNota] = React.useState("");
    const [tags, setTags] = React.useState([]);

    const notaRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveValidate = async (_msg, _estado) => {

        let _data = await api.fetchJson({
            url: "update-cash-on-delivery",
            data: {
                task: "validate",
                info: {
                    id: data.id,
                    estado: _estado,
                    nota,
                }
            }
        });

        if (_data.response == 1) {
            Alert(_msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const updateOrder = async (_estado) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "shopify-validate-cash-on-delivery",
            data: {
                pedido: pedido.order_number,
                order_id: data.pedido_id,
                nota: nota.toLowerCase(),
                estado: _estado
            }
        });

        if (_data.response == 1) {
            saveValidate(_data.msg, _estado);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }
    }

    const validate = (e, _estado) => {
        e.preventDefault();
        alertaRemove(notaRef.current);

        if (data.pedido.length < 1) {
            Alert("¡El pedido no existe!", "warning");
            return false;
        }

        if (_estado === 2 && nota.length < 1) {
            alertArea(notaRef.current, "¡Debes indicar el motivo del rechazo!");
            notaRef.current.focus();
            return false;
        }

        updateOrder(_estado);
    }

    const getCustomer = async (_id) => {
        setLoadInfo(true);

        let _data = await api.fetchJson({
            url: "shopify-get-customer",
            data: {
                id: _id,
                tipo: "id"
            }
        });

        if (_data.response == 1) {
            setInfoCustomer(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadInfo(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";

        let fecha_creado = new Date(data.fecha_creado);
        setFecha(format(fecha_creado.getDate()) + "/" + meses_hsort[fecha_creado.getMonth()] + "/" + fecha_creado.getFullYear() + " " + format(fecha_creado.getHours()) + ":" + format(fecha_creado.getMinutes()));

        if (data.pedido && data.pedido.name) {
            let _tags_list = [];
            data.pedido.total_articulos = data.pedido.line_items.map((article) => article.quantity).reduce((a, b) => a + b, 0);
            setPedido(data.pedido);
            setProducts(data.pedido.line_items);
            data.pedido.tags
                .replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",")
                .split(",").forEach(item => {
                    if (item.trim().length > 0) {
                        _tags_list.push(item.trim());
                    }
                });

            setTags(_tags_list);
            setTag(data.pedido.tags.includes("CONTRAENTREGA") ? "CONTRAENTREGA" : null);
            if (!infoCustomer.id) {
                getCustomer(data.pedido.customer.id);
            }
        }
    }, [data]);

    return (
        <MovePopUp
            parent={{ ...parent, top: pedido.name ? 0 : parent.top, left: media_movil ? "5%" : "calc(50% - 275px)", width: media_movil ? "90%" : 550 }}
            close={handleClose}
            title="Validar comprobante">

            <div className="content_primary _on_popup _pedido_detalle" style={{ paddingTop: "0.3rem", marginTop: "-1rem", width: "calc(100% + 1.8rem)" }}>

                {pedido.name ?
                    <>
                        <div className="in_column" style={{ width: media_movil ? "100%" : 320, maxWidth: media_movil ? "100%" : 320 }}>
                            <h4 className="field_text">{data.asesor.nombre} <i>{fecha}</i></h4>

                            <div className="info_card">
                                <h5 style={{ marginBottom: "0.6rem" }}>Artículos <sup>{pedido.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</sup></h5>
                                {products.map((item, i) => {
                                    let _precio = parseFloat(item.price);
                                    let _valor = funciones.current.formatPrice(_precio).format;
                                    let _total = funciones.current.formatPrice(_precio * item.quantity).format;
                                    return <p key={i} className="info_product"><b>{_valor} x {item.quantity} = {_total}</b> - <span>{item.name}</span></p>;
                                })}
                            </div>

                            <div className="info_card">
                                <h5 style={{ marginBottom: "1rem" }}>Pago {_tag && <b className="tag">{_tag}</b>} <sup>{pedido.financial_status.toLowerCase() === "pending" ? "Pendiente" : pedido.financial_status.toLowerCase() === "paid" ? "Pagado" : pedido.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : pedido.financial_status}</sup></h5>
                                <p className="info_price"><span className="left">Subtotal </span> <span className="center">{pedido.total_articulos}</span>  <span className="right">${funciones.current.formatPrice(pedido.subtotal_price.toString().replace(".", ",")).format}</span></p>
                                <p className="info_price"><span className="left">Envio </span> <span className="right">${funciones.current.formatPrice(pedido.total_shipping_price.toString().replace(".", ",")).format}</span></p>
                                <p className="info_price"><span className="left">Descuento</span> <span className="right">${funciones.current.formatPrice(pedido.total_discounts.toString().replace(".", ",")).format}</span></p>
                                <p className="info_price _total"><span className="left">Total</span> <span className="right">${funciones.current.formatPrice(pedido.total_price.toString().replace(".", ",")).format}</span></p>
                            </div>

                            <div className="info_card">
                                <h5>Etiquetas</h5>
                                <p className="cont_tags">
                                    {tags.map((tag, index) => {
                                        return <span key={index} className="tag">{tag}</span>
                                    })}
                                </p>
                            </div>

                            <TextArea
                                ref={notaRef}
                                title="Observación"
                                value={nota}
                                onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
                        </div>

                        <div className="in_column" style={{ width: media_movil ? "100%" : 200, maxWidth: media_movil ? "100%" : 200 }}>
                            <h4 className="field_text">Pedido: <span>{pedido.order_number}</span></h4>

                            <div className="info_card">
                                <h5>Notas</h5>
                                <p className="info_nota">{pedido.note}</p>
                                <h5>Detalles adicionales</h5>
                                <p className="info_attributes">
                                    {pedido.note_attributes.map((nota, index) => <React.Fragment key={index}>
                                        <b>{nota.name}</b>
                                        <span>{nota.value}</span>
                                    </React.Fragment>)}
                                </p>
                            </div>

                            <div className="info_card">
                                <h5>Cliente {loadInfo && <Iconos.Loading className="icon_loading" />}</h5>
                                {infoCustomer?.first_name ?
                                    <p className="info_name">{infoCustomer?.first_name} {infoCustomer?.last_name} </p>
                                    :
                                    <p className="info_name">{pedido.customer.first_name} {pedido.customer.last_name} </p>
                                }
                                {infoCustomer?.default_address?.company && <p className="info_document"><b>Documento:</b> {infoCustomer?.default_address?.company}</p>}
                                {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                                {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}

                                <h5>Dirección</h5>
                                {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                                {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                                {infoCustomer?.default_address?.address2 && <i className="info_phone"><b>Teléfono 2:</b> {infoCustomer?.default_address?.address2}</i>}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <h4 className="field_text">Pedido: <span>{pedido.order_number}</span></h4>

                        <TextArea
                            ref={notaRef}
                            title="Observación"
                            value={nota}
                            onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
                    </>
                }
            </div>

            <div className="content_btns _without_margin">
                <button type="button" className="btn btn_primary" onClick={e => validate(e, 1)}>Aceptar</button>
                <button type="button" className="btn btn_primary" onClick={e => validate(e, 2)}>Rechazar</button>
            </div>

            <Loading state={showLoading} />
        </MovePopUp>)
}

export default Options;