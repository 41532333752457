import * as React from "react";
import { BarCode, QrCode } from "shared/BarCode";
import { Funciones } from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Strip = ({ navigate, bill }) => {
    const { data_user, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));

    return (
        <>

            <div className="in_column">
                <div className="info_logo_hit"></div>
                <p className="info_greeting">
                    <span>{data_user.local.direccion ?? "No tiene dirección"}</span>
                    <br />
                    Teléfono: <span>{data_user.local.telefono ?? "No tiene"}</span>
                    <br />
                    Fecha: <span>{bill.order_created}</span>
                </p>
                <p className="info_customer">
                    <span style={{ display: "inline-block", margin: "0.2rem 0.6rem 0" }}>Cliente</span>
                    <br />
                    Nombre: <span>{bill.receiver_address.first_name} {bill.receiver_address.last_name}</span>
                    <br />
                    Documento: <span>{bill.receiver_address.company}</span>
                    <br />
                    Teléfono: <span>{bill.receiver_address.phone}</span>
                </p>
                <QrCode content={`${bill.name} ( ${bill.order_created_num} ( ${bill.total_articulos} ( ${bill.receiver_address.first_name} ${bill.receiver_address.last_name} ( ${bill.total_price} ( ${bill.receiver_address.city} ( ${bill.receiver_address.phone} ( ${bill.receiver_address.company} ( ${bill.tags}`} />
                <BarCode code={"Hit " + bill.order_number} />
            </div>

            {bill.notas && <h4 className="info_notas">{bill.notas}</h4>}

            <div className="info_articles">
                <table>
                    <thead>
                        <tr>
                            <th>Productos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bill.line_items && bill.line_items.map((item, i) =>
                            <tr key={i}>
                                <td>
                                    {funciones.current.formatPrice(item.price.toString().replace(".", ",")).format} x {item.quantity} - {item.name}
                                    <span className="_right">${funciones.current.formatPrice(item.price * item.quantity).format}</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="info_footer">
                <h4 className="info_price"><span className="left">Valor parcial</span> <span className="right">${funciones.current.formatPrice(bill.subtotal_price).format} {bill.currency}</span></h4>
                <h4 className="info_price"><span className="left">Descuento</span> <span className="right">${funciones.current.formatPrice(bill.total_discounts).format} {bill.currency}</span></h4>
                <h4 className="info_price _total"><span className="left">Total</span> <span className="right">${funciones.current.formatPrice(bill.total_price).format} {bill.currency}</span></h4>

                <h6>Si tienes algun inconveniente comunicarse al WhatsApp: 312 873 4751 Este Producto tiene Garantía Recuerda NO ARRANCAR LOS SELLOS QUE TIENE EL PRODUCTO PARA NO PERDER LA GARANTIA </h6>
            </div>
        </>
    )
}

export default Strip;