import * as React from 'react';
import PropTypes from 'prop-types';

const ItemSubMenu = ({ children, onClick = () => { }, className }) => (

    <span
        className={className ?? "submenu_item"}
        onClick={e => {
            e.stopPropagation();
            onClick(e);
        }}>
        {children}
    </span>
);

ItemSubMenu.propTypes = {
    onClick: PropTypes.func,
};

export default ItemSubMenu;
