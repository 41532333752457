import * as React from "react";
import { DatePicker, InputList, InputWithIcon, TextArea } from "shared/Components";
import { Alert, alertArea, alerta, alertaRemove } from "shared/Alert";
import { useNavigate } from "react-router-dom";
import { Funciones } from "services/funciones";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Salida = ({ parent, reload, close }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [codigos, setCodigos] = React.useState([]);
    const [fecha, setFecha] = React.useState(new Date());
    const [bankInfo, setBankInfo] = React.useState({});
    const [cuentas, setCuentas] = React.useState([]);
    const [codigo, setCodigo] = React.useState("");
    const [banco, setBanco] = React.useState("");
    const [monto, setMonto] = React.useState("");
    const [nota, setNota] = React.useState("");

    const codigoRef = React.useRef(null);
    const montoRef = React.useRef(null);
    const fechaRef = React.useRef(null);
    const bancoRef = React.useRef(null);
    const notaRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const handleBank = (_value) => {
        alertaRemove(bancoRef.current);
        setBanco(_value);

        if (_value === "") {
            setBanco("");
            setBankInfo({});
        } else {
            let item = cuentas.find(count => {
                let _cuentas = format(count.id) + " - " + count.nombre;
                _cuentas = _cuentas.trim();

                if (_cuentas === _value) {
                    return true;
                } else {
                    return false;
                }
            });

            if (typeof item === "object") {
                setBankInfo({ bank: item.id, bank_name: item.nombre });
            } else {
                // setBanco("");
                setBankInfo({});
                alerta(bancoRef.current, "¡La cuenta bancaria es invalida!");
            }
        }
    }

    const saveInTill = async (_codigo) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-cash-movement",
            data: {
                task: "salida",
                info: JSON.stringify({
                    tipo: 0, // salida
                    tipo_name: "Salida de caja",
                    bank: format(bankInfo.bank),
                    bank_name: bankInfo.bank_name,
                    monto: funciones.current.formatPrice(monto).int,
                    codigo: _codigo,
                    nota,
                    fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
                })
            }
        });

        if (_data.response == 1) {
            // Alert(_data.msg, "ok", () => {
            document.body.style.overflow = "auto";
            reload();
            close(false);
            // });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(montoRef.current);
        alertaRemove(bancoRef.current);
        alertaRemove(codigoRef.current);
        alertaRemove(notaRef.current);
        let _monto = funciones.current.formatPrice(monto).int;

        if (_monto.length < 1) {
            alerta(montoRef.current, "¡Debes ingresar el monto!");
            montoRef.current.focus();
            return false;
        }

        if (isNaN(_monto.split(',')[0])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (_monto.split(',')[1] && isNaN(_monto.split(',')[1])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (parseFloat(_monto.toString().replace(',', '.')) < 0) {
            alerta(montoRef.current, "¡Debes ingresar un monto valido!");
            montoRef.current.focus();
            return false;
        }

        // if (banco.length < 1) {
        //     alerta(bancoRef.current, "¡Debes seleccionar una cuenta bancaria!");
        //     bancoRef.current.focus();
        //     return false;
        // }

        let _codigo = codigos.filter(item => (format(item.id) + " - " + item.nombre).trim() === codigo);

        if (codigo.length > 0) {
            if (_codigo[0] === undefined) {
                alertArea(codigoRef.current, "¡Este código no es valido!");
                // codigoRef.current.focus();
                return false;
            }
            _codigo = _codigo[0].id;
        } else {
            _codigo = 0;
        }

        if (nota.length < 1) {
            alertArea(notaRef.current, "¡Debes ingresar un concepto!");
            notaRef.current.focus();
            return false;
        }

        saveInTill(_codigo);
    }

    const getConcepts = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-concepts",
            data: {
                task: "salidas"
            }
        });

        if (_data.response == 1) {
            setCodigos(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getConcepts();
        getBanksList();
        if (montoRef.current) {
            montoRef.current.focus();
        }
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Salida de caja">

            <div className="content_primary _on_popup">

                <InputWithIcon
                    ref={montoRef}
                    type="text"
                    title="Monto"
                    Icono={Iconos.Currency}
                    value={funciones.current.formatPrice(monto).format}
                    onChange={v => { alertaRemove(montoRef.current); setMonto(v) }} />

                <DatePicker
                    ref={fechaRef}
                    title="Fecha"
                    value={fecha}
                    onChange={(date) => { alertaRemove(fechaRef.current); setFecha(date); }} />

                <InputList
                    ref={bancoRef}
                    title="Cuenta Bancaria"
                    Icono={Iconos.Bank}
                    value={banco}
                    onChange={handleBank}
                    readOnly={cuentas.length < 1}
                    list={cuentas.map(item => {
                        let _cuentas = format(item.id) + " - " + item.nombre;
                        return _cuentas.trim();
                    })} />

                <InputList
                    ref={codigoRef}
                    title="Código"
                    value={codigo}
                    onChange={v => { alertaRemove(codigoRef.current); setCodigo(v) }}
                    list={codigos.map(item => {
                        let _nombres = format(item.id) + " - " + item.nombre;
                        return _nombres.trim();
                    })} />

                <TextArea
                    ref={notaRef}
                    title="Concepto"
                    value={nota}
                    onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
            </div>


            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={e => validate(e)}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default Salida;