import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputListOnly, InputSearch } from "shared/Components";
import { Alert, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import ciudades from "shared/ciudadesEnvia";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import logo from "images/envia.png";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react/jsx-no-target-blank: 0 */
/* eslint no-extend-native: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

String.prototype.acentos = function () {
    var translate_re = /[áéíóúÁÉÍÓÚ]/g;
    var translate = {
        "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
        "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
    };
    return this.replace(translate_re, function (match) { return translate[match]; });
};

const GuiasEnvia = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [toPrintList, setToPrintList] = React.useState([]);
    const [permission, setPermission] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [seleccionados, setSeleccionados] = React.useState([]);
    const [currentQuery, setCurrentQuery] = React.useState("todos");
    const [navBtns, setNavBtns] = React.useState({ prev: "", next: "" });
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [prevLastId, setPrevLastId] = React.useState({ direction: "next", since: 0 });
    const [rows, setRows] = React.useState(<h4 className="msg_lazy_load _alone _left">Verificando pedidos...</h4>);


    const Row = ({ info, interline }) => {
        let _default_address = info.customer ? info.customer.default_address : "-1";
        let _status = info.financial_status === "pending" ? "Pendiente" : info.financial_status === "paid" ? "Pagado" : info.financial_status === "partially_paid" ? "Pago parcial" : info.financial_status;
        let _city = _default_address.city ? _default_address.city.toString().toLowerCase().acentos() : "-1";
        let _province = _default_address.province ? _default_address.province.toString().toLowerCase().acentos() : "-1";
        let _obj_provinces = ciudades.filter(item => item.departamento.toString().toLowerCase().acentos().includes(_province));
        let _obj_city = _obj_provinces.filter(item => item.ciudad.toString().toLowerCase().acentos().includes(_city));

        const [flete, setFlete] = React.useState(<button type="button"><Iconos.Loading /> </button>);
        // const [flete, setFlete] = React.useState(<strong>debuging</strong>);

        const cityRef = React.useRef();
        const provinceRef = React.useRef();
        const [data, setData] = React.useState({
            ...info,
            fulfill_id: info.fulfillments[0]?.id,
            tracking_number: info.fulfillments[0]?.tracking_number,
            tracking_url: info.fulfillments[0]?.tracking_url,
            ciudad: _obj_city[0],
        });
        const [toPrint, setToPrint] = React.useState(false);
        const [isChecked, setIsChecked] = React.useState(false);
        const [formapago, setFormapago] = React.useState(false);
        const [departamentos, setDepartamentos] = React.useState([]);
        const [showAply, setShowAply] = React.useState(false);


        const [currentCity, setCurrentCity] = React.useState(_default_address.city || "");
        const [ciudadesPorDepartamentos, setCiudadesPorDepartamentos] = React.useState([]);
        const [currentProvince, setCurrentProvince] = React.useState(_default_address.province || "");

        const handleSelect = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (data.tracking_number === undefined && data.ciudad?.codigo) {
                setIsChecked(b => !b);
                if (isChecked) {
                    setFormapago(false);
                }

                setSeleccionados(s => {
                    if (s.some(item => item.id === data.id)) {
                        return s.filter(f => f.id !== data.id);
                    } else {
                        return [...s, data];
                    }
                });
            } else if (data.tracking_number?.length > 3) {
                setToPrint(b => !b);

                setToPrintList(s => {
                    if (s.some(item => item.id === data.id)) {
                        return s.filter(f => f.id !== data.id);
                    } else {
                        return [...s, data];
                    }
                });
            }
        }

        const handleWayPay = (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (_status === "Pagado" && isChecked) {
                setFormapago(!formapago);
                setSeleccionados(s => {
                    let _new = s.map(item => {
                        if (item.id === data.id) {
                            return { ...item, marcado: !formapago };
                        } else {
                            return item;
                        }
                    });
                    return _new;
                });
                // setData(d => ({ ...d, marcado: !formapago }));
            }
        }

        const handleChangeCode = (e) => {
            e.stopPropagation();
            e.preventDefault();

            _province = currentProvince.toString().toLowerCase().acentos();
            _city = currentCity.toString().toLowerCase().acentos();

            _obj_provinces = ciudades.filter(item => item.departamento.toString().toLowerCase().acentos().includes(_province));
            _obj_city = _obj_provinces.filter(item => item.ciudad.toString().toLowerCase().acentos().includes(_city));

            setData(d => ({ ...d, ciudad: _obj_city[0] }));
            setShowAply(false);
        }

        const cargaFlete = async () => {
            let _resp = await getFlete({
                tipo: data.financial_status,
                order: data.name,
                formapago: data.marcado ? 7 : 4,
                order_id: data.id,
                codigo: data.ciudad?.codigo,
                destinatario: {
                    nombre: data.customer.default_address?.name ?? "no name",
                    direccion: data.customer.default_address?.address1 ?? "no address",
                    telefono: data.customer.default_address?.phone ? data.customer.default_address?.phone.replaceAll(' ', '') : "",
                    cedula: data.customer.default_address?.company ?? "",
                },
                producto: {
                    nombre: data.name + " - Paquete Hit Colombia",
                    precio: data.financial_status === "pending" ? parseInt(data.total_price) : 0,
                }
            });

            if (_resp.response === -1) {
                setFlete(<strong className="_error">{_resp.msg}</strong>);
            } else {
                setFlete(<strong>{_resp.msg}</strong>);
            }
        }

        React.useEffect(() => {
            let _departamentos = {};
            let _ciudades = [];

            ciudades.forEach(item => {
                if (!_departamentos[item.departamento]) {
                    _departamentos[item.departamento] = item;
                }
            });

            ciudades.forEach(item => {
                if (item.departamento.toLowerCase().acentos() === _province) {
                    _ciudades.push(item);
                }
            });

            setDepartamentos(Object.values(_departamentos));
            setCiudadesPorDepartamentos(_ciudades);

            if (data.ciudad?.codigo && data.tracking_number === undefined) {
                cargaFlete();
            } else if (data.fulfillment_status === "fulfilled") {
                setFlete(<strong className="_ok">✅​</strong>);
            } else {
                // setFlete(<strong className="_error">Ciudad o departamento no encontrado</strong>);
                setFlete("no city");
            }
        }, [data]);

        return <Menu
            data={data}
            interline={interline}>

            {media_movil ?
                <td>
                    <pre className="cont_checkbox">
                        {(data.tracking_number === undefined && data.ciudad?.codigo) ?
                            <>
                                <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                                <span>pedido:&nbsp;</span>
                            </>
                            :
                            <>
                                {data.tracking_number ? <Iconos.CheckBold className="_correcto" /> : <Iconos.Close className="_error" />}
                                <span>pedido:&nbsp;</span>
                            </>
                        } {data.name}
                        <span>Estado:&nbsp;</span> {_status}
                    </pre>
                    <pre style={{ paddingLeft: "2rem" }}>{data.tracking_number ?
                        <><span>Guía:&nbsp;</span> <a href={data.tracking_url} target="_blank" onClick={e => e.stopPropagation()}>{data.tracking_number}</a></>
                        :
                        <><span>Costo envio:&nbsp;</span> {data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</>
                    }
                    </pre>
                </td>
                :
                <>
                    <td className="cont_checkbox" onClick={handleSelect}>
                        {(data.tracking_number === undefined && data.ciudad?.codigo) ?
                            <input type="checkbox" name={data.id} checked={isChecked} readOnly={true} />
                            :
                            <>
                                {data.tracking_number ?
                                    <Iconos.CheckBold className={toPrint ? "_correcto _to_print" : "_correcto"} />
                                    :
                                    <>
                                        {data.fulfillment_status === "fulfilled" ?
                                            <Iconos.CheckOk className="_correcto" />
                                            :
                                            <Iconos.Close className="_error" />
                                        }
                                    </>
                                }
                            </>
                        } {data.name}
                    </td>
                    <td className={_status === "Pagado" ? isChecked ? "cont_checkbox_second" : "cont_checkbox_second disable" : "cont_checkbox_second"} onClick={handleWayPay}>
                        {_status === "Pagado" && <input type="checkbox" checked={formapago} readOnly={true} />}
                        {_status}
                    </td>
                    <td>{data.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</td>
                    <td className="cont_city">
                        {data.tracking_number ?
                            <span>Guía: <a href={data.tracking_url} target="_blank">{data.tracking_number}</a></span>
                            :
                            <>
                                {flete === "no city" ?
                                    <span className="with_selects">
                                        <InputListOnly
                                            ref={provinceRef}
                                            placeholder="Departamento"
                                            value={currentProvince}
                                            onChange={v => {
                                                alertaRemove(provinceRef.current);
                                                alertaRemove(cityRef.current);
                                                setCurrentProvince(v);
                                                setCurrentCity("");
                                                let _ciudades = [];

                                                ciudades.forEach(item => {
                                                    if (item.departamento === v) {
                                                        _ciudades.push(item);
                                                    }
                                                });

                                                setCiudadesPorDepartamentos(_ciudades);
                                                setShowAply(true);
                                            }}
                                            list={departamentos.map(item => {
                                                return item.departamento?.trim();
                                            })} />
                                        <InputListOnly
                                            ref={cityRef}
                                            placeholder="Ciudad"
                                            value={currentCity}
                                            onChange={v => {
                                                alertaRemove(cityRef.current);
                                                setCurrentCity(v);
                                                setShowAply(true);
                                            }}
                                            list={ciudadesPorDepartamentos.map(item => {
                                                return item.ciudad?.trim();
                                            })} />

                                        {showAply && <button type="button" onClick={handleChangeCode}>Aplicar</button>}
                                    </span>
                                    :
                                    <span>{data.ciudad?.ciudad}{data.ciudad?.departamento ? ", " + data.ciudad?.departamento : ""} {flete}</span>
                                }
                            </>
                        }
                    </td>
                </>
            }
        </Menu>;
    }

    const armaFilas = () => {
        let _data = dataList.map((item, i) => {
            return <Row
                key={item.id}
                interline={i % 2}
                info={item} />;
        });

        if (_data.length < 1) {
            setRows(<h4 className="msg_lazy_load _alone _left">No hay pedidos</h4>);
        } else {
            setRows(<>
                <table className="table">
                    <tbody>
                        {media_movil ?
                            <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                                <th>&nbsp;</th>
                            </tr>
                            :
                            <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                                <th>Pedido</th>
                                <th>Estado del pago</th>
                                <th style={{ whiteSpace: "nowrap" }}>Estado preparación</th>
                                <th>Costo envio</th>
                            </tr>
                        }
                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <div className="content_btns _center">
                        {navBtns.next !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("next")}>Anterior</button>}
                        {navBtns.prev !== "" && <button className="btn btn_lazy_load" onClick={() => getListLazy("prev")}>Siguiente</button>}
                    </div>
                }
            </>);
        }

        // window.scrollTo(0, 120);
    }

    const reloadList = async () => {
        setShowLoading(true);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: "lazy",
                direction: prevLastId.direction,
                limit: 20,
                since_id: prevLastId.since
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                if (prevLastId.since !== 0) {
                    _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                }
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
            setSeleccionados([]);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async (direction) => {
        setShowLoading(true);
        let position = direction === "next" ? dataList.length - 1 : 0;
        let prev_last_id = dataList[position].id;
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: currentQuery,
                criterio: criterio,
                lazy: true,
                direction,
                limit: 20,
                since_id: dataList[position].id
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setPrevLastId({ direction, since: prev_last_id });
            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);
        setCurrentQuery("todos");
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: "todos",
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery("search");
        setDataList([]);
        let _data = await api.fetchDirect({
            url: "shopify-get-orders",
            data: {
                task: "search",
                criterio: _val,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            let _prev = "";
            let _next = "";

            if (_data.headers.link) {
                // _prev = _data.headers.link.includes('>; rel="previous"') ? _data.headers.link.split('>; rel="previous"')[0].split('<').pop() : "";
                _next = _data.headers.link.includes('>; rel="next"') ? _data.headers.link.split('>; rel="next"')[0].split('<').pop() : "";
            }

            setNavBtns({ prev: _prev, next: _next });
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getFlete = async (params) => {
        let _data = await api.fetchJson({
            url: "envia-get-flete",
            data: params
        });

        if (_data.response == 1) {
            let _res = JSON.parse(_data.data);
            return { response: 1, msg: `$${(_res.valor_flete + _res.valor_costom + _res.valor_otros)}` };
        } else {
            return { response: -1, msg: _data.msg };
        }
    }

    const crearGuias = async () => {
        setShowLoading(true);
        let _guias = seleccionados.map(pedido => {
            return {
                tipo: pedido.financial_status,
                order: pedido.name,
                formapago: pedido.marcado ? 7 : 4,
                order_id: pedido.id,
                fulfill_id: pedido.fulfill_id,
                codigo: pedido.ciudad.codigo,
                destinatario: {
                    nombre: pedido.customer.default_address?.name ?? "no name",
                    direccion: pedido.customer.default_address?.address1 ?? "no address",
                    telefono: pedido.customer.default_address?.phone ? pedido.customer.default_address?.phone.replaceAll(' ', '') : "",
                    cedula: pedido.customer.default_address?.company ?? "",
                },
                producto: {
                    nombre: pedido.name + " - Paquete Hit Colombia",
                    precio: pedido.financial_status === "pending" ? parseInt(pedido.total_price) : 0,
                }
            }
        });

        let _data = await api.fetchJson({
            url: "set-guias-envia",
            data: _guias
        });

        if (_data.response == 1) {
            reloadList();
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const goPrint = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/guias-imprimir", { state: { data: toPrintList } });
    }

    React.useEffect(() => {
        armaFilas();
    }, [dataList]);

    React.useEffect(() => {
        setTitle("Guías");

        funciones.current.validaSesion("guias_ver").then(response => {
            if (response) {
                setPermission(true);
                getList();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar_sticky">
                <img className="light" src={logo} alt="Logo Envía" />
                <div className="in_row">
                    {seleccionados.length < 1 ?
                        <h3>No hay pedidos seleccionados</h3>
                        :
                        <h3>{seleccionados.length} pedido{seleccionados.length == 1 ? "" : "s"} seleccionado{seleccionados.length == 1 ? "" : "s"}</h3>
                    }

                    {funciones.current.validaPermisos("guias_crear") && <>
                        {seleccionados.length < 1 ?
                            <button type="button" className="btn btn_with_icon _left unable"><Iconos.CheckBook />Crear guía</button>
                            :
                            <button type="button" className="btn btn_with_icon _left" onClick={crearGuias}><Iconos.CheckBook />Crear guía{seleccionados.length == 1 ? "" : "s"}</button>
                        }
                    </>}

                    {funciones.current.validaPermisos("guias_imprimir") && <>
                        {media_movil ? null :
                            <>
                                {toPrintList.length < 1 ?
                                    <button type="button" className="btn btn_with_icon _left unable"><Iconos.PrinterOutline />Imprimir guía</button>
                                    :
                                    <button type="button" className="btn btn_with_icon _left" onClick={goPrint}><Iconos.PrinterOutline />Imprimir {toPrintList.length} guía{toPrintList.length == 1 ? "" : "s"}</button>
                                }
                            </>
                        }
                    </>}
                </div>
            </div>

            <div className="head_bar with_margin">
                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar pedido"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            {rows}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(GuiasEnvia);