import * as React from "react";

/* eslint eqeqeq: 0 */

const Menu = (params) => {
    const { data, interline, children } = params;
    const [clases, setClases] = React.useState(interline == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line");


    React.useEffect(() => {
        switch (data.estado) {
            case '1':
                setClases(c => c + " bkg_aceptado");
                break;
            case '2':
                setClases(c => c + " bkg_pendiente");
                break;
            case '3':
                setClases(c => c + " bkg_rechazado");
                break;
            case '4':
                setClases(c => c + " bkg_pagado");
                break;
            case '5':
                setClases(c => c + " bkg_pagado_pendiente");
                break;
            case '6':
                setClases(c => c + " bkg_aceptado_cambio");
                break;
            case '7':
                setClases(c => c + " bkg_recibido");
                break;
            default:
                break;
        }
    }, [data.estado]);

    return (
        <tr className={clases}>

            {children}

            <td className="td_menu"></td>
        </tr>
    )
}

export default Menu;