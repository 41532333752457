import * as React from "react";
import { Funciones } from "services/funciones";
import { useParams } from "react-router-dom";
import { useMedia } from "hooks";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, current, inRow = false } = params;
    const { setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [modalView, setModalView] = React.useState(null);
    const path_params = useParams();


    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/detalle-cierre/"+path_params.local, { state: { data: data } });
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: "0", left: "0" });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            if (inRow) {
                setPos(funciones.current.getPosInRow(e, menuRef.current));
            } else {
                setPos(funciones.current.getPosInDisplay(e, menuRef.current));
            }
            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (<>
            <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
            <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                <ul>
                    <li onClick={goOpen}><Iconos.OpenIn /> <span><strong>Detalle</strong></span></li>
                    <hr />
                </ul>
            </nav>
        </>)
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onDoubleClick={goOpen}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="container _detalle" onContextMenu={handleContextMenu}>

                        {children}
                        {modalView}
                    </div>
                </>}
        </>
    )
}

export default Menu;