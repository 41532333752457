import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputList, InputWithIcon } from "shared/Components";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditLocal = (router) => {
    const { navigate, location } = router;
    const { setTitle, data_user, setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [direccion, setDireccion] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [userList, setUserList] = React.useState([]);
    const [adminId, setAdminId] = React.useState(0);
    const [nombre, setNombre] = React.useState("");
    const [admin, setAdmin] = React.useState("");
    const [email, setEmail] = React.useState("");

    const direccionRef = React.useRef(null);
    const telefonoRef = React.useRef(null);
    const nombreRef = React.useRef(null);
    const adminRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passRef = React.useRef(null);


    const handleSelect = (_name) => {
        alertaRemove(adminRef.current);
        setAdmin(_name);

        let _user = userList.filter(info => info.nombres === _name);
        _user = _user[0];

        if (typeof _user === "object") {
            setAdminId(_user.id);
        } else if (_name !== "") {
            setAdminId(0);
            alerta(adminRef.current, "¡El usuario no existe!");
            adminRef.current.focus();
        }
    }

    const saveUser = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-local",
            data: {
                task: "local",
                info: {
                    nombre,
                    admin_id: adminId,
                    telefono,
                    direccion,
                    correo: email,
                    local_id: location.state.data.id
                }
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate("/locales"));
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaCambios = () => {
        let _data = location.state.data;

        if (_data.nombre === nombre &&
            _data.telefono === telefono &&
            _data.direccion === direccion &&
            _data.correo === email &&
            parseInt(_data.admin.id) == adminId) {
            return false;
        }

        return true;
    }

    const validate = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        alertaRemove(nombreRef.current);
        alertaRemove(telefonoRef.current);
        alertaRemove(direccionRef.current);
        alertaRemove(emailRef.current);
        alertaRemove(passRef.current);

        if (nombre == null || nombre.length < 1) {
            alerta(nombreRef.current, "¡Debes ingresar el nombre del local!");
            nombreRef.current.focus();
            return false;
        }

        if (telefono == null || telefono.length < 1) {
            alerta(telefonoRef.current, "¡Debes ingresar el número de teléfono del local!");
            telefonoRef.current.focus();
            return false;
        }

        if (direccion == null || direccion.length < 1) {
            alerta(direccionRef.current, "¡Debes ingresar la dirección del local!");
            direccionRef.current.focus();
            return false;
        }

        if (email == null || email.length < 1) {
            alerta(emailRef.current, "¡Debes ingresar el correo eléctronico del local!");
            emailRef.current.focus();
            return false;
        }

        if (!regex.test(String(email).toLowerCase())) {
            alerta(emailRef.current, "¡El formato del correo es erróneo!");
            emailRef.current.focus();
            return false;
        }

        if (adminId == null || adminId < 1) {
            alerta(adminRef.current, "¡Debes agregar un administrador para el local!");
            adminRef.current.focus();
            return false;
        }

        if (validaCambios()) {
            saveUser();
        } else {
            Alert("¡No hay cambios en la información!", "warning");
        }
    }

    const getAsesores = async (_id) => {
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "asesores"
            }
        });

        if (_data.response == 1) {
            let _user = _data.data.filter(info => parseInt(info.id) === _id);
            _user = _user[0];

            if (typeof _user === "object") {
                setAdmin(_user.nombres);
                setAdminId(_user.id);
            }

            setUserList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {

        if (location.state && location.state.data) {
            let _data = location.state.data;
            setTitle("Editar local " + location.state.data.nombre, location.state);

            funciones.current.validaSesion("locales_edita").then(response => {
                if (response && [1, 2].includes(parseInt(data_user.nivel))) {
                    getAsesores(parseInt(_data.admin.id));
                    setPermission(true);
                    setNombre(_data.nombre || "");
                    setTelefono(_data.telefono || "");
                    setDireccion(_data.direccion || "");
                    setEmail(_data.correo || "");
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });

        } else {
            setShowLoading(false);
            setTitle("Editar usuario", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Editar local "{location.state && location.state.data.nombre}"</h2>
            </div>

            <div className="content_primary">

                <InputWithIcon
                    ref={nombreRef}
                    type="text"
                    title="Nombre"
                    name="nombre"
                    value={nombre}
                    onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

                <InputWithIcon
                    ref={telefonoRef}
                    type="tel"
                    title="Teléfono"
                    name="telefono"
                    Icono={Iconos.Phone}
                    value={telefono}
                    onChange={v => { alertaRemove(telefonoRef.current); setTelefono(v) }} />

                <InputWithIcon
                    ref={direccionRef}
                    type="text"
                    title="Dirección"
                    name="direccion"
                    Icono={Iconos.HomeMarker}
                    value={direccion}
                    onChange={v => { alertaRemove(direccionRef.current); setDireccion(v) }} />

                <InputWithIcon
                    ref={emailRef}
                    type="text"
                    title="Correo eléctronico"
                    name="email"
                    Icono={Iconos.Email}
                    value={email}
                    onChange={v => { alertaRemove(emailRef.current); setEmail(v) }} />

                <InputList
                    ref={adminRef}
                    title="Administrador"
                    Icono={Iconos.UserAdmin}
                    value={admin}
                    onChange={handleSelect}
                    list={userList.map(item => item.nombres)} />

            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Guardar local</button>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(EditLocal);