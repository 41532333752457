import * as React from "react";
import { InputList, InputWithIcon, SelectWithIcon } from "shared/Components";
import { Alert, alerta, alertaRemove, Confirm } from "shared/Alert";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const DeliveryDispatch = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [carrierInfo, setCarrierInfo] = React.useState({});
    const [currentList, setCurrentList] = React.useState([]);
    const [carrierList, setCarrierList] = React.useState([]);
    const [domicsList, setDomicsList] = React.useState([]);
    const [domici, setDomici] = React.useState("");
    const [carrier, setCarrier] = React.useState("");
    const [guia, setGuia] = React.useState("");

    const carrierRef = React.useRef(null);
    const domiciRef = React.useRef(null);
    const guiaRef = React.useRef(null);


    const handleDomiciliary = (_value) => {
        alertaRemove(domiciRef.current);
        setDomici(_value);

        let item = domicsList.filter(({ info }) => {
            let _info = JSON.parse(info);
            let _nombres = _info.nombre + " " + _info.apellido;
            return _nombres.trim() === _value;
        });
        item = item[0];

        if (typeof item === "object") {
            setCarrierInfo({ id: item.id, name: _value });
            guiaRef.current.focus();
        } else {
            setCarrierInfo({});
            alerta(domiciRef.current, "¡El domiciliario es invalido!");
            domiciRef.current.focus();
        }
    }

    const handleCarrier = (_value) => {
        alertaRemove(carrierRef.current);

        if (_value === "Agregar nuevo") {
            navigate("/crear-usuario", { state: { rol: 6 } });
        } else if (_value === "Domiciliario") {
            setCarrier(_value);
            getDomics();
        } else {
            let item = carrierList.filter(({ info }) => {
                let _info = JSON.parse(info);
                let _nombres = _info.nombre + " " + _info.apellido;
                return _nombres.trim() === _value;
            });
            item = item[0];

            if (typeof item === "object") {
                setCarrier(_value);
                setCarrierInfo({ id: item.id, name: _value });
                guiaRef.current.focus();
            } else {
                setCarrier("");
                setCarrierInfo({});
                alerta(carrierRef.current, "¡La transportadora es invalida!");
                carrierRef.current.focus();
            }
        }
    }

    const handleInputGuia = (_value) => {
        alertaRemove(guiaRef.current);
        setGuia(_value);
        setTimeout(() => {
            if (guiaRef.current.value.length === _value.length) {
                validate(_value);
            }
        }, 1000);
    }

    const saveDispatch = async (_guia) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "delivery-dispatch",
            data: {
                task: "??",
                info: {
                    guia: _guia,
                    carrier: carrierInfo,
                    state_int: 1,
                    state_name: "Entregado a " + carrierInfo.name,
                }
            }
        });


        if (_data.response == 1) {
            let _info = _data.data[0]
            setTimeout(() => {
                setCurrentList(l => {
                    return [...l, {
                        id: l.length + 1,
                        old: _info.old,
                        guia: _info.guia,
                        pedidos: _info.pedidos,
                        carrier: _info.carrier,
                        fecha_creado: _info.fecha_creado
                    }];
                });
                setGuia("");
                guiaRef.current.focus();
                setShowLoading(false);
            }, 200);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }
    }

    const validaCarrier = async (_guia) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "delivery-validate-carrier",
            data: {
                task: "??",
                info: {
                    guia: _guia,
                    carrier: {...carrierInfo, normalize: carrierInfo.name.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "")},
                }
            }
        });


        if (_data.response == 1) {
            saveDispatch(_guia);
        } else if (_data.response == 2) {
            setShowLoading(false);
            Confirm(_data.msg, async (response) => {
                if (response) {
                    saveDispatch(_guia);
                }
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }
    }

    const validate = (_guia) => {
        alertaRemove(guiaRef.current);
        alertaRemove(carrierRef.current);
        carrier === "Domiciliario" && alertaRemove(domiciRef.current);

        if (carrierInfo.id === undefined) {
            if (carrier === "Domiciliario") {
                alerta(domiciRef.current, "¡Debes ingresar un domocililario!");
                domiciRef.current.focus();
            } else {
                alerta(carrierRef.current, "¡Debes seleccionar la tranportadora!");
                carrierRef.current.focus();
            }


            return false;
        }

        if (_guia == null || _guia.length < 1) {
            alerta(guiaRef.current, "¡Debes ingresar el número de la guía!");
            guiaRef.current.focus();
            return false;
        }

        validaCarrier(_guia);
    }

    const getDomics = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "domiciliarios"
            }
        });

        if (_data.response == 1) {
            setDomicsList(_data.data);
            // domiciRef.current.focus();
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getCarriers = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "carriers"
            }
        });

        if (_data.response == 1) {
            setCarrierList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setCurrentList([]);
    }, [carrierInfo]);


    React.useEffect(() => {

        if (location.state && location.state.data) {
            let _data = location.state.data;

            setGuia(_data.guia);
        }
    }, [location.state]);

    React.useEffect(() => {
        setTitle("Entregar despachos");

        funciones.current.validaSesion("despachos_entregar").then(response => {
            if (response) {
                setPermission(true);
                getCarriers();
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Entregar despachos</h2>
            </div>

            {carrier === "Domiciliario" && <div className="content_primary">
                <InputList
                    ref={domiciRef}
                    title="Domiciliario"
                    Icono={Iconos.Moped}
                    value={domici}
                    onChange={handleDomiciliary}
                    list={domicsList.map(({ info }) => {
                        let _info = JSON.parse(info);
                        let _nombres = _info.nombre + " " + _info.apellido;
                        return _nombres.trim();
                    })} />
            </div>}

            <div className="content_primary">

                <SelectWithIcon
                    ref={carrierRef}
                    title="Transportadora"
                    Icono={Iconos.TruckFast}
                    value={carrier}
                    onChange={handleCarrier} >
                    <option value="">Selecciona transportadora</option>
                    {carrierList.map(item => {
                        let _info = JSON.parse(item.info);
                        return <option key={item.id}>{_info.nombre} {_info.apellido}</option>;
                    })}
                    <option>Domiciliario</option>
                    <option>Agregar nuevo</option>
                </SelectWithIcon>

                <InputWithIcon
                    ref={guiaRef}
                    type="text"
                    title="Número de guía"
                    value={guia}
                    readOnly={carrierInfo.id === undefined}
                    className={carrierInfo.id === undefined ? "unable" : ""}
                    onChange={handleInputGuia} />
            </div>

            {(location.state.data && location.state.data.estado == 0 && location.state.data.guia === guia) &&
                <div className="content_btns _with_margin">
                    <button type="button" className="btn btn_primary" onClick={() => validate(guia)}>Entregar</button>
                </div>}


            {currentList.length > 0 && <>
                <p className="count_list"><b>{currentList.length}</b> entregado{currentList.length > 1 ? "s" : ""} a <b>{carrierInfo.name}</b></p>
                <table className="table">
                    <tbody>
                        {media_movil ?
                            <tr className="table_title">
                                <th>Prueba</th>
                            </tr>
                            :
                            <tr className="table_title">
                                <th></th>
                                <th>Guía</th>
                                <th>Pedido</th>
                                <th>Transportadora</th>
                                <th>Fecha creado</th>
                            </tr>
                        }
                        {currentList.map((item, i) => {
                            let fecha_creado = new Date(item.fecha_creado);
                            return <tr key={item.id} className={(i % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                                {media_movil ?
                                    <td>
                                        <pre><span>Guía:</span> {item.guia}  <span>Pedido:</span> {item.pedidos}</pre>
                                        {item.carrier}
                                        <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                                    </td>
                                    :
                                    <>
                                        <td>{i + 1}</td>
                                        <td>{item.guia}</td>
                                        <td>{item.pedidos}</td>
                                        <td>{item.carrier}</td>
                                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                        <td className="td_menu"></td>
                                    </>
                                }
                            </tr>;
                        })}
                    </tbody>
                </table>
            </>}

            <div className="content_btns">

                <NavLink
                    to="/despachos"
                    className="btn link_to_btn _right">
                    Ver todos los despachos
                </NavLink>
            </div>
            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(DeliveryDispatch);