import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Closures = (router) => {
    const { navigate, params } = router;
    const { setTitle, media_movil, data_user, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [currentQuery, setCurrentQuery] = React.useState("cortes");
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const Row = ({ data, interline }) => {
        let _fecha_cierre = new Date(data.fecha_cierre);

        return (
            <Menu
                inRow={true}
                data={data}
                interline={interline}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery)}>

                {media_movil ?
                    <td>
                        {data.nombre}
                        <pre><b>Correo: </b>{data.correo}</pre>
                        <pre><b>Teléfono: </b>{data.telefono}</pre>
                        <pre><b>Administrador: </b>{data.admin.nombre} <sup>{data.admin.correo}</sup></pre>
                    </td>
                    :
                    <>
                        <td>${funciones.current.formatPrice(data.valor_apertura).format}</td>
                        <td>${funciones.current.formatPrice(data.valor_efectivo).format} <sup>{data.cantidad_efectivos}</sup></td>
                        <td>${funciones.current.formatPrice(data.valor_otros).format}</td>
                        <td>${funciones.current.formatPrice(data.valor_cierre).format}</td>
                        <td>{format(_fecha_cierre.getDate())}/{meses[_fecha_cierre.getMonth()]}/{_fecha_cierre.getFullYear()} {format(_fecha_cierre.getHours())}:{format(_fecha_cierre.getMinutes())}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _footer;
        let _data = dataList.map((item, i) => {
            let _info = JSON.parse(item.info_closure);
            _info.otros = JSON.parse(_info.otros);
            _info.id = item.id;

            return <Row
                key={item.id}
                interline={i % 2}
                data={_info} />;
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">No hay cierres</h4>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="msg_lazy_load">¡No hay más cierres!</h4>
                        }
                    </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Lista de cierres</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Valor apertura</th>
                            <th>Ventas</th>
                            <th>Ingresos y gastos</th>
                            <th>Valor entregado</th>
                            <th>Fecha cierre</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-sales",
            data: {
                task: currentQuery,
                tienda: window.atob(params.local),
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_task = "cortes") => {
        setShowLoading(true);
        setCurrentQuery(_task);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-sales",
            data: {
                task: _task,
                tienda: window.atob(params.local),
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Cierres de caja");

        funciones.current.validaSesion("locales_admin").then(response => {
            if ((response && data_user.local.id === params.local) || funciones.current.validaPermisos("locales_ver")) {
                getList();
                setPermission(true);
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2>Cierres de caja</h2>
            </div>

            <AllRow />

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Closures);