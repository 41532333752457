import * as React from "react";
import { alertArea, alerta, alertaRemove } from "shared/Alert";
import { InputWithIcon, TextArea } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Funciones } from "services/funciones";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import { useMedia } from "hooks";
import { DropFile } from "shared/FileUpload";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */


const Salida = ({ parent, onChange, close }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [monto, setMonto] = React.useState("");
    const [nota, setNota] = React.useState("");
    const [file, setFile] = React.useState([]);

    const montoRef = React.useRef(null);
    const notaRef = React.useRef(null);
    const fileRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(montoRef.current);
        alertaRemove(notaRef.current);
        let _monto = funciones.current.formatPrice(monto).int;

        if (_monto.length < 1) {
            alerta(montoRef.current, "¡Debes ingresar el monto!");
            montoRef.current.focus();
            return false;
        }

        if (isNaN(_monto.split(',')[0])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (_monto.split(',')[1] && isNaN(_monto.split(',')[1])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (parseFloat(_monto.toString().replace(',', '.')) < 0) {
            alerta(montoRef.current, "¡Debes ingresar un monto valido!");
            montoRef.current.focus();
            return false;
        }

        if (nota.length < 1) {
            alertArea(notaRef.current, "¡Debes ingresar un concepto!");
            notaRef.current.focus();
            return false;
        }

        document.body.style.overflow = "auto";
        onChange({
            tipo: "Gasto",
            monto: _monto,
            nota,
            foto: file.length > 0 ? file[0] : [],
            id: new Date().getTime()
        });
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";

        if (montoRef.current) {
            montoRef.current.focus();
        }
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Agregar Gasto">

            <div className="content_primary _on_popup">

                <InputWithIcon
                    ref={montoRef}
                    type="text"
                    title="Monto"
                    Icono={Iconos.Currency}
                    value={funciones.current.formatPrice(monto).format}
                    onChange={v => { alertaRemove(montoRef.current); setMonto(v) }} />

                <TextArea
                    ref={notaRef}
                    title="Concepto"
                    value={nota}
                    onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />

                <div className="form_group _with_dropzone">
                    <span className="form_group-label">Foto del comprobante</span>
                    <DropFile
                        ref={fileRef}
                        attach={file}
                        onChange={v => {
                            alertaRemove(fileRef.current);
                            setFile(v.length > 0 ? [v[0]] : []);
                        }} />
                </div>
            </div>

            <div className="content_btns _without_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Agregar</button>
            </div>
        </MovePopUp>)
}

export default Salida;