import * as React from "react";
import { InputSearch, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import Loading, { LoadingProgress } from "shared/Loading";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Menu from "./components/Menu";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */

const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ColaImpresion = (router) => {
    const { navigate } = router;
    const { setTitle, media_movil, currentQuery, setCurrentQuery, setDataUser } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando pedidos...");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showProgress, setShowProgress] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [permission, setPermission] = React.useState(false);
    const [toPrintList, setToPrintList] = React.useState([]);
    const [carrierList, setCarrierList] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [asc, setAsc] = React.useState(false);
    const carrierRef = React.useRef(null);


    const FiltroCarrier = () => {
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const [carrier, setCarrier] = React.useState("");


        const handleSelct = (_opcion) => {
            document.body.style.overflow = "auto";
            setCarrier(_opcion);
            searchCarriers({ task: _opcion, criterio: _opcion });
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 270) });
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={pos}>
                    {showFilter ?
                        <>
                            <h4 className="filter_title">Filtrar por transportadora</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />
                        </>
                        :
                        <Iconos.Filter className="filter_icon" onClick={handleClic} />}
                    {showFilter &&
                        <SelectWithIcon
                            ref={carrierRef}
                            title="Transportadora"
                            Icono={Iconos.TruckFast}
                            value={carrier}
                            onChange={handleSelct} >
                            <option value="">Selecciona transportadora</option>
                            {carrierList.map(item => {
                                let _info = JSON.parse(item.info);
                                return <option key={item.id}>{_info.nombre} {_info.apellido}</option>;
                            })}
                        </SelectWithIcon>}
                </div>
            </>
        )
    }

    const CardProduct = ({ data }) => {
        const [showCard, setShowCard] = React.useState(false);
        const title = data.total_articulos + " " + (data.total_articulos > 1 ? "artículos" : "articulo");
        const products = data.line_items;

        return <td className="td_info">
            <div className="info_name _product" onClick={() => setShowCard(true)}>
                <p>{title}</p>
                <Iconos.Products className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    <h5>{title}</h5>
                    {products.map((item, i) => <p key={i} className="info_product">{item.quantity} x {funciones.current.formatPrice(item.price.toString().replace(".", ",")).format} - <span>{item.name}</span></p>)}
                </div>
            </>
            }
        </td>;
    }

    const CardCustomer = ({ customer }) => {
        const [infoCustomer, setInfoCustomer] = React.useState({});
        const [showCard, setShowCard] = React.useState(false);
        const [loadInfo, setLoadInfo] = React.useState(true);

        const getCustomer = async () => {
            setLoadInfo(true);

            let _data = await api.fetchJson({
                url: "shopify-get-customer",
                data: {
                    id: customer.id,
                    tipo: "id"
                }
            });

            if (_data.response == 1) {
                setInfoCustomer(_data.data);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setLoadInfo(false);
        }

        React.useEffect(() => {
            if (showCard && !infoCustomer.id) {
                getCustomer();
            }
        }, [showCard]); // eslint-disable-line react-hooks/exhaustive-deps

        return <td className="td_info">
            <div className="info_name" onClick={() => setShowCard(true)}>
                <p>{customer?.first_name} {customer?.last_name}</p>
                <Iconos.CardSelect className="icon_card" />
            </div>

            {showCard && <>
                <div className="back_float" onClick={() => setShowCard(false)}></div>
                <div className="info_card">
                    {loadInfo ?
                        <h5>{customer?.first_name} {customer?.last_name} <Iconos.Loading className="icon_loading" /></h5>
                        :
                        <h5>{infoCustomer?.first_name} {infoCustomer?.last_name}</h5>
                    }
                    {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                    {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}
                    {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                    {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                    {infoCustomer?.default_address?.zip && <p className="info_nota"><b>Observación:</b> {infoCustomer?.default_address?.zip}</p>}
                </div>
            </>
            }
        </td>;
    }

    const Row = ({ data, interline }) => {
        let fecha_creado = new Date(data.created_at);
        let _tag = data.tags.includes("CONTRAENTREGA") ? "CONTRAENTREGA" : null;
        let _carrier = "";
        carrierList.forEach(item => {
            if (data.tags.toLowerCase().includes(item.nombres.trim().toLowerCase())) {
                _carrier = item.nombres.trim();
            }
        });

        const handleSelect = (e) => {
            e.preventDefault();
            e.stopPropagation();

            setToPrintList(s => {
                if (s.some(item => item.id === data.id)) {
                    return s.filter(f => f.id !== data.id);
                } else {
                    return [...s, data];
                }
            });
        }

        return <Menu
            inRow={true}
            data={data}
            interline={interline}
            navigate={navigate}
            current={selected.id}
            onSel={setSelected}
            setOpen={() => { }}
            showProgress={showProgress}
            reload={() => getList(currentQuery.to_print)}>

            {media_movil ?
                <td>
                    <strong>{data.name}</strong>
                    <pre><span>Artículos:</span> {data.total_articulos} {data.total_articulos > 1 ? "artículos" : "articulo"}</pre>
                    <pre><span>Valor:</span> ${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</pre>
                    <pre><span>Pago:</span> {data.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.financial_status.toLowerCase() === "paid" ? "Pagado" : data.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.financial_status} {_tag && <b className="tag">{_tag}</b>}</pre>
                    <pre><span>Cliente:</span> {data.customer.first_name} {data.customer.last_name}</pre>
                    <i>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</i>
                </td>
                :
                <>
                    <td className="cont_checkbox _with_frame" onClick={handleSelect}>
                        <input type="checkbox" name={data.id} checked={toPrintList.some(item => item.id === data.id)} readOnly={true} />
                        {data.name}
                    </td>
                    <CardProduct data={data} />
                    <td>${funciones.current.formatPrice(data.total_price.toString().replace(".", ",")).format}</td>
                    <td>{data.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.financial_status.toLowerCase() === "paid" ? "Pagado" : data.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.financial_status} {_tag && <b className="tag">{_tag}</b>}</td>
                    <td>{_carrier}</td>
                    {data.customer ? <CardCustomer customer={data.customer} /> : <td><span>Sin cliente</span></td>}
                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                </>
            }
        </Menu>;
    }

    const AllRow = () => {
        let _footer;
        let _data = dataList.map((item, i) => {
            item.total_articulos = item.line_items.map((item) => item.quantity).reduce((a, b) => a + b, 0);

            return <Row
                key={item.id}
                interline={i % 2}
                data={item} />;
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">{loadResult}</h4>;
        } else {
            _footer = <h4 className="msg_lazy_load">{loadResult}</h4>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Pedidos por imprimir</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th className="cont_checkbox _with_frame" onClick={() => {
                                setToPrintList(() => {
                                    if (toPrintList.length == dataList.length) {
                                        return [];
                                    } else {
                                        return dataList;
                                    }
                                });
                            }}>
                                <input type="checkbox" name="all" checked={toPrintList.length == dataList.length && dataList.length > 0} readOnly={true} />
                                Pedido
                            </th>
                            <th>Artículos</th>
                            <th>Valor</th>
                            <th>Pago</th>
                            <th>Transportadora<FiltroCarrier /></th>
                            <th>Cliente</th>
                            <th><Iconos.SwapVertical onClick={() => sortBy("fecha_creado")} />Fecha</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const sortBy = (_key) => {
        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getList = async (_filter) => {
        setLoadResult(_filter.task === "todos" ? "Cargando pedidos..." : "Cargando pedidos " + _filter.task + "...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, to_print: _filter }));
        setDataList([]);
        let _data = await api.fetchJson({
            url: "shopify-get-orders-to-print",
            data: {
                task: _filter.task,
                criterio: _filter.criterio,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter.task === "todos") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos!" : "¡No hay más pedidos!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos " + _filter.task + "!" : "¡No hay más pedidos " + _filter.task + "!");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        let _task = _val === "" ? "todos" : "search";
        setLoadResult("Buscando pedidos con: " + _val + "...");
        setShowLoading(true);
        setCurrentQuery(f => ({ ...f, to_print: { task: _task, criterio: _val } }));
        setDataList([]);
        let _data = await api.fetchJson({
            url: "shopify-get-orders-to-print",
            data: {
                task: _task,
                criterio: _val,
                limit: 20,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_task === "todos") {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos!" : "¡No hay más pedidos!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay pedidos con: " + _val : "No hay más pedidos con: " + _val);
        }

        setShowLoading(false);
    }

    const searchCarriers = async (_filter) => {
        setLoadResult("Cargando transportadora " + _filter.criterio + "...");
        setShowLoading(true);
        setToPrintList([]);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "shopify-get-carriers",
            data: {
                task: _filter.task,
                criterio: _filter.criterio,
                limit: 50,
                since_id: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay pedidos de la transportadora " + _filter.task + "!" : "¡No hay más pedidos de la transportadora " + _filter.task + "!");

        setShowLoading(false);
    }

    const getCarriers = async () => {

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: "carriers"
            }
        });

        if (_data.response == 1) {
            setCarrierList(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const goPrint = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/imprimir-facturas", { state: { data: toPrintList } });
    }

    const goPrepare = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _articulo = toPrintList.length > 1 ? " artículos como preparados?" : " articulo como preparado?";

        Confirm("¿Marcar  " + toPrintList.length + _articulo, async (response) => {
            if (response) {
                let all_responses = [];

                setShowProgress(true);
                for (let i = 0; i < toPrintList.length; i++) {
                    let item = toPrintList[i];
                    let _data = await api.fetchJson({
                        url: "shopify-prepare-order",
                        data: {
                            ...item,
                            order_id: item.id,
                            nota: item.note_attributes[1]?.name === "Observación" ? item.note_attributes[1]?.value : ""
                        }
                    });

                    all_responses.push({ name: item.name, response: _data.response, msg: _data.msg });

                    // Espera un segundo antes de continuar con la siguiente iteración
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    // Calcula el porcentaje de progreso
                    setProgress(Math.round(((i + 1) / toPrintList.length) * 100));
                }

                await new Promise(resolve => setTimeout(resolve, 1000));

                let _errores = all_responses.filter(item => item.response == -1);

                Alert(`Preparación terminada \ncon ${_errores.length} errores`, "ok", () => getList(currentQuery.to_print));
                log.i("resultado preparación", all_responses);
                setShowProgress(false);
                setToPrintList([]);
            }
        });
    }

    React.useEffect(() => {
        setTitle("Pedidos por imprimir");

        funciones.current.validaSesion("pedidos_imprimir").then(response => {
            if (response) {
                setPermission(true);
                getCarriers();
                getList(currentQuery.to_print);
            } else {
                setPermission(false);
                setShowLoading(false);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar_sticky">
                <h2>Pedidos por imprimir</h2>

                <div className="in_row">
                    {toPrintList.length < 1 ?
                        <h3>No hay pedidos seleccionados</h3>
                        :
                        <h3>{toPrintList.length} pedido{toPrintList.length == 1 ? "" : "s"} seleccionado{toPrintList.length == 1 ? "" : "s"}</h3>
                    }

                    {toPrintList.length < 1 ?
                        <button type="button" className="btn btn_with_icon _left unable"><Iconos.PrinterOutline />Imprimir factura</button>
                        :
                        <button type="button" className="btn btn_with_icon _left" onClick={goPrint}><Iconos.PrinterOutline />Imprimir {toPrintList.length} factura{toPrintList.length == 1 ? "" : "s"}</button>
                    }

                    {toPrintList.length < 1 ?
                        <button type="button" className="btn btn_with_icon _left unable"><Iconos.Prepare />Preparar artículo</button>
                        :
                        <button type="button" className="btn btn_with_icon _left" onClick={goPrepare}><Iconos.Prepare />Preparar {toPrintList.length} artículo{toPrintList.length == 1 ? "" : "s"}</button>
                    }

                </div>
            </div>

            <div className="head_bar with_margin">
                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar pedido"
                        value={currentQuery.to_print.criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            {funciones.current.validaPermisos("pedidos_crear") &&
                <NavLink
                    to="/nuevo-pedido"
                    className="btn btn_add_to_list _right">
                    Crear pedido<Iconos.PedidoAdd />
                </NavLink>}

            <Loading state={showLoading} />
            <LoadingProgress state={showProgress} progress={progress} />
        </RequirePermissions>
    )
}

export default withRouter(ColaImpresion);