import * as React from "react";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, onUpdate } = params;
    const { setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [modalView, setModalView] = React.useState(null);


    const changeStatus = async () => {
        let _data = await api.fetchJson({
            url: "re-open-conciliation",
            data: {
                task: "change-conciliation",
                id: data.id
            }
        });

        if (_data.response == 1) {
            document.body.style.overflow = "auto";
            setModalView(null);
            onUpdate({
                ...data,
                carrier: JSON.stringify({
                    ...data.carrier,
                    conciliacion: {
                        ...data.carrier.conciliacion,
                        estado: '0'
                    }
                })
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const receiveGuide = async () => {
        let _data = await api.fetchJson({
            url: "receive-guide",
            data: {
                task: "receive-guide",
                id: data.id
            }
        });

        if (_data.response == 1) {
            document.body.style.overflow = "auto";
            setModalView(null);
            onUpdate({
                ...data,
                carrier: JSON.stringify({
                    ...data.carrier,
                    conciliacion: {
                        ...data.carrier.conciliacion,
                        estado: '7'
                    }
                })
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Confirm(`¿Cambiar la guía ${data.guia} \na "Sin conciliar"?`, resp => {
            if (resp) {
                setModalView(<Loading state={true} />);
                changeStatus();
            }
        });
    }

    const handleReceive = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Confirm(`¿Marcar la guía ${data.guia} \n como "Recibida"?`, resp => {
            if (resp) {
                setModalView(<Loading state={true} />);
                receiveGuide();
            }
        });
    }

    const BtnChange = () => {
        let _btn = <li className="multi_line unable"><Iconos.TruckPending /> <span>Cambiar a <br /><strong>Sin conciliar</strong></span></li>;

        if (data.carrier?.conciliacion && data.carrier?.conciliacion?.estado == 1 && data.carrier?.conciliacion?.id == 1) {
            _btn = <li className="multi_line" onClick={handleChange}><Iconos.TruckPending /> <span>Cambiar a <br /><strong>Sin conciliar</strong></span></li>;
        }

        return _btn;
    }

    const BtnMarkReceive = () => {
        let _btn = <li className="unable"><Iconos.Truck /> <span>...</span></li>;

        if (data.carrier?.conciliacion && [5, 6].includes(parseInt(data.carrier?.conciliacion?.estado))) {
            _btn = <li className="multi_line" onClick={handleReceive}><Iconos.TruckCheck /> <span>Cambiar a <br /><strong>Recibido</strong></span></li>;
        }

        return _btn;
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: "0", left: "0" });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";

            setPos(funciones.current.getPosInRow(e, menuRef.current));

            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (
            <>
                <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                    <ul>
                        <BtnChange />
                        <hr />
                        <BtnMarkReceive />
                    </ul>
                </nav>
            </>)
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        switch (data.estado) {
            case '1':
                setClases(c => c + " bkg_aceptado");
                break;
            case '2':
                setClases(c => c + " bkg_pendiente");
                break;
            case '3':
                setClases(c => c + " bkg_rechazado");
                break;
            case '4':
                setClases(c => c + " bkg_pagado");
                break;
            case '5':
                setClases(c => c + " bkg_pagado_pendiente");
                break;
            case '6':
                setClases(c => c + " bkg_aceptado_cambio");
                break;
            case '7':
                setClases(c => c + " bkg_recibido");
                break;
            default:
                break;
        }
    }, [data]);

    return (
        <tr className={clases}
            onContextMenu={handleContextMenu}>

            {children}

            <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {modalView}
            </td>
        </tr>
    )
}

export default Menu;