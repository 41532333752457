import * as React from 'react';
import { InputPassword, InputWithIcon } from 'shared/Components';
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const LogInForm = () => {
    const { lastPage, setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [pass, setPass] = React.useState("");
    const navigate = useNavigate();

    const inputMail = React.useRef(null);
    const inputPass = React.useRef(null);


    const validateFormatEmail = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(email).toLowerCase())) {
            alerta(inputMail.current, "¡El formato del correo es erróneo!");

            return false;
        } else {
            return true;
        }
    }

    const validaMail = () => {
        alertaRemove(inputMail.current);

        if (email == null || email.length < 1) {
            alerta(inputMail.current, "¡Debes ingresar tu correo eléctronico!");

            return false;
        } else {
            return true;
        }
    }

    const validaPass = () => {
        alertaRemove(inputPass.current);

        if (pass == null || pass.length < 1) {
            alerta(inputPass.current, "¡Debes ingresar tu contraseña!");

            return false;
        } else if (pass.length < 8) {
            alerta(inputPass.current, "¡La contraseña en muy corta!");

            return false;
        } else {
            return true;
        }
    }

    const validate = async () => {
        if (validaMail() && validateFormatEmail() && validaPass()) {
            setShowLoading(true);
            let _data = await api.signIn(email, pass);

            if (_data.response == 1) {
                let _info = JSON.parse(_data.data.info);
                let _user = {
                    auth: _data.data.auth,
                    time: _data.data.time,
                    token: _data.data.token,
                    validate: _data.data.validate,
                    nivel: _data.data.nivel,
                    permisos: _info.permisos,
                    nombre: _info.nombre + " " + _info.apellido
                };

                if (_info?.local?.id) {
                    _user.local = _info.local;
                    _user.local.id = btoa(_user.local.id);
                }

                localStorage.setItem('usuario', JSON.stringify(_user));
                setDataUser(_user);
                setTimeout(() => {
                    let _footer = document.getElementsByClassName("footer")[0];
                    _footer.style.display = "block";
                    setShowLoading(false);

                    if (lastPage.url === undefined || lastPage.url === "/sign-in" || lastPage.state.self !== undefined) {
                        navigate("/", { replace: true });
                    } else if (Object.keys(lastPage.state).length > 0 && lastPage.state.self === undefined) {
                        navigate(lastPage.url, { replace: true, state: lastPage.state });
                    } else {
                        navigate(lastPage.url, { replace: true });
                    }
                }, 2000);

            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    return (
        <form className="form">
            <InputWithIcon
                ref={inputMail}
                type="text"
                title="Correo eléctronico"
                Icono={Iconos.Email}
                value={email}
                onChange={v => { alertaRemove(inputMail.current); setEmail(v) }} />

            <InputPassword
                ref={inputPass}
                title="Contraseña"
                value={pass}
                onChange={v => { alertaRemove(inputPass.current); setPass(v) }}>
                <div className="login__forgot-password">
                    <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
                </div>
            </InputPassword>

            <div className="content_btns">
                <button type="button" className="btn btn_primary" onClick={validate}>Iniciar sesión</button>
            </div>

            <Loading state={showLoading} />
        </form>
    );
};

export default LogInForm;
