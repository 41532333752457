import * as React from "react";
import { DatePicker, SelectWithIcon } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Funciones } from "services/funciones";
import ReactExport from "react-export-excel-ext";
import MovePopUp from "shared/MovePopUp";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_center = { horizontal: "center", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "ffb7e1cd" } },
    font: { sz: "12", bold: true, color: { rgb: "ffff0000" } },
    alignment: _align_center,
    border: _border
};

const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    alignment: _align_center,
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Reporte = ({ parent, close }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(false);
    const [fileName, setFileName] = React.useState("No name");
    const [generado, setGenerado] = React.useState(false);
    const [flujoCaja, setFlujoCaja] = React.useState([]);
    const [fecha, setFecha] = React.useState(new Date());
    const [bankInfo, setBankInfo] = React.useState({});
    const [dataList, setDataList] = React.useState([]);
    const [cuentas, setCuentas] = React.useState([]);
    const [lastDay, setLastDay] = React.useState(30);
    const [error, setError] = React.useState("");
    const [bank, setBank] = React.useState("");


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const handleBank = (_value) => {
        setError("");
        setGenerado(false);

        if (_value === "") {
            setBank("");
            setBankInfo({});
        } else {
            let item = cuentas.find(count => format(count.id) === _value);

            if (typeof item === "object") {
                setBank(_value);
                setBankInfo({ bank: item.id, bank_name: item.nombre });
            } else {
                setBank("");
                setBankInfo({});
                setError("¡La cuenta bancaria es invalida!");
            }
        }
    }

    const getList = async () => {
        setError("");
        setShowLoading(true);
        setDataList([]);
        let _radios = document.getElementsByName("tipo");
        let _tipo;

        _radios.forEach(element => {
            if (element.checked) {
                _tipo = element.value;
            }
        });

        let _data = await api.fetchJson({
            url: "get-cash-movement",
            data: {
                task: "para_excel",
                tipo: _tipo,
                bank: format(bankInfo.bank),
                fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
            }
        });

        if (_data.response == 1) {
            let _list = [];

            _data.data.map(item => {
                _list.push(item);
            });

            if (_data.data.length > 0) {
                let _data_flow = await api.fetchJson({
                    url: "get-concepts",
                    data: {
                        task: "flujo",
                        fecha_ini: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-01`,
                        fecha_fin: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${lastDay}`
                    }
                });

                armaFlujo(_data_flow.data);
            }

            setDataList(_list);
            setGenerado(true);
        } else if (_data.response == -2) {
            setError(_data.msg);
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_with_icon _left unable"><Iconos.ExcelDownload />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_with_icon _left"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const BotonGenerar = () => {

        if (showLoading) {
            return <button className="btn btn_with_icon _left"><Iconos.Loading />Cargando info...</button>;
        } else {
            return <button className="btn btn_with_icon _left" onClick={() => getList()}><Iconos.ExcelSearch />Generar informe </button>;
        }
    }

    const armaFlujo = (data) => {
        let _last_day = new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate();
        let _categoria = "";
        let _category_name = "";
        let _days = [];
        let _all = [];

        _days.push({ value: "Códigos", style: _heads, width: 40 });
        _days.push({ value: meses[fecha.getMonth()] + "", style: _heads, width: 9 });

        for (let i = 1; i <= _last_day; i++) {
            _days.push({ value: format(i) + "", style: _heads, width: 7 });
        }


        data.map((item) => {
            let _rows = [];
            let _rows_empty = [];
            let _total = 0;
            let _pagos = Object.entries(JSON.parse(item.pagos || "{}"));

            for (let i = 1; i <= _last_day; i++) {
                let _valor_hoy = 0;

                // eslint-disable-next-line
                _pagos.forEach(([key, value]) => {
                    let _montos = value.split(':');
                    let _fecha = new Date(_montos[0] + "T05:00:00");

                    if (_montos[1] !== undefined && _fecha.getDate() == i) {
                        _valor_hoy += Number(_montos[1]);
                        _total += Number(_montos[1]);
                    }
                });

                _rows.push({ value: _valor_hoy == 0 ? "" : _valor_hoy.toFixed(2).replace('.', ','), style: { ..._txt, alignment: _align_rigth } });
                _rows_empty.push({ value: "", style: _resum });
            }

            _rows.unshift({ value: _total == 0 ? "" : _total.toFixed(2).replace('.', ','), style: { ..._txt, alignment: _align_rigth } });
            _rows.unshift({ value: format(item.id) + " - " + item.nombre, style: _txt });


            if (item.caegoria && item.caegoria == _categoria) {
                _all.push(_rows);
            } else {
                _categoria = item.caegoria;

                if (_categoria) {
                    _category_name = _categoria ? _categoria.charAt(0).toUpperCase() + _categoria.slice(1) : _categoria;

                    _rows_empty.unshift({ value: "", style: _resum });
                    _rows_empty.unshift({ value: _category_name + "", style: _resum });

                    _all.push(_rows_empty);
                    _all.push(_rows);
                } else {
                    _all.push(_rows);
                }
            }
        });

        setFlujoCaja([
            {
                columns: _days,
                data: _all
            }
        ]);

    }

    const HojaDeCalculo = () => {

        const controlCaja = [
            {
                columns: [
                    { value: "Fecha", style: _heads, width: 9 },
                    { value: "Concepto", style: _heads, width: 20 },
                    { value: "Código", style: _heads, width: 8 },
                    { value: "Cuenta", style: _heads, width: 20 },
                    { value: "Entrada", style: _heads, width: 10 },
                    { value: "Salida", style: _heads, width: 10 },
                    { value: "Responsable", style: _heads, width: 19 },
                ],
                data: dataList.map(item => {
                    let _info = JSON.parse(item.info);
                    let fecha = new Date(_info.fecha + "T05:00:00");

                    return [
                        { value: format(fecha.getDate()) + "/" + meses_hsort[fecha.getMonth()] + "/" + fecha.getFullYear(), style: _txt },
                        { value: _info.nota ?? "", style: _txt },
                        { value: _info.codigo == 0 ? "" : _info.codigo, style: _txt },
                        { value: _info.bank ? _info.bank + " - " + _info.bank_name : "", style: _txt },
                        { value: item.tipo == 1 ? funciones.current.formatPrice(item.monto).format : "", style: { ..._txt, alignment: _align_rigth } },
                        { value: item.tipo == 0 ? funciones.current.formatPrice(item.monto).format : "", style: { ..._txt, alignment: _align_rigth } },
                        { value: _info.gestor.nombre ?? "", style: _txt },

                    ];
                })
            }
        ];

        return <>
            {(dataList.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={controlCaja} name="Control de caja" />
                    <ExcelSheet dataSet={flujoCaja} name="Flujo de caja" />
                </ExcelFile>
            }
        </>;
    }

    const handleChange = (e) => {
        if (e.target.value === "todos") {
            setFileName(`Todos los movimientos ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Movimientos de ${e.target.value} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }
        setGenerado(false);
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        let _radios = document.getElementsByName("tipo");
        let _tipo;

        _radios.forEach(element => {
            if (element.checked) {
                _tipo = element.value;
            }
        });

        if (_tipo === "todos") {
            setFileName(`Todos los movimientos ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        } else {
            setFileName(`Movimientos de ${_tipo} ${format(fecha.getDate())} de ${meses[fecha.getMonth()]} del ${fecha.getFullYear()}`);
        }

        setLastDay(new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate());
        setGenerado(false);
    }, [fecha]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getBanksList();
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Informe de caja">

            <SelectWithIcon
                title="Cuenta Bancaria"
                Icono={Iconos.Bank}
                value={bank}
                onChange={handleBank} >
                <option value="">Todas las cuentas</option>
                {cuentas.map((item, i) => {
                    return <option key={i} value={format(item.id)}>{item.nombre}</option>;
                })}
            </SelectWithIcon>

            <DatePicker
                title="Fecha"
                value={fecha}
                onChange={(date) => { setError(""); setFecha(date); }} />

            <div className="radio_goup">
                <label><input type="radio" name="tipo" value="entradas" onChange={handleChange} /><Iconos.CashIn />Entradas</label>
                <label><input type="radio" name="tipo" value="salidas" onChange={handleChange} /><Iconos.CashOut />Salidas</label>
                <label><input type="radio" name="tipo" value="todos" onChange={handleChange} defaultChecked={true} /><Iconos.CashMultiple />Todos</label>
            </div>


            {error.length > 1 && <h4 className="error"><Iconos.InformationCircle />{error}</h4>}

            {generado ?
                <HojaDeCalculo />
                :
                <BotonGenerar />
            }
        </MovePopUp>
    )
}

export default Reporte;