import * as React from "react";
import { Funciones } from "services/funciones";
import { Alert, Confirm } from "shared/Alert";
import MovePopUp from "shared/MovePopUp";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import Options from "./Options";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, reload, current, inRow = false } = params;
    const { setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);


    const goEdit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalView(<Loading state={true} />);

        let _data = await api.fetchJson({
            url: "shopify-get-order",
            data: {
                order_number: data.pedido.order_number
            }
        });

        if (_data.response == 1) {
            navigate("/editar-pedido", { state: { data: { ..._data.data, tipo: "Editar" } } });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<PopUpDetalle />);
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm("¿Seguro de borrar este comprobante?", async (response) => {
            if (response) {
                setShowLoading(true);
                let _data = await api.fetchJson({
                    url: "delete-voucher",
                    data: {
                        info: JSON.stringify(data)
                    }
                });

                if (_data.response == 1) {
                    Alert(_data.msg, "ok", () => reload());
                } else if (_data.response == -2) {
                    Alert(_data.msg, "warning", () => navigate("/sign-in"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });

    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const BtnEdit = () => {
        let _btn = <li className="unable"><Iconos.Edit /> <span>Editar</span></li>;

        if (funciones.current.validaPermisos("pedidos_edita") && data.fulfillment_status !== "fulfilled") {
            _btn = <li onClick={goEdit}><Iconos.Edit /> <span>Editar</span></li>;
        }

        return _btn;
    }

    const BtnValidate = () => {
        let _btn = <li className="unable"><Iconos.VoucherOk /> <span>Validar</span></li>;

        const changeStatus = (e) => {
            e.preventDefault();

            setModalView(<Options
                data={data}
                parent={{ top: e.clientY, left: e.clientX, width: 300 }}
                reload={() => reload()}
                close={() => setModalView(null)} />);
        }

        if (funciones.current.validaPermisos("contraentregas_validate")) {
            _btn = <li onClick={changeStatus}><Iconos.VoucherOk /> <span>Validar</span></li>;
        }

        return _btn;
    }

    const BtnDelete = () => {
        if (funciones.current.validaPermisos("contraentregas_borra")) {
            return <li onClick={goDelete}><Iconos.Delete /> <span>Borrar</span></li>;
        } else {
            return <li className="unable"><span style={{ display: "inline-block", width: "2.5rem" }}></span><span>...</span></li>;
        }
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: "0", left: "0" });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            if (inRow) {
                setPos(funciones.current.getPosInRow(e, menuRef.current));
            } else {
                setPos(funciones.current.getPosInDisplay(e, menuRef.current));
            }
            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (
            <>
                <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                    <ul>
                        {inRow ?
                            <li onClick={goOpen}><Iconos.OpenIn /> <span><strong>Detalle</strong></span></li>
                            :
                            <li className="unable"><Iconos.OpenIn /> <span><strong>...</strong></span></li>
                        }

                        <hr />
                        <BtnEdit />
                        <BtnValidate />
                        <hr />
                        <BtnDelete />
                    </ul>
                    <Loading state={showLoading} />
                </nav>
            </>)
    }

    const PopUpDetalle = () => {
        const [infoCustomer, setInfoCustomer] = React.useState({});
        const [loadInfo, setLoadInfo] = React.useState(true);
        const [tags, setTags] = React.useState([]);
        const { media_movil } = useMedia();
        const products = data.pedido.line_items;
        const total_articulos = data.pedido.total_articulos + " " + (data.pedido.total_articulos > 1 ? "artículos" : "articulo");
        let _tag = data.pedido.tags.includes("CONTRAENTREGA") ? "CONTRAENTREGA" : null;

        const getCustomer = async () => {
            setLoadInfo(true);

            let _data = await api.fetchJson({
                url: "shopify-get-customer",
                data: {
                    id: data.pedido.customer.id,
                    tipo: "id"
                }
            });

            if (_data.response == 1) {
                setInfoCustomer(_data.data);
            } else if (_data.response == -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }

            setLoadInfo(false);
        }

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            let _tags_list = [];

            if (!infoCustomer.id) {
                getCustomer();
            }
            data.pedido.tags
                .replace(/,\s*local\.[A-Za-z0-9=]+|local\.[A-Za-z0-9=]+,\s*|local\.[A-Za-z0-9=]+/g, "").replace(/,,/g, ",")
                .split(",").forEach(item => {
                    if (item.trim().length > 0) {
                        _tags_list.push(item.trim());
                    }
                });

            setTags(_tags_list);
        }, []);

        return <MovePopUp
            parent={{ top: media_movil ? 80 : 100, left: media_movil ? "5%" : "calc(50% - 275px)", width: media_movil ? "90%" : 550 }}
            className="popup_window height_fixed"
            close={() => {
                document.body.style.overflow = "auto";
                setModalView(null);
            }}
            title={"Detalle del pedido " + data.pedido.name}>

            <div className="content_primary _pedido_detalle" style={{ paddingTop: "0.5rem" }}>
                <div className="in_column" style={{ width: media_movil ? "100%" : 320, maxWidth: media_movil ? "100%" : 320 }}>

                    <div className="info_card">
                        <h5 style={{ marginBottom: "0.6rem" }}>Artículos <sup>{data.pedido.fulfillment_status === "fulfilled" ? "Preparado" : "No preparado"}</sup></h5>
                        {products.map((item, i) => {
                            let _precio = parseFloat(item.price);
                            let _valor = funciones.current.formatPrice(_precio).format;
                            let _total = funciones.current.formatPrice(_precio * item.quantity).format;
                            return <p key={i} className="info_product"><b>{_valor} x {item.quantity} = {_total}</b> - <span>{item.name}</span></p>;
                        })}
                    </div>

                    <div className="info_card">
                        <h5 style={{ marginBottom: "1rem" }}>Pago {_tag && <b className="tag">{_tag}</b>} <sup>{data.pedido.financial_status.toLowerCase() === "pending" ? "Pendiente" : data.pedido.financial_status.toLowerCase() === "paid" ? "Pagado" : data.pedido.financial_status.toLowerCase() === "partially_paid" ? "Pago parcial" : data.pedido.financial_status}</sup></h5>
                        <p className="info_price"><span className="left">Subtotal </span> <span className="center">{total_articulos}</span>  <span className="right">${funciones.current.formatPrice(data.pedido.subtotal_price.toString().replace(".", ",")).format}</span></p>
                        <p className="info_price"><span className="left">Envio </span> <span className="right">${funciones.current.formatPrice(data.pedido.total_shipping_price.toString().replace(".", ",")).format}</span></p>
                        <p className="info_price"><span className="left">Descuento</span> <span className="right">${funciones.current.formatPrice(data.pedido.total_discounts.toString().replace(".", ",")).format}</span></p>
                        <p className="info_price _total"><span className="left">Total</span> <span className="right">${funciones.current.formatPrice(data.pedido.total_price.toString().replace(".", ",")).format}</span></p>
                    </div>

                    <div className="info_card">
                        <h5>Etiquetas</h5>
                        <p className="cont_tags">
                            {tags.map((tag, index) => {
                                return <span key={index} className="tag">{tag}</span>
                            })}
                        </p>
                    </div>
                </div>

                <div className="in_column" style={{ width: media_movil ? "100%" : 200, maxWidth: media_movil ? "100%" : 200 }}>
                    <div className="info_card">
                        <h5>Notas</h5>
                        <p className="info_nota">{data.pedido.note}</p>
                        <h5>Detalles adicionales</h5>
                        <p className="info_attributes">
                            {data.pedido.note_attributes.map((nota, index) => <React.Fragment key={index}>
                                <b>{nota.name}</b>
                                <span>{nota.value}</span>
                            </React.Fragment>)}
                        </p>
                    </div>

                    <div className="info_card">
                        <h5>Cliente {loadInfo && <Iconos.Loading className="icon_loading" />}</h5>
                        {infoCustomer?.first_name ?
                            <p className="info_name">{infoCustomer.first_name} {infoCustomer.last_name} </p>
                            :
                            <p className="info_name">{data.pedido.customer.first_name} {data.pedido.customer.last_name} </p>
                        }
                        {infoCustomer?.default_address?.company && <p className="info_document"><b>Documento:</b> {infoCustomer?.default_address?.company}</p>}
                        {infoCustomer?.email && <i className="info_email">{infoCustomer?.email}</i>}
                        {infoCustomer?.orders_count && <p className="info_orders">{infoCustomer?.orders_count} {infoCustomer?.orders_count > 1 ? "pedidos" : "pedido"}</p>}

                        <h5>Dirección</h5>
                        {infoCustomer?.default_address && <p className="info_address">{infoCustomer?.default_address?.address1} {infoCustomer?.default_address?.province}, {infoCustomer?.default_address?.city}</p>}
                        {infoCustomer?.default_address?.phone && <i className="info_phone"><b>Teléfono:</b> {infoCustomer?.default_address?.phone}</i>}
                        {infoCustomer?.default_address?.address2 && <i className="info_phone"><b>Teléfono 2:</b> {infoCustomer?.default_address?.address2}</i>}
                    </div>
                </div>
            </div>
        </MovePopUp>;
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    React.useEffect(() => {
        if (inRow && data.estado == 1) {
            setClases(c => c + " bkg_aceptado");
        }

        if (inRow && data.estado == 2) {
            setClases(c => c + " bkg_rechazado");
        }
    }, []);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onDoubleClick={goOpen}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="container _detalle" onContextMenu={handleContextMenu}>

                        {children}
                        {modalView}
                    </div>
                </>}
        </>
    )
}

export default Menu;