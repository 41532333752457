import * as React from "react";
import { Alert, Confirm } from "shared/Alert";
import { Funciones } from "services/funciones";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { navigate, data, interline, children, onSel, reload, current, inRow = false } = params;
    const { data_user, setDataUser } = useMedia();
    const [clases, setClases] = React.useState(interline == 0 ? "table_row" : "table_row bkg_inter_line");
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [showLoading, setShowLoading] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);


    const goEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/editar-usuario", { state: { data: data } });
    }

    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/detalle-usuario", { state: { data: data } });
    }

    const goDevolutions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";

        let _name = data.nombres.trim().replace(/\./g, '');
        _name = _name.trim().replace(/ /g, '_');
        _name = _name.trim().toLowerCase();
        navigate("/pedidos/" + data.id + "/" + _name);
    }

    const goConciliations = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";

        let _name = data.nombres.trim().replace(/\./g, '');
        _name = _name.trim().replace(/ /g, '_');
        _name = _name.trim().toLowerCase();
        navigate("/conciliaciones/" + data.id + "/" + _name);
    }

    const goConciliarGuias = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";

        let _name = data.nombres.trim().replace(/\./g, '');
        _name = _name.trim().replace(/ /g, '_');
        _name = _name.trim().toLowerCase();
        navigate("/conciliar-guias/" + data.id + "/" + _name);
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm("¿Seguro de borrar este usuario?", async (response) => {
            if (response) {
                setShowLoading(true);
                let _data = await api.deletePackage({ id_pack: data.id });

                if (_data.response == 1) {
                    // navigate(-1);
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response == -2) {
                    Alert(_data.msg, "warning", () => navigate("/sign-in"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });

    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setModalView(null);
    }

    const BtnEdit = () => {
        let _btn_enable = <li onClick={goEdit}><Iconos.Edit /> <span>Editar</span></li>;
        let _btn_disable = <li className="unable"><Iconos.Edit /> <span>Editar</span></li>;

        if (funciones.current.validaPermisos("admin_edita")) {
            return _btn_enable;
        } else {
            return _btn_disable;
        }
    }

    const BtnHistory = () => {
        let _btn = <li className="unable"><Iconos.History /> <span>Historial</span></li>;

        if ([1, 2].includes(parseInt(data_user.nivel))) {
            _btn = <li className="unable"><Iconos.History /> <span>Historial</span></li>;
        } else {
            _btn = <></>;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        let _btn = <li className="unable"><Iconos.Delete /> <span>Borrar</span></li>;

        if (funciones.current.validaPermisos("admin_borra")) {
            _btn = <li className="unable"><Iconos.Delete /> <span>Borrar</span></li>
        }

        return (_btn);
    }

    const AllButtons = ({ e }) => {
        const [pos, setPos] = React.useState({ top: "0", left: "0" });
        const menuRef = React.useRef();

        React.useEffect(() => {
            document.body.style.overflow = "hidden";
            if (inRow) {
                setPos(funciones.current.getPosInRow(e, menuRef.current));
            } else {
                setPos(funciones.current.getPosInDisplay(e, menuRef.current));
            }
            setTimeout(() => {
                menuRef.current.style.opacity = 1;
            }, 100);
        }, []);

        return (
            <>
                <div className="back_float" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                <nav className="menu" style={{ ...pos, width: 180 }} ref={menuRef}>
                    <ul>
                        <li onClick={goDevolutions}><Iconos.TruckOrders /> <span><strong>Pedidos</strong></span></li>
                        <li onClick={goConciliations}><Iconos.TruckCheck /> <span>Conciliaciones</span></li>
                        <li onClick={goConciliarGuias}><Iconos.TruckExcelUpload /> <span>Conciliar guías</span></li>
                        <hr />
                        <li onClick={goOpen}><Iconos.OpenIn /> <span>Detalle</span></li>
                        <BtnEdit />
                        <hr />
                        <BtnHistory />
                        <BtnDelete />
                    </ul>
                    <Loading state={showLoading} />
                </nav>
            </>)
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(<AllButtons e={e} />);
    }

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onDoubleClick={goDevolutions}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="td_menu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="container _detalle" onContextMenu={handleContextMenu}>

                        {children}
                        {modalView}
                    </div>
                </>}
        </>
    )
}

export default Menu;