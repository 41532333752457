import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses_hsort = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const DetailsDispatch = (router) => {
    const { navigate, location, params } = router;
    const { setTitle, setDataUser } = useMedia();
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const [listaPedidos, setListaPedidos] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [noData, setNoData] = React.useState(false);
    const [carrier, setCarrier] = React.useState("");
    const [guia, setGuia] = React.useState("");
    const [data, setData] = React.useState({});


    const getOrders = async (_guia) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-dispatch",
            data: {
                task: "por_guia",
                guia: _guia
            }
        });

        if (_data.response == 1) {
            if (_data.data.length > 0) {
                _data.data.map(item => {
                    let _value = JSON.parse(item.info).value;
                    let _info = _value.split(")");

                    setListaPedidos(p => [...p, {
                        id: item.id,
                        value: _value,
                        numero: _info[0] ? _info[0].trim() : "No aplica",
                        fecha: _info[1] ? _info[1].trim() : "No aplica",
                        cantidad: _info[2] ? _info[2].trim() : "No aplica",
                        cliente: _info[3] ? _info[3].trim() : "No aplica",
                        valor: _info[4] ? _info[4].trim() : "No aplica",
                        ciudad: _info[5] ? _info[5].trim() : "No aplica",
                        contacto: _info[6] ? _info[6].trim() : "No aplica",
                        cedula: _info[7] ? _info[7].trim() : "No aplica",
                        etiquetas: _info[8] ? _info[8].trim() : "No aplica",
                        productos: _info[11] ? _info[11].trim() : "No aplica",
                    }]);
                });

                setCarrier(JSON.parse(_data.data[0].carrier));
                setData({
                    ...JSON.parse(_data.data[0].info),
                    carrier: JSON.parse(_data.data[0].carrier),
                    estado: _data.data[0].estado,
                    guia: _data.data[0].guia,
                    id: _data.data[0].id,
                });
            } else {
                setNoData(true);
            }
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (params.guia) {
            setTitle("Detalle despacho " + params.guia);

            funciones.current.validaSesion("despachos_ver").then(response => {
                if (response) {
                    setPermission(true);
                    getOrders(params.guia);
                    setGuia(params.guia);
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });
        } else {
            if (location.state && location.state.data) {
                let _data = location.state.data;

                setTitle("Detalle despacho " + _data.guia, location.state);
                setData(_data);

                funciones.current.validaSesion("despachos_ver").then(response => {
                    if (response) {
                        setPermission(true);
                        getOrders(_data.guia);
                        setGuia(_data.guia);
                    } else {
                        setPermission(false);
                        setShowLoading(false);
                    }
                });
            } else {
                setShowLoading(false);
                setTitle("Detalle despacho", location.state);
                Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                    "warning",
                    () => navigate(-1));
            }
        }
    }, [params.guia, location.pathname]);

    return (
        <RequirePermissions menu={true} permission={permission} loading={showLoading}>
            <Menu
                data={data}
                navigate={navigate}>

                {noData ?
                    <div className="head_bar">
                        <h2 className="head_bar-title">No se encuentra el despacho "{guia}"</h2>
                    </div>
                    :
                    <>
                        <div className="head_bar">
                            <h2 className="head_bar-title">Despacho "{guia}"</h2>
                        </div>

                        <div className="content_primary">
                            {carrier.name ?
                                <h4 className="title_with_icon _left"><Iconos.TruckCheck /> Entregado a {carrier.name.toLowerCase()}</h4>
                                :
                                <h4 className="title_with_icon _left"><Iconos.TruckPending /> Pendiente de entregar </h4>
                            }
                        </div>

                        <div className="content_primary _on_details _center">

                            {listaPedidos.map((item, i) => {
                                let fecha_compra = item.fecha.split("-");
                                fecha_compra = new Date(fecha_compra[2], (fecha_compra[1] - 1), fecha_compra[0]);

                                return <p className="detalles" key={i}>
                                    <span><b>Pedido:</b> {item.numero} </span><br />
                                    {(item.fecha && item.fecha !== "No aplica") && <><span><b>Fecha:</b> {format(fecha_compra.getDate()) + "/" + meses_hsort[fecha_compra.getMonth()] + "/" + fecha_compra.getFullYear()} </span><br /></>}
                                    {(item.cantidad && item.cantidad !== "No aplica") && <><span><b>Cantidad:</b> {item.cantidad} </span><br /></>}
                                    {(item.cliente && item.cliente !== "No aplica") && <><span><b>Cliente:</b> {item.cliente} </span><br /></>}
                                    {(item.valor && item.valor !== "No aplica") && <><span><b>Valor:</b> {item.valor} </span><br /></>}
                                    {(item.ciudad && item.ciudad !== "No aplica") && <><span><b>Ciudad:</b> {item.ciudad} </span><br /></>}
                                    {(item.contacto && item.contacto !== "No aplica") && <><span><b>Contacto:</b> {item.contacto} </span><br /></>}
                                    {(item.cedula && item.cedula !== "No aplica") && <><span><b>Cédula:</b> {item.cedula} </span><br /></>}
                                    {(item.etiquetas && item.etiquetas !== "No aplica") && <><span><b>Etiquetas:</b> {item.etiquetas} </span><br /></>}
                                    {(item.productos && item.productos !== "No aplica") && <><span><b>Productos:</b> {item.productos}  </span><br /></>}
                                </p>
                            })}
                        </div>

                        <div className="content_btns">

                            <NavLink
                                to="/despachos"
                                className="btn link_to_btn _right">
                                Ver todos los despachos
                            </NavLink>
                        </div>
                    </>
                }
                <Loading state={showLoading} />
            </Menu>
        </RequirePermissions>
    )
}

export default withRouter(DetailsDispatch);