import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const rel_zooms = {
    50: 49, // (199%)
    67: 17, // (150%)
    75: 8, // (133%)
    80: 5, // (125%)
    90: 1, // (111%)
    100: 0, // (100%)
    110: 1, // (91%)
    125: 5, // (80%)
    133: 8, // (75%)
    140: 11, // (71%)
    150: 16, // (66%)
    175: 32, // (57%)
};

const getDPI = () => {
    // Crea un elemento invisible para medir
    const dpiElement = document.createElement('div');
    dpiElement.style.width = '1in'; // 1 pulgada en CSS
    dpiElement.style.height = '1in'; // 1 pulgada en CSS
    dpiElement.style.position = 'absolute';
    dpiElement.style.left = '-100%'; // Posiciona fuera de la vista
    document.body.appendChild(dpiElement);

    // Obtiene el tamaño en píxeles
    const dpi = dpiElement.offsetWidth;

    // Elimina el elemento después de medir
    document.body.removeChild(dpiElement);

    return dpi;
}

const PrintGuide = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil, setDataUser } = useMedia();

    const [enableButton, setEnableButton] = React.useState(false);
    const [loadedFrames, setLoadedFrames] = React.useState(-1);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [toPrintList, setToPrintList] = React.useState([]);
    const funciones = React.useRef(new Funciones(navigate, setDataUser));
    const template_print = React.useRef();
    const dpi = getDPI();
    const widthInInches = 8.5;  // Ancho en pulgadas
    const heightInInches = 11;  // Alto en pulgadas
    const width = widthInInches * dpi;
    const height = heightInInches * dpi;
    const [zoom, setZoom] = React.useState(window.devicePixelRatio * 100);
    const [zoomRecalculado, setZoomRecalculado] = React.useState((200 - zoom.toFixed(0)) + rel_zooms[zoom.toFixed(0)]);


    const handleFrameLoad = () => {
        setLoadedFrames(prevCount => {
            if (prevCount == -1) {
                prevCount = 0;
            }
            return prevCount + 1;
        });
    }

    React.useEffect(() => {
        const updateZoom = () => {
            let _zoom = window.devicePixelRatio * 100;
            log.i("cambio el zoom", _zoom.toFixed(0) + "%");
            setToPrintList([]);
            setZoom(_zoom);

            setZoomRecalculado((200 - _zoom.toFixed(0)) + rel_zooms[_zoom.toFixed(0)]);

            if (location.state && location.state.data) {
                setTimeout(() => {
                    setToPrintList(location.state.data);
                }, 1000);
            }
        };

        const handleBeforePrint = () => {
            log.w("Se activó la impresión");
            const marco_iframe = document.getElementsByClassName("marco_iframe");
            const printStyles = `
                @page {
                    size: letter;
                }
                @media print {
                  body {
                    padding: 0;
                    margin: 0;
                    background-color: rgb(255, 255, 255) !important;
                  }

                  header,
                  footer,
                  .head_bar,
                  .content_btns {
                    display: none !important;
                  }

                  .wrapper {
                    display: block;
                    position: relative;
                    top: 0 !important;
                    width: ${width}px;
                    min-height: ${height}px !important;
                    padding: 0 !important;
                    background-color: rgb(255, 255, 255) !important;

                    .container {
                      display: block;
                      padding: 0 !important;
                      background-color: rgb(255, 255, 255) !important;

                      .content_to_print {
                        display: block !important;

                        .marco_iframe {
                          width: ${width + parseInt(dpi * 0.08)}px;
                          height: ${height}px;
                          margin: 0;
                          overflow: hidden !important;

                          iframe {
                            width: calc(100% + ${parseInt(dpi * 0.08)}px);
                            height: ${height + parseInt(dpi * 0.4)}px;
                            padding: 0;
                            border: none;
                            margin-top: -4px;
                            margin-left: -${parseInt(dpi * 0.08)}px;
                            margin-bottom: -8px;
                          }
                        }
                      }
                    }
                  }
                }
            `;

            if (document.getElementById("printOrdersStyles")) {
                document.getElementById("printOrdersStyles").remove();
            }

            if (!document.getElementById("printGuideStyles")) {
                const styleSheet = document.createElement("style");
                styleSheet.id = "printGuideStyles";
                styleSheet.innerHTML = printStyles;
                document.head.appendChild(styleSheet);
                log.d("Se agrego el estilo de impresión");
            } else {
                log.d("El estilo de impresión ya existe");
            }

            for (let i = 0; i < marco_iframe.length; i++) {
                marco_iframe[i].style.width = (width + parseInt(dpi * 0.08)) + "px";
                marco_iframe[i].style.height = height + "px";
            }
        };

        const handleAfterPrint = () => {
            log.i("Se finalizó la impresión");
            const marco_iframe = document.getElementsByClassName("marco_iframe");
            let _zoom = window.devicePixelRatio * 100;
            let _zoom_recalculado = (200 - _zoom.toFixed(0)) + rel_zooms[_zoom.toFixed(0)];
            let _pixels_width = width + parseInt(dpi * 0.08);

            log.i("width", _pixels_width);
            log.i("height", height);

            log.d("zoom old", _zoom.toFixed(0) + "%");
            log.d("zoom new", _zoom_recalculado + "%");

            for (let i = 0; i < marco_iframe.length; i++) {
                marco_iframe[i].style.width = (_pixels_width * _zoom_recalculado / 100).toFixed(1) + "px";
                marco_iframe[i].style.height = (height * _zoom_recalculado / 100).toFixed(1) + "px";
            }
        }

        window.addEventListener('resize', updateZoom);
        window.addEventListener('beforeprint', handleBeforePrint);
        window.addEventListener('afterprint', handleAfterPrint);

        return () => {
            window.removeEventListener('resize', updateZoom);
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);

    React.useEffect(() => {
        if (loadedFrames === toPrintList.length) {
            setEnableButton(true);
        }
    }, [loadedFrames, toPrintList]);

    React.useEffect(() => {
        setTitle("Imprimir Guías");

        if (location.state && location.state.data) {
            let _data = location.state.data;

            funciones.current.validaSesion("guias_crear").then(response => {
                if (response) {
                    setPermission(true);
                    setToPrintList(_data);
                } else {
                    setPermission(false);
                }
                setShowLoading(false);
            });

        } else {
            setShowLoading(false);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar guide">
                {media_movil && <h4>En la versión movil no esta disponible la impresión de guías</h4>}
            </div>

            <div className="content_to_print" ref={template_print} >
                {toPrintList.map((item, index) => {
                    let _url = process.env.REACT_APP_WEB_API + "envia-get-pdf?tracking_number=" + item.tracking_number;

                    return <React.Fragment key={item.id}>
                        {media_movil ?
                            <a href={_url} target="_blank" rel="noopener noreferrer">Guía {item.tracking_number}</a>
                            :
                            <div className="marco_iframe" style={{ width: ((width + parseInt(dpi * 0.08)) * zoomRecalculado / 100), height: (height * zoomRecalculado / 100) }}>
                                <iframe style={{ height: height + parseInt(dpi * 0.4) }}
                                    title={`PDF_${index}`}
                                    src={`${_url}#zoom=${zoomRecalculado}&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`}
                                    onLoad={handleFrameLoad} />
                            </div>
                        }
                    </React.Fragment>
                })}
            </div>

            <div className="content_btns _floating">

                {!media_movil && <>
                    {enableButton ?
                        <button type="button" className="btn btn_primary" onClick={() => window.print()}>Imprimir {toPrintList.length} guía{toPrintList.length == 1 ? "" : "s"}</button>
                        :
                        <button type="button" className="btn btn_primary unable">Imprimir {toPrintList.length} guía{toPrintList.length == 1 ? "" : "s"}</button>
                    }
                </>}
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(PrintGuide);